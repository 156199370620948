/**, *:before, *:after {*/
  /*-webkit-box-sizing:border-box;*/
  /*-moz-box-sizing:border-box;*/
  /*-o-box-sizing:border-box;*/
  /*-ms-box-sizing:border-box;*/
  /*box-sizing:border-box;*/
/*}*/

/* Markup */
.c-editor {
  font-size: 16px;
  line-height: 1.7em;
  font-family: YakuHanJP, "Noto Sans JP", sans-serif;
}
.c-editor h2 {
  display: block;
  width: 100%;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5em;
  margin: 20px 0;
  padding: 5px 0.5em;
  color: #000000;
  border-top: solid 1px #eb613b;
  border-bottom: solid 1px #eb613b;
}
.c-editor h3 {
  display: block;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  padding-left: 0.5em;
  color: #000000;
  border-left: solid 4px #eb613b;
  margin: 20px 0;
}
.c-editor h4 {
  display: block;
  width: 100%;
  padding: 5px 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  padding-left: 0.5em;
  margin: 20px 0;
  color: #000000;
  border-bottom: solid 1px #eb613b;
  // border-bottom: double 2px #eb613b;
}

.c-editor h5 {
  display: block;
  width: 100%;
  padding: 5px 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  padding-left: 0.5em;
  margin: 10px 0;
  border-bottom: solid 1px #cccccc;
}
.c-editor h6 {
  display: block;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  padding-left: 0.5em;
  color: #000000;
  border-left: solid 4px #cccccc;
  margin: 20px 0;
}


.c-editor p {
  line-height: 2;
  display: block;
  margin: 1.5em 0;
}
.c-editor strong {
  font-weight: bold;
  color: #eb613b;
}
.c-editor em {
  font-weight: bold;
  font-style: normal;
  color: #eb613b;
}
.c-editor hr {
  border-top: 1px solid #eb613b;
}
.c-editor ul {
  margin: 30px 0;
  margin: 1em 0;
  padding-left: 40px;
  list-style-type: disc;
  list-style: disc;
}
.c-editor ul li {
  float: none !important;
  list-style-type: disc;
  list-style: disc;
  line-height: 2;
}
.c-editor ul.oblong {
  margin: 30px 0;
  margin: 1em 0;
  padding-left: 0;
  list-style-type: none;
}
.c-editor ul.oblong li {
  float: none !important;
  background-size: auto 50%;
  padding-left: 40px;
}
.c-editor ol {
  margin: 30px 0;
  padding-left: 40px;
  list-style-type: decimal;
  list-style: decimal;
}
.c-editor ol li {
  float: none !important;
  list-style-type: decimal;
  list-style: decimal;
  line-height: 2;
}
.c-editor table {
  border-collapse: collapse;
  line-height: 2;
}
.c-editor table tr th {
  border: solid 1px #eb613b;
  background: #f7c0b1;
  padding: 14px;
}
.c-editor table tr td {
  border: solid 1px #eb613b;
  padding: 14px;
}
.c-editor table tr:nth-child(2n) {
    background: none;
}

// .c-editor table tr td:nth-child(1) {
//   background: #ccc;
//   font-weight: bold;
// }
@media screen and (max-width: 1024px) {
  .c-editor table {
    width: 100% !important;
  }
}
.c-editor blockquote {
  background-color: #f4f9e8;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 1em;
  position: relative;
  z-index: 1;
}
.c-editor blockquote:before {
  font-size: 3em;
  line-height: 1em;
  color: #fff;
  position: absolute;
  z-index: -1;
  content: "\f10d";
  font-family: FontAwesome;
  left: 0;
  top: 0;
  padding: 10px;
}
.c-editor blockquote:after {
  font-size: 3em;
  line-height: 1em;
  color: #fff;
  position: absolute;
  z-index: -1;
  content: "\f10e";
  font-family: FontAwesome;
  right: 0;
  bottom: -0.2em;
  padding: 10px;
}
.c-editor blockquote p {
  display: inline;
}
.c-editor img {
  max-width: 100%;
  height: auto;
  padding: 10px 0;
}
.c-editor img.aligncenter {
  display: block;
  margin: 0 auto;
}
.c-editor img.alignright {
  display: inline;
  float: right;
}
.c-editor img.alignleft {
  display: inline;
  float: left;
}
.c-editor a {
  color: #eb613b;
  text-decoration: underline;
  word-break: break-all;
}
.c-editor a:hover {
  background: #eb613b;
  color: #fff;
  text-decoration: none;
}
.c-editor a:hover img{
    display: block;
}

.c-editor a[target="_blank"]:after {
  content: "\f14c";
  font-family: FontAwesome;
  padding: 0 5px;
}

.c-editor pre {
  white-space: pre-wrap;
  width: 100%;
  background: #fff;
  padding:20px;
}


/* コンポーネント */
.wpac-alert {
  display: block;
  background-color: #f7c0b1;
  border: 1px solid #eb613b;
  border-radius: 3px;
  color: #eb613b;
  padding: .9rem;
  font-weight: bold;
}

.wpac-columns {
  margin:10px 0;
}

.wpac-columns__row {
  display: flex;
  flex-wrap: wrap;
}

.wpac-columns__row {
  display: flex;
  flex-wrap: wrap;
}

.wpac-columns__col {
  padding-right: .9rem;
  padding-left: .9rem;
  margin-bottom: 1.8rem;
}

.wpac-columns__col--1-1 {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -o-box-sizing:border-box;
  -ms-box-sizing:border-box;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  width: 50%;
}
@media (max-width: 767px) {
  .wpac-columns__col--1-1 {
    width: 100%;
  }
}

.wpac-columns__col--1-2 {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -o-box-sizing:border-box;
  -ms-box-sizing:border-box;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  width: 50%;
}


.wpac-columns__col--lg-1-3{
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -o-box-sizing:border-box;
  -ms-box-sizing:border-box;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  width: 33.3333%;
}

//タブレット
@media (min-width: 768px) and (max-width: 991px) {
  .wpac-columns__col--1-1.wpac-columns__col--lg-1-3 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .wpac-columns__col--lg-1-3{
    width: 100%;
  }
}


.wpac-columns__col--1-3{
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -o-box-sizing:border-box;
  -ms-box-sizing:border-box;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  width: 33.3333%;
}

.wpac-columns__col--lg-1-4{
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -o-box-sizing:border-box;
  -ms-box-sizing:border-box;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  width: 25%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .wpac-columns__col--1-1.wpac-columns__col--lg-1-4 {
      width: 100%;
  }
}
@media (max-width: 767px) {
  .wpac-columns__col--1-1.wpac-columns__col--lg-1-4 {
      width: 100%;
  }
}

.wpac-columns__col--1-4{
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -o-box-sizing:border-box;
  -ms-box-sizing:border-box;
  box-sizing:border-box;
  display: flex;
  flex-direction: column;
  width: 25%;
}

@media (max-width: 767px) {
  .wpac-columns__col--1-2.wpac-columns__col--lg-1-4{
    width: 50%;
  }
}
