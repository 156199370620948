/**
* clearfix
*/
.u-cf:before,
.u-cf:after {
  display: table;
  content: " ";
}

.u-cf:after {
  clear: both;
}

.u-cf {
  *zoom: 1;
}