/*// -----------------------------------------------------------------
// お問い合わせ
// -----------------------------------------------------------------*/

.p-contact {
  margin-bottom:70px;
  @media ( max-width: $screen-xs-max ){
  margin-bottom:35px;
  }
}

.p-contact__head{
  width: 100%;
  min-height: 300px;
  background: url(../../assets/img/contact/contact_bg.png) no-repeat bottom right 20px;
  border-bottom: 1px solid #d1d1d1;
  background-size:155px;
  @media ( max-width: $screen-xs-max ){
  min-height: 500px;
  background: url(../../assets/img/contact/contact_bg.png) no-repeat bottom center;
  background-size:155px;
  }
}



.p-contact__tel {
  padding-bottom: 70px;
  border-bottom: 1px solid #d1d1d1;
  @media ( max-width: $screen-xs-max ){
  padding-bottom:35px;
  }
}

.p-contact__line{
  padding-bottom: 70px;
  border-bottom: 1px solid #d1d1d1;
  @media ( max-width: $screen-xs-max ){
  padding-bottom:35px;
  }
}


.p-fudou-detail__form--tel__meta{
  margin:40px 0 0 30%;
  @media ( max-width: $screen-xs-max ){
  margin:20px 0 0 0;
  }
}

.p-fudou-detail__form--tel__tel{
  display: block;
  padding-left: 10px;
  font-size: 34px;
  font-style:normal;
  position: relative;
  padding-left: 50px;
  @media ( max-width: $screen-xs-max ){
  font-size: 20px;
  }
  &:before {
      margin-right: 10px;
      padding: 5px;
      border-radius: 5px;
      background: #8fc31f;
      color: #ffffff;
      font-size: 19px;
      font-weight: bold;
      content: "TEL";
      position: absolute;
      margin-top: -5px;
      left: 0;
  }
  a{
    pointer-events: none;
    @media ( max-width: $screen-xs-max ){
    pointer-events: auto;
    }
  }
}

.p-fudou-detail__form--txt{

}



/*// -----------------------------------------------------------------
// お問い合わせ送信完了
// -----------------------------------------------------------------*/

.p-contact-complete {
  margin-bottom:70px;
  @media ( max-width: $screen-xs-max ){
  margin-bottom:35px;
  }
}

.p-contact-complete__box{
  padding: 60px 60px 60px;
  border: 1px solid #d1d1d1;
  @media ( max-width: $screen-xs-max ){
  padding: 30px 20px 30px;
  }

}

.p-contact-complete__txt{
  text-align: left;
  line-height: 2em;
  margin: 0;
}

.p-contact-complete__btn{
  text-align: center;
  margin:0;
  padding-top:40px;
}