/*// -----------------------------------------------------------------
// トップ　共通
// -----------------------------------------------------------------*/


.p-top__ttl {
  padding-top: 0;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  @media ( max-width: $screen-xs-max ){
    font-weight: 500;
    font-size: 24px;
  }
}

.p-top__subttl {
  display: block;
  color: #7f7f7f;
  font-size: 15px;
  font-family: 'Yantramanav', sans-serif;
  font-weight: normal;
  @media ( max-width: $screen-xs-max ){
    font-size: 14px;
  }
}

/*// -----------------------------------------------------------------
// TOP 物件一覧
// -----------------------------------------------------------------*/
.p-top__fudou {
  padding:70px 0 70px;
  @media ( max-width: $screen-xs-max ){
    padding: 35px 0 35px;
  }
}

.p-top__fudou__list{

}

.p-top__fudou__item {
  overflow: hidden;
  display: block;
  float: left;
  text-align: left;
  width: 23%;
  height: auto;
  margin: 0 1% 2% 1%;
  // margin: 20px 15px 17px 15px;
  padding: 0;
  font-size: 14px;
  list-style-type: none;
  position: relative;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
  @media ( max-width: $screen-xs-max ){
    width: 48%;
    margin: 0 1% 2% 1%;
    font-size: 0.85rem;
  }
}

// 新着、更新　アイコン
.p-top__new_mark{
  margin: 0;
  position: absolute;
  display: table;
  z-index: 2;
  color: #fff;
  font-size:12px;
  text-align: center;
  background: url(#{$imagepath}img/fudo/status_icon.png) no-repeat top center;
  background-size: cover;
  width: 50px;
  height:50px;
  top:0;
  left:0;
  span{
    display: table-cell;
    vertical-align: middle;
  }
}

.p-top__fudou__item--btn{
  margin-top:10px;
}

.p-top__fudou--btn{
  text-align: center;
}



/*// -----------------------------------------------------------------
// TOP 華はうすについて
// -----------------------------------------------------------------*/

.p-top__about{
  background : #F6F1DA;
  padding:70px 0;
  @media ( max-width: $screen-xs-max ){
    padding:35px 0;
  }
}

.p-top__about--img{
  text-align: center;
}

// .p-top__about--ttl {
//   padding-top: 0;
//   padding-bottom: 20px;
//   font-size: 30px;
//   font-weight: bold;
//   text-align: center;
// }
//
// .p-top__about--subttl {
//   display: block;
//   color: #7f7f7f;
//   font-size: 15px;
//   font-family: 'Yantramanav', sans-serif;
// }

.p-top__about--copy {
  font-size:16px;
  text-align: left;
  line-height: 2;
  margin: 0;
}

.p-top__about--btn{
  text-align: center;
  margin-top:40px;
}


/*// -----------------------------------------------------------------
// SNSでも知寄町のくらしの情報発信中！
// -----------------------------------------------------------------*/

.p-top__sns{
  background: url(#{$imagepath}img/common/bg.jpg) repeat top center;
  border-bottom:solid 4px #eb613b;
}
.p-top__sns--bg{
  background: url(#{$imagepath}img/common/footer_bg01.png) no-repeat bottom center;
  padding:50px 0;
  min-height: 200px;
  @media ( max-width: $screen-xs-max ){
    background: url(#{$imagepath}img/common/footer_bg01_sp.png) no-repeat bottom center;
    min-height: 500px;
    background-size: contain;
  }
}

.p-top__sns--list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto;
}

.p-top__sns--item{
  width: 50%;
  margin: 20px 0 0;
  text-align: center;
  @media ( max-width: $screen-xs-max ){
    width: 100%;
    &:first-child{
      margin: 0;
    }
  }
}



/*// -----------------------------------------------------------------
// 不動産コラム
// -----------------------------------------------------------------*/


.p-top__column{
  background : #F6F1DA;
  padding:0 0 70px;
  @media ( max-width: $screen-xs-max ){
    padding: 0 0 35px;
  }
}
.p-top__column--list{
  padding:0;
  margin:0;
}
.p-top__column--item {
}

.p-top__common--item__link{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  padding: 30px 0;
  border-bottom:dotted 2px #eb613b;
  @media ( max-width: $screen-xs-max ){
    padding: 15px 0;
  }
}

.p-top__column--item__img{
  width: 30%;
  @media ( max-width: $screen-xs-max ){
    width: 100%;
  }
  img{
    width: 100%;
  }
}

.p-top__column--item__data{
  width: 65%;
  margin-left:5%;
  @media ( max-width: $screen-xs-max ){
    width: 100%;
    margin-left:0;
  }
}

.p-top__column--item__ttl{
  font-size:18px;
  font-weight: bold;
  margin-bottom:15px;
}

.p-top__column--item__meta{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:0;
  margin-bottom:15px;
}

.p-top__column--item__meta--date{
  color: #7f7f7f;
}

.p-top__column--item__meta--cate{
  background: #ddedbb;
  border-radius: 5px;
  padding:5px 20px;
  font-size:14px;
}

a:hover .p-top__column--item__meta--cate{
  color: #000000;
}

.p-top__column--btn{
  text-align: right;
  margin-top:30px;
}


/*// -----------------------------------------------------------------
// お知らせ
// -----------------------------------------------------------------*/

.p-top__information{
  padding:70px 0 70px;
  @media ( max-width: $screen-xs-max ){
    padding:35px 0 35px;
  }
}

.p-top__information--list{
  padding:0;
  margin:0;
}
.p-top__information--item {
  padding: 30px 0;
  border-bottom:dotted 2px #eb613b;
  @media ( max-width: $screen-xs-max ){
    padding: 15px 0;
  }
}

.p-top__information--item__ttl{
  font-size:18px;
  font-weight: bold;
  margin-bottom:15px;
}

.p-top__information--item__meta{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:0;
  margin-bottom:15px;
}

.p-top__information--item__meta--date {
  color: #7f7f7f;
}

.p-top__information--item__meta--cate{
  background: #f9cfc4;
  border-radius: 5px;
  padding:5px 20px;
  font-size:14px;
}

a:hover .p-top__information--item__meta--cate{
  color: #000000;
}

.p-top__information--btn{
  text-align: right;
  margin-top:30px;
}


.p-top__sns--ttl{
  br{
    display: none;
    @media ( max-width: $screen-xs-max ){
      display: block;
    }
  }
}
