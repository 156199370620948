.c-pagenavi .wp-pagenavi {
  margin: 40px 0;
  padding: 0 0 0 0;
  border: 0;
  text-align: center;
  font-size:16px;
}

.c-pagenavi .wp-pagenavi .pages {
  display: none;
}

.c-pagenavi .wp-pagenavi a:not(.first):not(.last), .c-pagenavi .wp-pagenavi span {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 4px;
  padding: 7px 5px;
  border: none;
  /* border: solid 1px #000000; */
  color: #eb613b;
}

.c-pagenavi .previouspostslink,
.c-pagenavi .nextpostslink{
  border: solid 1px #eb613b;
  background: #eb613b;
  color: #fff !important;
}

.c-pagenavi .wp-pagenavi .current {
  border: solid 1px #eb613b;
  color: #eb613b;
}

/* .c-pagenavi__prev {
  display: inline-block;
  margin: 0 50px;
}

.c-pagenavi__next {
  display: inline-block;
  margin: 0 50px;
} */
