/*// -----------------------------------------------------------------
// 華はうすについて
// -----------------------------------------------------------------*/


.p-page__ttl {
  padding-top: 0;
  padding-bottom: 40px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  @media ( max-width: $screen-xs-max ){
    font-weight: 500;
    font-size: 24px;
  }
}

.p-page__subttl {
  display: block;
  color: #7f7f7f;
  font-size: 15px;
  font-family: 'Yantramanav', sans-serif;
  font-weight: normal;
  @media ( max-width: $screen-xs-max ){
    font-size: 14px;
  }
}

.p-about__message--copy{
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  display: block;
  @media ( max-width: $screen-xs-max ){
    text-align: left;
  }
}

.p-about__message--txt{
  margin:20px 0 40px;
  line-height: 2;
}

.p-about__message--president{
  font-size:16px;
  font-weight: bold;
  font-style: normal;
  display: block;
}

.p-about__message--bg{
  background: url(#{$imagepath}img/about/president.png) no-repeat bottom right;
  background-size: 200px;
  padding-bottom:200px;
  @media ( max-width: $screen-xs-max ){
    background: url(#{$imagepath}img/about/president.png) no-repeat bottom center;
    background-size: 200px;
    padding-bottom:280px;
  }
}



.p-about__what{
  background: #fbdfd8;
  padding:70px 0;
  @media ( max-width: $screen-xs-max ){
  padding:35px 0;
  }
}

.p-about__what--list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.p-about__what--item{
  width: 48%;
  background: #fff;
  padding:30px 20px;
  margin-bottom:4%;
  @media ( max-width: $screen-xs-max ){
  width: 100%;
  }
}

.p-about__what--item__ttl{
  font-size:18px;
  font-weight: bold;
  position: relative;
  padding-left:55px;
  min-height: 85px;
  &:before{
    content: '';
    background: url(#{$imagepath}img/about/icon1.png) no-repeat top left;
    width: 50px;
    height: 50px;
    position: absolute;
    top:0;
    left:0;
  }
}

.p-about__what--item__txt{
  min-height: 180px;
  padding:0;
  margin:0;
  line-height: 2;
}

.p-about__what--item__btn{
  text-align: center;
  margin-top:20px;
}

.p-about__our{
  background: url(#{$imagepath}img/about/bg.jpg) no-repeat top center;
  background-size:cover;
}

.p-about__our--bg{
  background : rgba(255, 255, 255, 0.7);
  padding:70px 0;
  text-align: center;
  position: relative;
  @media ( max-width: $screen-xs-max ){
  padding:35px 0 0;
  }
}

.p-about__our--img{
  display: block;
  padding-bottom:20px;
  margin: 0 auto;
}

.p-about__our--meta{
  margin:20px 0;
  @media ( max-width: $screen-xs-max ){
    padding:0 15px;
  }
  hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background-color: #d1d1d1;
  border: 0;
  height: 2px;
  margin: 0 0 1.75em;
  margin-right: 20%;
  margin-left: 20%;
  }
}

.p-about__our--ttl2{
  font-size:27px;
  font-weight: bold;
}

.p-about__our--txt{
  font-size:18px;
  line-height: 1.8em;
  @media ( max-width: $screen-xs-max ){
    text-align: left;
    br{
      display: none;
    }
  }
}

.p-about__our--img2{
  position: absolute;
  right: -100px;
  bottom:0;
  @media ( max-width: $screen-xs-max ){
  position:static;
  right: auto;
  bottom:auto;
  }
}


.p-about__spots{
  padding: 70px 0;
  @media ( max-width: $screen-xs-max ){
  padding:35px 0 0;
  }
}

.p-about__spots--ttl{
  br{
    display: none;
    @media ( max-width: $screen-xs-max ){
      display: block;
    }
  }
}

.p-about__spots--copy{
  display: block;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  @media ( max-width: $screen-xs-max ){
  text-align: left;
  }
}

.p-about__spots--list{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  margin:40px 0;
}
.p-about__spots--item{
  display: block;
  position: relative;
  width: 23%;
  height: auto;
  margin: 0 1% 2% 1%;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
  font-size: 14px;
  list-style-type: none;
  text-align: left;
  @media ( max-width: $screen-xs-max ){
    width: 48%;
  }
}

.p-about__spots--item__img{
  margin:0;
  img{
    width: 100%;
  }
}

.p-about__spots--item__ttl{
  padding:10px 10px 0;
  font-size: 16px;
  font-weight: bold;
}

.p-about__spots--item__txt{
  padding:10px;
  margin:0;
  line-height: 1.8;
}

.p-about__spots--item__btn{
  text-align: center;
  padding:10px 0 20px;
}


.p-about__business{
  padding: 70px 0 0;
  background: #f3ecd4;
  @media ( max-width: $screen-xs-max ){
  padding:35px 0 0;
  }
}


.p-about__business--table{
  background: #fff;
  th{
    width: 120px;
  }
}

.p-about__business-mapimg{
  margin: 70px 0 0;
  @media ( max-width: $screen-xs-max ){
  margin:35px 0 0;
  }
  img{
    width: 100%;
  }
}

.p-about__business--map{
  padding: 70px 0 0;
  @media ( max-width: $screen-xs-max ){
  padding:35px 0 0;
  }
}


#map_canvas {
  width: 100%;
  height: 550px;
  @media ( max-width: $screen-xs-max ){
    padding: 35px 0 0;
    // height: 300px;
  }
}
