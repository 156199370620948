/*// -----------------------------------------------------------------
// red-style.css
// -----------------------------------------------------------------*/
/*
red-style.css
をベースにカスタマイズ
*/


body, button, input, textarea {
  background-color: #e6e6e6;
}

.widget select, .widget textarea {
  background-color: #f7f7f7;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  padding: 0.4375em;
  width: 100%;
}


button:focus .kogma_drawer_menu .kogma_drawer_button .kogma_drawer_bar, .widget button, #list_add_table dt, .site-header-main-in .info span, .single-post header.entry-header {
  background-color: #eb613b;
}
header.entry-header span.bu-cat{
  background-color: #aeaeae;
}
.header-image{
  background-color: #000000;
}
.site-header-main-in .info {
  color: #7A8687;
}

.contact-box-form, .site-header-main-in .info span, .kogma_drawer_menu .kogma_drawer_button, .pagination a, .info-bottom a, #list_add_table dt, #syousai_box .new_mark:after, .grid-content.cookie_fudou_single .new_mark:after {
  color: #fff;
}

.single-post header.entry-header {
  color: #fff;
  text-shadow: 0 0 10px #000, 0 0 15px #000;
}

.site-header-main-in .info strong, .top-btn-left a:before, .top-btn-right a:before {
  color: #000000;
}

.site-header-main {
  background-color: rgba(255, 255, 255, 0.93);
}

.kogma_drawer_menu .kogma_drawer_nav_wrapper, .site, .site-inner, .site-footer, td.td1 span {
  background-color: #fff;
}

.kogma_drawer_menu .kogma_drawer_button.active {
  background-color: #aeaeae;
}


#ponopono, #top-map, #top-eye {
  background-color: #f2f2f2;
}

/*drawer_menu end*/
table {
  border: 1px solid #eb613b;
}
th, td {
  border: 1px solid #eb613b;
}
th {
  background: #f7c0b1;
}
// table {
//   border-top: 1px solid #eb613b;
//   border-bottom: 1px solid #eb613b;
// }
//
// th, td {
//   border-right: 1px solid #eb613b;
// }

// td:last-child {
//   border-right: none;
// }
//
// tr:nth-child(even) {
//   background: #fce6df;
// }

td.td1 span {
  border: 1px solid #dad4d4;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

/* Block: Quote -------------------------------------------- */
blockquote {
  margin: 0 auto;
}

.wp-block-quote {
  background: #f6f6f6;
  clear: both;
  color: #666;
  display: block;
  font-size: 1em;
  max-width: 960px;
  width: 100%;
  margin: 0 auto 1.75em auto;
  position: relative;
  padding: 30px 30px 10px 90px;
}

.wp-block-quote p {
  padding: 0 0 10px 0px;
  margin: 0;
  width: 100%;
}

.wp-block-quote:before {
  color: #aaa;
  content: '”';
  display: block;
  font-family: 'Georgia', 'Times New Roman', serif;
  font-size: 72px;
  font-weight: 700;
  line-height: 72px;
  position: absolute;
  left: 0px;
  top: 32px;
  text-align: center;
  width: 90px;
  -webkit-font-smoothing: antialiased;
}

cite {
  font-weight: 700;
}

cite::before, .wp-block-quote footer::before, .wp-block-quote__citation::before, .wp-block-pullquote__citation::before {
  content: "— ";
}

.new_mark {
  background-color: #eb613b;
  color: #fff;
  text-transform: uppercase;
}

.eye-mark, .login_success .logout_title a, .login_success .repass_title a {
  color: #fff;
  background-color: #eb613b;
}

.login_success .logout_title a:hover, .login_success .repass_title a:hover {
  color: #fff;
  background-color: #aeaeae;
}

button, button[disabled]:hover, button[disabled]:focus, input[type="button"], input[type="button"][disabled]:hover, input[type="button"][disabled]:focus, input[type="reset"], input[type="reset"][disabled]:hover, input[type="reset"][disabled]:focus, input[type="submit"], input[type="submit"][disabled]:hover, input[type="submit"][disabled]:focus {
  background-color: #eb613b;
  // border: 0;
  border-radius: 2px;
  color: #fff;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-weight: normal;
  letter-spacing: 0.046875em;
  line-height: 1;
  padding: 0.84375em 0.875em 0.78125em;
  text-transform: uppercase;
  border: solid 2px #eb613b;
}

.widget.widget_fudo_search {
  width: 100%;
  margin: 10px auto 0 auto;
}

button:focus, input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus {
  outline: thin dotted;
  outline-offset: -4px;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
  background-color: #f7f7f7;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  color: #686868;
  padding: 0.625em 0.4375em;
  margin-top: 10px;
  width: 100%;
}

input[type="date"]:focus, input[type="time"]:focus, input[type="datetime-local"]:focus, input[type="week"]:focus, input[type="month"]:focus, input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="number"]:focus, textarea:focus {
  background-color: #fff;
  border-color: #eb613b;
  color: #000000;
  outline: 0;
}

.post-password-form label {
  color: #686868;
}

//消す
// a, .sidebar .widget_fudou_jsearch_history li a {
//   color: #eb613b;
//   text-decoration: none;
// }

a:hover, a:focus, a:active, .sidebar .widget_fudou_jsearch_history li a:hover {
  color: #aaaaaa;
}

.main-navigation li {
  border-top: 1px solid #d1d1d1;
  position: relative;
}

.main-navigation a:hover, .main-navigation a:focus {
  color: #eb613b;
}

.dropdown-toggle:after {
  border: 0 solid #d1d1d1;
  border-left-width: 1px;
  content: "\f431";
  font-size: 24px;
  left: 1px;
  position: relative;
  width: 48px;
}

.dropdown-toggle:hover, .dropdown-toggle:focus {
  background-color: transparent;
  color: #eb613b;
}

.social-navigation a:hover:before, .social-navigation a:focus:before {
  color: #eb613b;
}

/** nav　pagenation */
#nav-above1 .nav-next {
  width: 100%;
  margin: 20px auto 10px auto;
  padding: 10px;
  text-align: right;
}

#nav-above1 .nav-next a {
  letter-spacing: 0.013157895em;
  line-height: 1;
  margin: 0;
  padding: 0.3947368421em 0.7em;
  text-transform: uppercase;
  transition: .3s ease-in-out;
}

#nav-above1 .nav-next b {
  font-weight: 700;
  padding: 0.34em 0.7586em;
}

.nav-previous {
  text-align: left;
  margin-top: 30px;
}

.pagination {
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 19px;
  font-size: 1.1875rem;
  min-height: 56px;
  position: relative;
}

.pagination .current .screen-reader-text {
  position: absolute !important;
}

.pagination:before {
  right: 0;
}

.pagination:after {
  right: 54px;
}

.pagination .nav-links {
  padding-right: 106px;
  position: relative;
}

.pagination .nav-links:before {
  content: "\f429";
  right: -1px;
}

.pagination .nav-links:after {
  content: "\f430";
  right: 55px;
}

.pagination .prev:focus, .pagination .next:focus {
  outline: 0;
}

.pagination .prev {
  right: 54px;
}

.pagination .prev:before {
  content: "\f430";
  left: -1px;
  top: -1px;
}

.pagination .next {
  right: 0;
}

.pagination .next:before {
  content: "\f429";
  right: -1px;
  top: -1px;
}

.post-navigation {
  border-top: 4px solid #000000;
  border-bottom: 4px solid #000000;
  clear: both;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  margin: 0 7.6923% 3.5em;
}

.post-navigation a {
  color: #000000;
  display: block;
  padding: 1.75em 0;
}

.post-navigation span {
  display: block;
}

.post-navigation .meta-nav {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  letter-spacing: 0.076923077em;
  line-height: 1.6153846154;
  margin-bottom: 0.5384615385em;
  text-transform: uppercase;
}

.post-navigation .post-title {
  display: inline;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 700;
  line-height: 1.2173913043;
  text-rendering: optimizeLegibility;
}

.post-navigation a:hover .post-title, .post-navigation a:focus .post-title {
  color: #eb613b;
}

.post-navigation div + div {
  border-top: 4px solid #000000;
}

.pagination:before, .pagination:after {
  background-color: #e3e3e3;
  content: "";
  height: 52px;
  position: absolute;
  top: 0;
  width: 52px;
  z-index: 0;
}

.pagination a:hover, .pagination a:focus {
  color: #000000;
}

.pagination .nav-links:before, .pagination .nav-links:after {
  color: #eb613b;
  font-size: 32px;
  line-height: 51px;
  opacity: 0.3;
  position: absolute;
  width: 52px;
  z-index: 1;
}

.pagination .page-numbers {
  display: none;
  background-color: #eb613b;
  letter-spacing: 0.013157895em;
  line-height: 1;
  margin: 0;
  padding: 0.3947368421em 0.7em;
  text-transform: uppercase;
  border: 1px solid #eb613b;
}

.pagination a:hover {
  background-color: #eb613b;
  border: 1px solid #eb613b;
  color: #fff;
}

.pagination .current {
  display: inline-block;
  font-weight: 700;
  background-color: #e3e3e3;
  border: 1px solid #dad4d4;
  color: #eb613b;
}

.pagination .prev, .pagination .next {
  background-color: #eb613b;
  color: #fff;
  display: inline-block;
  height: 52px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 52px;
  z-index: 2;
}

.pagination .prev:before, .pagination .next:before {
  font-size: 32px;
  height: 53px;
  line-height: 52px;
  position: relative;
  width: 53px;
}

.pagination .prev:hover, .pagination .prev:focus, .pagination .next:hover, .pagination .next:focus {
  background-color: #eb613b;
  border: 1px solid #eb613b;
  color: #fff;
}

.image-navigation, .comment-navigation {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  color: #686868;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin: 0 7.6923% 2.1538461538em;
  padding: 1.0769230769em 0;
}

.site .skip-link {
  background-color: #f1f1f1;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  color: #eb613b;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 700;
  left: -9999em;
  outline: none;
  padding: 15px 23px 14px;
  text-decoration: none;
  text-transform: none;
  top: -9999em;
}

.logged-in .site .skip-link {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  font-family: "Open Sans", sans-serif;
}

.wg-footer .widget .widget-title {
  color: #ffffff;
}

.widget-title a {
  color: #000000;
}
.wg-footer .widget .widget-title a {
  color: #fff;
}

/* Calendar widget */
.widget.widget_calendar table {
  margin: 0;
  border: 1px solid #dad4d4;
}

.widget_calendar td, .widget_calendar th {
  line-height: 2.5625;
  padding: 0;
  text-align: center;
}

.widget_calendar caption {
  font-weight: 900;
  margin-bottom: 0.75em;
  color: #eb613b;
}

.widget_calendar a {
  display: block;
  font-weight: 700;
  background-color: #ffffff;
}
.widget_calendar th {
  background-color: #aaa;
}
.widget_calendar td {
  color: #000000;
  background-color: #f5f5f5;
  border-bottom: 1px solid #dad4d4;
}
.widget_calendar td#today {
  background-color: #eb613b;
  color: #fff;
}
.widget_calendar tbody a:hover, .widget_calendar tbody a:focus {
  background-color: #686868;
  color: #fff;
}

/* Recent Posts widget */
.widget_recent_entries .post-date {
  color: #686868;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.615384615;
  margin-bottom: 0.538461538em;
}

.widget_recent_entries li:last-child .post-date {
  margin-bottom: 0;
}

/* RSS widget */
.widget_rss .rsswidget img {
  margin-top: -0.375em;
}

.widget_rss .rss-date, .widget_rss cite {
  color: #686868;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: normal;
  line-height: 1.615384615;
  margin-bottom: 0.538461538em;
}

.widget_rss .rssSummary:last-child {
  margin-bottom: 2.1538461538em;
}

.widget_rss li:last-child :last-child {
  margin-bottom: 0;
}

/* Tag Cloud widget */
.tagcloud a {
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  display: inline-block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  line-height: 1;
  margin: 0 0.1875em 0.4375em 0;
  padding: 0.5625em 0.4375em 0.5em;
}

.tagcloud ul {
  list-style-type: none;
  margin-left: 0;
}

.tagcloud ul li {
  display: inline-block;
}

.tagcloud a:hover, .tagcloud a:focus {
  border-color: #916b54;
  color: #916b54;
  outline: 0;
}

.site-branding .site-title a {
  color: #000000;
}

.site-branding .site-title a:hover, .site-branding .site-title a:focus {
  color: #6f6f6f;
}

.wp-custom-logo .site-title {
  margin-top: 0.608695652em;
}

.site-description {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.0769230769;
  margin: 0.3em 0 0;
}

.menu-toggle:focus {
  outline: 0;
}

.menu-toggle.toggled-on:focus {
  outline: thin dotted;
}

button:focus, input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus {
  outline: thin dotted;
  outline-offset: -4px;
}

.no-js .menu-toggle {
  display: none;
}

.entry-content, .entry-summary {
  border-color: #d1d1d1;
  line-height: 30px;
}

body:not(.search-results) .entry-summary {
  color: #686868;
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1.4736842105;
  margin-bottom: 1.4736842105em;
}

.author-bio {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin-bottom: 1.6153846154em;
  overflow: hidden;
}

.author-link {
  white-space: nowrap;
}

.entry-footer {
  color: #686868;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin-top: 2.1538461538em;
}

.entry-footer:empty {
  margin: 0;
}

.entry-footer a {
  color: #686868;
}

.entry-footer a:hover, .entry-footer a:focus {
  color: #ff8c00;
}

.entry-footer > span:not(:last-child):after {
  content: "\002f";
  display: inline-block;
  opacity: 0.7;
  padding: 0 0.538461538em;
}

.entry-footer .avatar {
  height: 21px;
  margin: -0.1538461538em 0.5384615385em 0 0;
  width: 21px;
}

.sticky-post {
  color: #686868;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  letter-spacing: 0.076923077em;
  line-height: 1.6153846154;
  margin-bottom: 0.5384615385em;
  text-transform: uppercase;
}

.taxonomy-description {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
}

.page-links {
  clear: both;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  margin: 0 0 1.75em;
}

.page-links a, .page-links > span {
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  display: inline-block;
  font-size: 13px;
  font-size: 0.8125rem;
  height: 1.8461538462em;
  line-height: 1.6923076923em;
  margin-right: 0.3076923077em;
  text-align: center;
  width: 1.8461538462em;
}

.page-links a {
  background-color: #000000;
  border-color: #000000;
  color: #fff;
}

.page-links a:hover, .page-links a:focus {
  background-color: #eb613b;
  border-color: transparent;
  color: #fff;
}

.page-links > .page-links-title {
  border: 0;
  color: #000000;
  height: auto;
  margin: 0;
  padding-right: 0.6153846154em;
  width: auto;
}

.entry-attachment {
  margin-bottom: 1.75em;
}

.entry-caption {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: italic;
  line-height: 1.6153846154;
  padding-top: 1.0769230769em;
}

.comments-title, .comment-reply-title {
  border-top: 1px solid #d1d1d1;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 700;
  line-height: 1.3125;
  padding-top: 1.217391304em;
}

.comment-list article, .comment-list .pingback, .comment-list .trackback {
  border-top: 1px solid #d1d1d1;
  padding: 1.75em 0;
}

.comment-author {
  color: #000000;
  margin-bottom: 0.4375em;
}

.comment-metadata, .pingback .edit-link {
  color: #686868;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
}

.comment-metadata {
  margin-bottom: 2.1538461538em;
}

.comment-metadata a, .pingback .comment-edit-link {
  color: #686868;
}

.comment-metadata a:hover, .comment-metadata a:focus, .pingback .comment-edit-link:hover, .pingback .comment-edit-link:focus {
  color: #eb613b;
}

.comment-reply-link {
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  color: #eb613b;
  display: inline-block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1;
  margin-top: 2.1538461538em;
  padding: 0.5384615385em 0.5384615385em 0.4615384615em;
}

.comment-reply-link:hover, .comment-reply-link:focus {
  border-color: currentColor;
  color: #eb613b;
  outline: 0;
}

.comment-form {
  padding-top: 1.75em;
}

.comment-form label {
  color: #686868;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  letter-spacing: 0.076923077em;
  line-height: 1.6153846154;
  margin-bottom: 0.5384615385em;
  text-transform: uppercase;
}

.comment-list .comment-form {
  padding-bottom: 1.75em;
}

.comment-notes, .comment-awaiting-moderation, .logged-in-as, .form-allowed-tags {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin-bottom: 2.1538461538em;
}

.no-comments {
  border-top: 1px solid #d1d1d1;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-weight: 700;
  margin: 0;
  padding-top: 1.75em;
}

.required {
  color: #eb613b;
  font-family: Merriweather, Georgia, serif;
}

.comment-reply-title small a:hover, .comment-reply-title small a:focus {
  color: #000000;
}

.site-info {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  float: left;
}
.top-btn {
  margin: 0 auto;
  padding: 0;
  max-width: 840px;
  width: 90%;
  text-align: center;
}

.top-btn a {
  border-right: none;
  font-size: 1.2rem;
  margin: 40px 0 20px 0;
  padding: 5px 0;
  line-height: 3;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  display: block;
  transition: .3s ease-in-out;
}

.top-btn a:before {
    content: "\f429";
    font-size: 30px;
    line-height: 30px;
    position: relative;
    width: 35px;
    font-family: "Genericons";
    left: 3px;
    top: 7px;
}

.info-bottom, .kogma_drawer_menu .kogma_drawer_button, .top-btn a {
  background-color: #eb613b;
  color: #fff;
}

.wp-caption .wp-caption-text {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: italic;
  line-height: 1.6153846154;
  padding-top: 0.5384615385em;
}

.entry-content h2:before {
  border-left: solid 1px #000000;
  border-top: solid 1px #000000;
  border-bottom: solid 1px #000000;
  left: 0;
}

.entry-content h2:after {
  content: '';
  border-top: solid 1px #000000;
  border-right: solid 1px #000000;
  border-bottom: solid 1px #000000;
  right: 0;
}

header.entry-header span.bu-cat {
  color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.17);
}

.wg-footer {
  background-color: #000000;
  color: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
.wg-footer li a {
  color: #ccc;}
.wg-footer li a:hover {
  color: #eb613b;}
.content-bottom-widgets .widget-area #searchitem {
  color: #eb613b;
}



.top_price {
  font-weight: 600;
  color: #ee686d;
  font-size: 130%;
  font-family: arial;
  padding-left:10px;
}

.top_madori {
  font-weight: 700;
  color: #eb613b;
  font-size: 130%;
  font-family: arial;
  padding-right:10px;
}

.box1low a {
  color: #fff;
  background-color: #eb613b;
  border: solid 2px #eb613b;
}

.box1low a:hover {
  color: #eb613b;
  background-color: #fff;
  border: solid 2px #eb613b;
}

.top_title {
  color: #000;
}

.top_shozaichi {
  display: block;
  padding-left:10px;
  padding-right:10px;
}

.top_kotsu{
  padding-left:10px;
  padding-right:10px;
}

#nendebcopy {
  background-color: rgba(255, 255, 255, 0);
  margin: 0 auto;
  text-align: center;
}

#nendebcopy a {
  color: #a0a5aa;
  font-size: 80%;
}

/** top banner */
.top-btn-title {
  color: #fff;
  background-color: #eb613b;
}

.top-btn-title:after {
  border-color: transparent;
  border-left-color: #eb613b;
}

.top-btn-right a, .top-btn-left a {
  color: #000000;
  background-color: rgba(255, 255, 255, 0.89);
}

.top-btn-left a {
  border-right: 1px solid #aaa;
}

.top-btn-right a {
  border-left: 2px solid #fff;
}

.top-btn-right a:hover, .top-btn-left a:hover {
  background-color: #fff;
  text-shadow: 0 0 10px #d1d1d1, 0 0 15px #d1d1d1;
}

/** slider */
.mb60 {
  margin-bottom: 60px;
}

.header-image span {
  text-shadow: 0 0 10px #000, 0 0 15px #000;
  color: #fff;
}

.prettyprint {
  border: none;
  background-color: #fafafa;
  color: #697d86;
}

.swiper-slide-next, .swiper-slide-prev {
  display: inline-block;
}

.swiper-slide-prev img, .swiper-slide-next img {
  opacity: 0.6;
}

.kaiin_register a {
  color: #fff;
  padding: 10px 5%;
  background-color: #000000;
}

.kaiin_register a:hover.thickbox {
  color: #fff;
  background-color: #aeaeae;
}

kaiin-readtxt {
  border: solid 1px #e3e3e3;
}

.list_picsam {
  margin-bottom: 30px;
  padding: 10px 0;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.67);
  border-radius: 50%;
}

.swiper-pagination-bullet-active {
  background-color: rgba(255, 255, 255, 0.93);
  transform: scale(1.4);
}

.list_price dt.leftline {
  border-left: 1px solid rgba(65, 65, 65, 0.25);
}

#list_add_table dl {
  border-bottom: 1px solid #eb613b;
  //border-bottom: 1px solid rgba(65, 65, 65, 0.25);
}

.dpoint4 {
  font-weight: 700;
  color: #eb613b;
}

h2.section-title {
  color: #eb613b;
}

.widget h3.section-title {
  color: #000000;
}

#list_other_table h3, .widget h3 {
  color: #000000;
}



.contact-chui {
  background-color: #ee686d;
  color: #fff;
  border-top: 2px solid #ee686d;
}



//消す
// .pageback a {
//   background-color: #eb613b;
//   padding: 2px 25px 3px 25px;
//   border-radius: 10px;
//   transition: .3s ease-in-out;
//   color: #fff;
// }
//
// .pageback a:hover {
//   color: #fff;
//   background-color: #aeaeae;
// }

.contact-box-form a {
  background-color: #eb613b;
  color: #fff;
}

.contact-box-form a:hover {
  background-color: #ee686d;
  color: #fff;
}

.contact-form2 {
  border: 1px solid #d1d1d1;
}

#nav-above1 .nav-next a {
  background-color: #e6e6e6;
  color: #eb613b;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}

#nav-above1 .nav-next a:hover {
  background-color: #aeaeae;
  border: 1px solid #aeaeae;
  color: #fff;
}

#nav-above1 .nav-next b {
  background-color: #eb613b;
  border: 1px solid #eb613b;
  color: #ffffff;
}

#map_search h3 {
  background-color: #e3e3e3 !important;
}

#map_menu {
  border: none !important;
  background-color: none !important;
}

#bread-out {
  background-color: #f2f2f2;
}

@media screen and (min-width: 56.875em) {
  // button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus {
  //   background-color: #aeaeae;
  // }

  .kogma_drawer_menu .kogma_drawer_button:hover, .top-btn a:hover {
    background-color: #333333;
  }
}
@media screen and (max-width: 910px) {

  .menu-toggle.toggled-on {
    background-color: #aeaeae;
    color: #fff;
  }
}
@media screen and (max-width: 600px) {
  .top-btn-left a {
    border-right: 0;    border-bottom: 1px solid #ababab;
  }

  .top-btn-right a {
    border-left: 0;
    border-top: 1px solid #ffffff;
  }
  .top-btn-right a, .top-btn-left a {
    color: #00222f;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.73);
    border-top: 1px solid #ffffff;
}
.top-btn-title {
      border-top: 1px solid #eb613b;
      background-color: rgba(191, 0, 0, 0.68);
}

.top-btn a {
  margin: 20px 0;
  padding: 0;
  line-height: 2.5;
}

}
@media screen and (max-width: 420px) {
  .kogma_drawer_menu .kogma_drawer_button {
    border-bottom: 8px solid #fff;
    border-left: 8px solid #fff;
    border-top: 8px solid #fff;
    border-right: 4px solid #fff;
  }

  .info-bottom {
    border-bottom: 8px solid #fff;
    border-left: 4px solid #fff;
    border-top: 8px solid #fff;
    border-right: 8px solid #fff;
  }
}
