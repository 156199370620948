@charset "UTF-8";

// ==========================================================================
// Foundation
// ==========================================================================

@import "foundation/_market-style.scss";
@import "foundation/_market-red-style.scss";
@import "foundation/_base.scss";

/*// -----------------------------------------------------------------
// packages
// -----------------------------------------------------------------*/
// @import url(../../../packages/font-awesome/css/font-awesome.min.css);

/*// ==========================================================================
// Layout
// ==========================================================================*/

@import "layout/_header.scss";
// @import "layout/_main.scss";
@import "layout/_page.scss";
@import "layout/_footer.scss";

/*// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------*/

@import "object/component/_container.scss";
@import "object/component/_header.scss";
@import "object/component/_footer.scss";
@import "object/component/_editor-style.scss";
@import "object/component/_btn.scss";
@import "object/component/_pagenavi.scss";


/*// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------*/

@import "object/project/_top.scss";
@import "object/project/_information.scss";
@import "object/project/_fudou.scss";
@import "object/project/_about.scss";
@import "object/project/contact.scss";


/*// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------*/

@import "object/utility/clearfix.scss";