/*// -----------------------------------------------------------------
// お知らせ一覧
// -----------------------------------------------------------------*/

.p-information{
  margin-top:70px;
  @media ( max-width: $screen-xs-max ){
    margin-top:35px;
  }
}

.p-information__main{
  width: 64%;
  float: left;
  @media ( max-width: $screen-xs-max ){
    width: 100%;
    float:none;
  }
}

.p-information--list{
  padding:0;
  margin:0;
}
.p-information--item {
}

.p-information--item__link{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  padding: 0 0 40px;
  margin: 0 0 40px;
  border-bottom:dotted 2px #eb613b;

}
//
// .p-information--list{
//   &:first-child{
//     .p-information--item__link{
//       padding: 0 0 40px;
//     }
//   }
// }

.p-information--item__img{
  width: 30%;
  margin:0;
  padding: 0;
  @media ( max-width: $screen-xs-max ){
    width: 100%;
  }
  img{
    width: 100%;
  }
}

.p-information--item__data{
  width: 65%;
  margin-left:5%;
  @media ( max-width: $screen-xs-max ){
    width: 100%;
    margin:15px 0 0 0;
  }
}

.p-information--item__ttl{
  font-size:18px;
  font-weight: bold;
  margin-bottom:15px;
}

.p-information--item__meta{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:0;
  margin-bottom:15px;
}

.p-information--item__meta--date{
  color: #7f7f7f;
}

.p-information--item__meta--cate{
  background: #cccccc;
  border-radius: 5px;
  padding:5px 20px;
  font-size:14px;
  min-width: 130px;
  text-align: center;
}

a:hover .p-information--item__meta--cate{
color: #000000;
}

.p-information--item__meta--cate.__column{
  background: #ddedbb;
}
.p-information--item__meta--cate.__news{
  background: #f9cfc4;;
}



/*// -----------------------------------------------------------------
// お知らせ詳細
// -----------------------------------------------------------------*/

.p-information__ttl {
  font-size: 36px;
  font-weight: bold;
  display: block;
  border-bottom: solid 4px #eb613b;
  padding-bottom:10px;
}

.p-information__meta{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:0;
  margin:25px 0;
}

.p-information__meta--date{
  color: #7f7f7f;
}

.p-information__meta--cate{
  background: #cccccc;
  border-radius: 5px;
  padding:5px 20px;
  font-size:14px;
  min-width: 130px;
  text-align: center;
}

.p-information__meta--cate.__column{
  background: #ddedbb;
}
.p-information__meta--cate.__news{
  background: #f9cfc4;;
}







/*// -----------------------------------------------------------------
// お知らせサイドコンテンツ
// -----------------------------------------------------------------*/


.p-information__side{
  width: 28%;
  float: right;
  line-height: 1.8em;
  @media ( max-width: $screen-xs-max ){
    width: 100%;
    float:none;
  }
}
@media (max-width: 767px) {
  .p-information__side{
    width: 100%;
    float: left;
  }
}

.p-information__side--widget {
  margin-bottom: 60px;
}

.p-information__side--widget-title{
  font-size: 16px;
  font-weight: bold;
  color: #231815;
  border-top: solid 1px #eb613b;
  border-bottom: solid 1px #eb613b;
  line-height: 2em;
}

.p-information__side--widget-list{
  padding: 0;
}

.p-information__side--widget-list li {
  margin: 1em 0;
  font-size: 16px;
}

.p-information__side--widget-list a {
  color: #000000;
  text-decoration: none;
  &:hover:not(.c-side-cate) {
    color: #eb613b;
  }
}

.p-information__side--widget-list .cat-item a::before{
  font-family: FontAwesome;
  content: '\f105';
  margin-right: 2px;
  color: #ccc;
}


// .p-information__side--widget-list .c-side-cate{
//   padding: 5px 12px;
//   height: 24px;
//   background: #f2e9cf;
//   color: #231815;
//   font-size: 12px;
// }

.p-information__side--widget-list .c-side-ttl{
  display: block;
  margin-bottom:10px;
}

.p-information__side--widget-list .c-side-date{
  font-size: 12px;
  display: block;
  color: #7f7f7f;
}

.c-side-mata{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:0;
  margin-bottom:15px;
}

.c-side-cate{
  background: #cccccc;
  border-radius: 5px;
  padding:5px 20px;
  font-size:14px;
  min-width: 130px;
  text-align: center;
}

a:hover .c-side-cate{
  color: #000000;
}

.c-side-cate.__column{
  background: #ddedbb;
}
.c-side-cate.__news{
  background: #f9cfc4;;
}




/*// -----------------------------------------------------------------
// 詳細ページページング
// -----------------------------------------------------------------*/
.p-information-nav {
  padding: 40px 0 40px;
  text-align: center;
}

.p-information-nav a.page {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;

  padding: 0.5em 2.5em;
  border: solid 2px #eb613b;
  border-radius: 5px;
  background: #eb613b;
  color: #ffffff;
  transition: .4s;

}

.p-information-nav a.page:hover {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  color: #eb613b;
}

.p-information-nav .current {
  display: inline-block;
  margin: 0 5px;
  padding: 5px 15px;
  border: solid 1px #eb613b;
  color: #eb613b;
}

.p-information-nav .previouspostslink {
  width: 40px;
  height: 40px;
  float: left;
  border: none;
  font-size: 20px;
  line-height: 36px;

  border: solid 2px #eb613b;
  border-radius: 5px;
  background: #eb613b;
  color: #ffffff;
  transition: .4s;
}

.p-information-nav .previouspostslink i {
  margin-left: -4px;
}

.p-information-nav .previouspostslink:hover {
  width: 40px;
  height: 40px;
  border: none;
  border: solid 2px #eb613b;
  background: #ffffff;
  color: #eb613b;
}

.p-information-nav .nextpostslink {
  width: 40px;
  height: 40px;
  float: right;
  border: none;
  font-size: 20px;
  line-height: 36px;

  border: solid 2px #eb613b;
  border-radius: 5px;
  background: #eb613b;
  color: #ffffff;
  transition: .4s;
}

.p-information-nav .nextpostslink i {
  margin-right: -4px;
}

.p-information-nav .nextpostslink:hover {
  width: 40px;
  height: 40px;
  border: none;
  border: solid 2px #eb613b;
  background: #ffffff;
  color: #eb613b;
}

.p-information-nav .post-nav .list_top {
  display: inline-block;

  font-size: 14px;
  text-align: center;

  padding: 0.5em 2.5em;
  border: solid 2px #eb613b;
  border-radius: 5px;
  background: #eb613b;
  color: #ffffff;
  transition: .4s;
}

.p-information-nav .post-nav .list_top:hover {
  border: solid 2px #eb613b;
  background: #ffffff;
  color: #eb613b;
  text-decoration: none;
}