/*// -----------------------------------------------------------------
// 物件一覧
// -----------------------------------------------------------------*/

.p-fudou__searchresults{
  margin: 70px 0 40px;
  @media ( max-width: $screen-xs-max ){
    margin: 0 0 40px;
  }
  th{
    width: 100px;
    @media ( max-width: $screen-xs-max ){
      padding: 0.5em;
    }
  }
  td{
    // width : -webkit-calc(100% - 100px) ;
    // width : calc(100% - 100px) ;
    @media ( max-width: $screen-xs-max ){
      padding: 0.5em;
    }
  }
}

.p-fudou__searchbtn {
  border-top:solid 1px #eb613b;
  border-bottom:solid 1px #eb613b;
  background: #eb613b;
  color: #ffffff;
  padding:10px 0;
  cursor: pointer;
  small{
    color: #ffffff;
    font-size:20px;
    display: none;
  }
}
.p-fudou__searchbtn_txt{
  position: relative;
  padding: 0 1em;
  font-size:20px;
  font-weight: bold;
  &:before{
    font-family: FontAwesome;
    content: "\f107";
    padding-right:10px;
  }
}
.p-fudou__searchbtn_txt.__changed{
  &:before{
    font-family: FontAwesome;
    content: "\f106";
    padding-right:10px;
  }
}

.p-fudou__search{
  margin:40px 0;
  display: none;
}

.p-fudou__bukkenno{
  text-align: left;
  padding:10px 0;
  em{
    font-size: 110%;
    font-weight: bold;
    color: #eb613b;
    font-style: normal;
  }
}

// 新着、更新　アイコン
.p-fudou__new_mark{
  margin: 0;
  position: absolute;
  display: table;
  z-index: 2;
  color: #fff;
  font-size:14px;
  text-align: center;
  background: url(#{$imagepath}img/fudo/status_icon.png) no-repeat top center;
  width: 80px;
  height:80px;
  top:0;
  left:0;
  span{
    display: table-cell;
    vertical-align: middle;
  }
}


/*// -----------------------------------------------------------------
// 物件詳細
// -----------------------------------------------------------------*/

.p-fudou__main{
  padding: 0 0 70px;
}

// 新着、更新　アイコン
.p-fudou-detail__new_mark{
  margin: 0;
  display: table;
  color: #fff;
  font-size:14px;
  text-align: center;
  background: url(#{$imagepath}img/fudo/status_icon.png) repeat top center;
  width: 80px;
  height:80px;
  margin-right: 20px;
  @media ( max-width: $screen-xs-max ){
    background-size: cover;
    width: 60px;
    height:60px;
    margin-right: 10px;
    font-size:12px;
  }
  span{
    display: table-cell;
    vertical-align: middle;
  }
}

.p-fudou-detail__ttl{
  font-size: 36px;
  font-weight: bold;
  width : -webkit-calc(100% - 100px) ;
  width : calc(100% - 100px) ;
  @media ( max-width: $screen-xs-max ){
    width : -webkit-calc(100% - 70px) ;
    width : calc(100% - 70px) ;
    margin: 15px 0 10px;
    font-size: 24px;
  }
}

.p-fudou-detail__meta{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  margin:70px 0 10px;
  align-items: center;
  @media ( max-width: $screen-xs-max ){
    margin:35px 0 10px;
  }
}

.p-fudou-detail__cate{
  min-width: 130px;
  padding: 5px 20px;
  border-radius: 5px;
  background: #cccccc;
  font-size: 14px;
  text-align: center;
  display: inline-block;
}

.p-fudou-detail__area{
  margin:40px 0 0;
}

.p-fudou-detail__area--copy{
  width: 100%;
  // max-width: 1200px;
  margin: 0 auto 40px;
  line-height: 2;
  @media ( max-width: $screen-xs-max ){
    margin: 0 auto 20px;
  }
}


.p-fudou-detail__point{
  margin:40px 0 0;
  padding:20px;
  width: 100%;
  min-height: 250px;
  background: #f3ecd4 url(#{$imagepath}img/fudo/point.png) no-repeat bottom right 20px;
  @media ( max-width: $screen-xs-max ){
    padding:20px 20px 170px;
    background: #f3ecd4 url(#{$imagepath}img/fudo/point.png) no-repeat bottom center;
  }
}

.p-fudou-detail__point--ttl{
  padding: 5px 20px;
  background: #eb613b;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
}

.p-fudou-detail__point--txt{
  margin:20px 0 0;
  width: 75%;
  line-height:2;
  @media ( max-width: $screen-xs-max ){
    width: 100%;
  }
}

.p-fudou-detail__subttl{
  padding-top: 70px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2em;
  @media ( max-width: $screen-xs-max ){
    padding-top: 35px;
    font-size: 24px;
    font-weight:500;
  }
}

.p-fudou-detail__table {
  margin: 0;
}

/*// -----------------------------------------------------------------
// 物件詳細 お問い合わせ
// -----------------------------------------------------------------*/
.p-fudou-detail__form{
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 60px 60px;
  border: 1px solid #d1d1d1;
  line-height: 20px;
  @media ( max-width: $screen-xs-max ){
    padding: 0 15px 20px;
  }
}
.p-fudou-detail__form--head{
  width: 100%;
  min-height: 250px;
  background: url(#{$imagepath}img/fudo/point.png) no-repeat bottom right 20px;
  @media ( max-width: $screen-xs-max ){
    min-height: 350px;
  }
}
.p-fudou-detail__form--copy{
  max-width: 540px;
  width: 100%;
  margin: 0 auto;
}
.p-fudou-detail__form--txt{
  line-height: 2;
  br{
    @media ( max-width: $screen-xs-max ){
      display: none;
    }
  }
}

.p-fudou-detail__form--line{

}

.p-fudou-detail__subttl{

}

.p-fudou-detail__form--line__dl{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  max-width: 540px;
  width: 100%;
  margin: 40px auto 0;
  @media ( max-width: $screen-xs-max ){
    margin: 20px auto 0;
  }
}

.p-fudou-detail__form--line__dt{
  width: 70%;
  font-weight:normal;
  @media ( max-width: $screen-xs-max ){
    width: 100%;
    text-align: left;
    margin-top:10px;
  }
}

.p-fudou-detail__form--line__dd{
  width: 30%;
  margin:0;
  padding:0;
  @media ( max-width: $screen-xs-max ){
    width: 100%;
    text-align: left;
    margin-top:10px;
  }
}

.p-fudou-detail__form--policy a {
  text-decoration: underline;
  &:hover{
    text-decoration: none;
  }
}

/*// -----------------------------------------------------------------
// 物件詳細関連物件表示
// -----------------------------------------------------------------*/


.c-fudo__list{

}

.c-fudo__item {
  overflow: hidden;
  display: block;
  float: left;
  text-align: left;
  width: 23%;
  height: auto;
  margin: 0 1% 2% 1%;
  // margin: 20px 15px 17px 15px;
  padding: 0;
  font-size: 14px;
  list-style-type: none;
  position: relative;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
  @media ( max-width: $screen-xs-max ){
    width: 48%;
    margin: 0 1% 2% 1%;
    font-size: 0.85rem;
  }
}

// 新着、更新　アイコン
.c-fudo__new_mark{
  margin: 0;
  position: absolute;
  display: table;
  z-index: 2;
  color: #fff;
  font-size:12px;
  text-align: center;
  background: url(#{$imagepath}img/fudo/status_icon.png) no-repeat top center;
  background-size: cover;
  width: 50px;
  height:50px;
  top:0;
  left:0;
  span{
    display: table-cell;
    vertical-align: middle;
  }
}