.c-header__copy {
  font-size:14px;
  color: #666666;
  line-height: 1.7;
  margin: 15px 0 0 0;
  font-weight: normal;
  display: block;
  width: 100%;
  @media ( max-width: 910px ){
    margin:5px 0 0 0;
    font-size:12px;
    display: none;
  }
}

.c-header__logo{
  img{
    max-width: 260px;
    width: 100%;
    @media ( max-width: 910px ){
    max-width: 180px;
    }
  }
}

.c-tel__sp{
  display: none;
  position: absolute;
  top:0;
  right: 80px;
  @media ( max-width: 910px ){
    display: block;
  }
}
.c-tel__sp--link{
  font-size:55px;
  color: #8fc31f;
}

.c-mainimg{
  img{
    width: 100%;
  }
}

.c-header-sp__meta{
  display: none;
  @media ( max-width: 910px ){
    display: block;
  }
}

.c-header-sp__meta--tel{
  padding:20px 0 0;
  text-align: center;
}

.c-header-sp__meta--hours{
  text-align: center;
  padding: 10px 0 20px;
  margin: 0;
  line-height: 2;
}


.c-header-sp__sns--list{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  align-items: flex-start;
  padding-bottom: 40px;
}
.c-header-sp__sns--item{
  margin: 0 auto;
  border: 0;
  width: 30%;
  a{
    display: block;
    padding: 0 !important;
    text-align: center;
    margin:0;
    &.c-sns__linebtn{
      font-size: 55px;
      line-height: 1;
      &:hover{
        transition: .4s;
        color:#00b900;
        opacity:0.7;
      }
    }
    &.c-sns__instabtn{
      margin-top: 6px;
      &:hover{
        transition: .4s;
        opacity:0.7;
      }
    }
    &.c-sns__fbbtn{
      font-size: 55px;
      line-height: 1;
      &:hover{
        transition: .4s;
        color:#1877f2;
        opacity:0.7;
      }
    }

  }
}