
.c-footer__logo{
  max-width: 260px;
  width: 100%;
}

.c-footer__company{
  margin-left:5em;
  line-height: 1.8em;
  @media ( max-width: 960px ){
    margin-left:2em;
  }
}
.c-footer__company--address,
.c-footer__company--tel,
.c-footer__company--fax{
  padding:0;
  margin:0;
}

.c-footer__company--tel{
  a{
    pointer-events: none;
    @media ( max-width: $screen-xs-max ){
    pointer-events: auto;
    }
  }
}

.c-footer__company--address{
  margin:20px 0 0;
}

.c-footer__inner{
  position: relative;
}

.c-footer__nav{
  position: absolute;
  top:30px;
  right:0;
  // @media ( max-width: $screen-xs-max ){
  @media ( max-width: 960px ){
    display: none;
  }
}
.c-footer__nav--list{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
}
.c-footer__nav--item{
  font-size:16px;
  font-weight: bold;
  margin:0 15px;
  &:last-child{
  margin:0 0 0 15px;
  }
  a{
    color: #000000;
    &:hover{
      color: #eb613b;
    }
  }
}

.c-footer__sns--nav{
  position: absolute;
  top:100px;
  right:0;
  // @media ( max-width: $screen-xs-max ){
  @media ( max-width: 960px ){
    display: none;
  }
}

.c-footer__sns--nav__list{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  align-items: flex-start;
}
.c-footer__sns--nav__item{
  margin:0 5px;
  a{
    display: block;
    &.c-sns__linebtn{
      font-size: 55px;
      line-height: 1;
      &:hover{
        transition: .4s;
        color:#00b900;
        opacity:0.7;
      }
    }
    &.c-sns__instabtn{
      margin-top: 6px;
      &:hover{
        transition: .4s;
        opacity:0.7;
      }
    }
    &.c-sns__fbbtn{
      font-size: 55px;
      line-height: 1;
      &:hover{
        transition: .4s;
        color:#1877f2;
        opacity:0.7;
      }
    }

    // i{
    //   padding: 0;
    // }
  }
}


.c-footer__copyright{
  text-align: center;
  margin-top:30px;
  @media ( max-width: 960px ){
    font-size: 12px;
  }
}