
.c-btn {

}

.c-btn {
  min-width: 290px;
  display: inline-block;
  font-size:25px;
  padding: 0.5em 2.5em;
  text-decoration: none;
  color: #ffffff;
  border: solid 2px #eb613b;
  background: #eb613b;
  border-radius: 5px;
  transition: .4s;
  @media ( max-width: $screen-xs-max ){
    font-size:20px;
  }
  &:hover {
    border: solid 2px #eb613b;
    background: #ffffff;
    color: #eb613b;
  }
}

.c-btn__form{
  min-width: 290px;
  display: inline-block;
  font-size:25px;
  padding: 0.5em 2.5em;
  text-decoration: none;
  color: #ffffff;
  border: solid 2px #eb613b;
  background: #eb613b;
  border-radius: 5px;
  transition: .4s;
  @media ( max-width: $screen-xs-max ){
    font-size:20px;
  }
  &:hover {
    border: solid 2px #eb613b;
    background: #ffffff;
    color: #eb613b;
  }
}

.c-btn__small{
  min-width: 110px;
  display: inline-block;
  font-size:14px;
  padding: 0.5em 2.5em;
  text-decoration: none;
  color: #ffffff;
  border: solid 2px #eb613b;
  background: #eb613b;
  border-radius: 5px;
  transition: .4s;
  &:hover {
    border: solid 2px #eb613b;
    background: #ffffff;
    color: #eb613b;
  }
}


.c-sns__btn--insta{
  min-width: 290px;
  display: inline-block;
  font-size:20px;
  padding: 0.5em 2.5em;
  text-decoration: none;
  color: #9711cc;
  border: solid 2px #9711cc;
  background: #fff;
  border-radius: 5px;
  transition: .4s;
  &:hover {
    border: solid 2px #9711cc;
    background: #9711cc;
    color: #fff;
  }
}

.c-sns__btn--fb{
  min-width: 290px;
  display: inline-block;
  font-size:20px;
  padding: 0.5em 2em;
  text-decoration: none;
  color: #0d47a0;
  border: solid 2px #0d47a0;
  background: #fff;
  border-radius: 5px;
  transition: .4s;
  &:hover {
    border: solid 2px #0d47a0;
    background: #0d47a0;
    color: #fff;
  }
}



.c-sns__linebtn{
  color:#00b900;
}

.c-sns__fbbtn{
  color:#1877f2;
}

.c-sns__instabtn {
  display: inline-block;
  text-align: center;/*中央揃え*/
  color: #2e6ca5;/*文字色*/
  font-size: 20px;/*文字サイズ*/
  text-decoration: none;/*下線消す*/
}

.c-sns__instabtn:hover {/*ホバー時*/
  color:#668ad8;/*文字色*/
  transition: .5s;/*ゆっくり変化*/
}

.c-sns__instabtn .__insta{/*アイコンの背景*/
  position: relative;/*相対配置*/
  display: inline-block;
  width: 50px;/*幅*/
  height: 50px;/*高さ*/
  background: -webkit-linear-gradient(135deg, #427eff 0%, #f13f79 70%) no-repeat;
  background: linear-gradient(135deg, #427eff 0%, #f13f79 70%) no-repeat;/*グラデーション①*/
  overflow: hidden;/*はみ出た部分を隠す*/
  border-radius: 7px;/*角丸に*/

}

.c-sns__instabtn .__insta:before{/*グラデーションを重ねるため*/
  content: '';
  position: absolute;/*絶対配置*/
  top: 23px;/*ずらす*/
  left: -18px;/*ずらす*/
  width: 60px;/*グラデーションカバーの幅*/
  height: 60px;/*グラデーションカバーの高さ*/
  background: -webkit-radial-gradient(#ffdb2c 10%, rgba(255, 105, 34, 0.65) 55%, rgba(255, 88, 96, 0) 70%);
  background: radial-gradient(#ffdb2c 10%, rgba(255, 105, 34, 0.65) 55%, rgba(255, 88, 96, 0) 70%);/*グラデーション②*/
}

.c-sns__instabtn .fa-instagram {/*アイコン*/
  color: #FFF;/*白に*/
  position: relative;/*z-indexを使うため*/
  z-index: 2;/*グラデーションより前に*/
  font-size: 35px;/*アイコンサイズ*/
  line-height: 50px;/*高さと合わせる*/
}