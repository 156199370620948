@charset "UTF-8";
/*// -----------------------------------------------------------------
// style.css
// -----------------------------------------------------------------*/
html {
  font-family: YakuHanJP, "Noto Sans JP", sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

a {
  background-color: transparent;
}

a:focus {
  outline: none;
}

a:hover, a:active {
  outline: 0;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  transition: .3s ease-in-out;
}

a:hover img {
  opacity: .7;
  filter: alpha(opacity=70);
}

.wp-block-button .wp-block-button__link:hover {
  opacity: 0.7 !important;
}

img.box1image:hover, img.box2image:hover {
  opacity: 1;
}

.wp-block-image, .wp-block-image img {
  margin-left: 0;
  margin-right: 0;
}

.wp-block-image.alignwide, .wp-block-cover.aligncenter {
  max-width: 960px !important;
  margin: 0 auto 1.75em auto;
  width: auto;
}

b, strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background-color: #d1d1d1;
  border: 0;
  height: 1px;
  margin: 0 0 1.75em;
  margin-right: 23.3335%;
  margin-left: 23.3335%;
}

code, kbd, pre, samp {
  font-size: 1em;
}

ul, ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
  margin-left: 1.5em;
}

li {
  list-style: none;
}

.entry-content .form_jsearch li {
  margin-left: 0.6em;
}

.entry-content li {
  list-style: square;
  margin-left: 2.6em;
}

li > ul, li > ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

del {
  opacity: 0.8;
}

fieldset {
  border: 1px solid #d1d1d1;
  margin: 0 0 1.75em;
  min-width: inherit;
  padding: 0.875em;
}

fieldset > :last-child {
  margin-bottom: 0;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

optgroup {
  font-weight: bold;
}

#main #maching_mail a {
  margin: 50px auto !important;
}

#container {
  float: left;
  margin-right: -100%;
  width: 60%;
  margin-left: 10%;
}

#sub {
  float: left;
  margin-left: 75%;
  padding: 0;
  margin-right: 10%;
  margin-top: 10px;
  width: 15%;
}

#charge {
  padding: 0;
  width: 90%;
  height: auto;
  min-height: 152px;
  line-height: 1.5;
  margin: 10px auto;
}

.role_kannri_tanto_name {
  padding: 1% 0 1% 0;
  margin: 0;
  font-weight: bold;
  display: block;
}

.role_kannri_tanto_comment {
  padding: 1%;
  margin: 0;
  display: block;
}

img.kannri_tanto_pic {
  width: 200px;
  float: left;
  margin-right: 10px;
}

h1, h2, h3, h4, h5, h6 {
  clear: both;
  font-weight: 700;
  margin: 0;
  text-rendering: optimizeLegibility;
}

address {
  font-style: italic;
  margin: 0 0 1.75em;
}

big {
  font-size: 125%;
}

.site-main > article {
  margin-bottom: 5.25em;
}

body:not(.search-results) .entry-summary li > ul, body:not(.search-results) .entry-summary blockquote > ul {
  margin-left: 1.157894737em;
}

body:not(.search-results) .entry-summary li > ol, body:not(.search-results) .entry-summary blockquote > ol {
  margin-left: 1.473684211em;
}

.swiper-container, .post-thumbnail, img.box4image, .h-top.header-image {
  -webkit-animation: fadeIn 3s ease 0s 1 normal;
  animation: fadeIn 3s ease 0s 1 normal;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.archive .page-cont p {
  margin: 0.75em auto 1.75em auto;
}

#page {
  width: 100%;
  margin: 0 auto;
}

.home .site-content {
  padding: 0;
}

.wp-block-cover-image.alignfull {
  min-height: 500px;
}

.map-left .wp-block-cover-image.alignfull {
  margin: 0;
}

#content[tabindex="-1"]:focus {
  outline: 0;
}

.site-main > :last-child {
  margin-bottom: 0;
}

.cl_botton, .cl_botton2 {
  float: none !important;
}

/** fudo */
.list_simple_box .list_detail, .widget_fudo_syousai, .widget_fudou_single_history {
  text-align: left;
  margin: 10px auto;
  max-width: 960px;
  width: 100%;
}

.list_price dt.leftline {
  margin-left: 5px;
  padding-left: 9px;
}

.list_price ul {
  margin: 0 auto;
  border-bottom: 1px solid #eb613b;
  overflow: hidden;
  padding: 5px 0 0 0;
}

.list_detail .list_price li {
  margin: 0;
  font-weight: normal;
  float: left;
  font-size: 1.1rem;
  padding-left: 1%;
  margin-bottom: 15px;
  border-left: 1px solid #eb613b;
}

.list_detail .list_price li.price-box {
  border-left: none;
}

.list_simple_box .list_price span {
  margin: 0 15px;
  font-size: 1.3rem;
}

.list_simple_box .entry-content .list_price .dpoint4 {
  font-size: 18px;
}

.list_price {
  line-height: 1;
  margin: 1em auto;
  width: 100%;
  padding: 0 5% 10px 5%;
  float: left;
  position: relative;
  font-size: 0.875rem;
}

.list_picsam_img {
  position: relative;
  overflow: hidden;
}

img.box4image {
  object-fit: cover;
  font-family: 'object-fit: cover;';
  height: 350px;
  width: 100%;
}

.list_detail .list_price {
  margin: 0.2em auto 0 auto;
  line-height: 1.5;
  padding: 0;
}

.list_details_button {
  font-weight: bold;
  z-index: 10;
  margin-top: 160px;
  margin-left: 341px;
  font-family: 'Helvetica', 'sans-serif';
  position: absolute;
  font-size: 24px;
  width: 300px;
  text-transform: uppercase;
  padding: 20px 30px;
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.list_picsam .list_address, .list_picsam .list_address_koutu {
  float: left;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
}

#list_add_table dt {
  margin: 0 1% 0 0;
  font-weight: normal;
  padding: 5px;
  width: 8%;
  float: left;
  text-align: center;
  border-radius: 5px;
}

#list_add_table dd {
  font-weight: normal;
  font-size: 18px;
  font-size: 1.1rem;
  line-height: 1.5;
  float: left;
  text-align: left;
  width: 40%;
  margin: 0 1% 0 0;
}

#list_add_table dl {
  line-height: 1;
  margin: 3em auto 0 auto;
  float: left;
  width: 100%;
  padding-bottom: 20px;
}

#list_simplepage {
  float: left;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

#list_simplepage .hentry {
  margin: 20px 1% 1% 1%;
  padding-bottom: 10px;
  float: left;
  width: 48%;
  text-align: center;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
}

#list_simplepage .hentry .list_picsam {
  width: 100%;
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
  float: left;
  text-align: center;
  padding: 0;
}

#list_simplepage .hentry .list_picsam .list_price li {
  margin: 0;
  font-weight: normal;
  float: left;
  padding-left: 1%;
  margin-bottom: 15px;
  border-left: 1px solid rgba(65, 65, 65, 0.25);
}

#list_simplepage .hentry .list_picsam .list_price li.price-box {
  border-left: none;
}

#list_simplepage .hentry .list_picsam .list_price span.dpoint4 {
  font-weight: bold;
}

#list_simplepage .hentry .list_picsam .list_price span {
  margin: 0 10px;
  font-weight: normal;
  font-size: 16px;
}

#toukou_top ul {
  list-style: none;
  margin: 10px auto 0 auto;
  padding-left: 0;
}

#toukou_top ul.toukou_top_post_excerpt {
  display: block;
  float: left;
  width: 100%;
  height: auto;
}

#toukou_top ul.toukou_top_post_excerpt li {
  display: block;
  float: left;
  width: 98%;
  height: auto;
}

#toukou_top li {
  list-style: none;
  width: 46%;
  float: left;
  margin: 10px 2% 10px 2%;
}

#toukou_top img {
  height: auto;
  max-width: 30%;
  margin-right: 2%;
  float: left;
}

ul#toukou_top {
  margin: 0 auto 0 auto;
  width: 100%;
  float: left;
}

span.toukou_top_post_title {
  list-style: none;
  font-size: 16px;
  padding-left: 15px;
  font-weight: 700;
}

.home .grid-content li span.top_kotsu, #syousai_box li span.top_kotsu, .grid-content.cookie_fudou_single li span.top_kotsu {
  display: block;
  padding: 5px 10px 10px 10px;
}

.home .grid-content br, #syousai_box br, .grid-content.cookie_fudou_single br {
  display: none;
}

.home .grid-content li .top_title, #syousai_box li .top_title, .grid-content.cookie_fudou_single li .top_title {
  font-weight: normal;
  display: block;
  padding: 10px 10px 5px 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.box1low a {
  font-size: 0;
  display: block;
  margin: 0 auto;
  padding: 5px;
  width: 100px;
  border-radius: 3px;
  transition: .3s ease-in-out;
}

span.box1low {
  float: none !important;
  margin: 2px 0 20px 0;
  display: block;
  width: 100%;
  text-align: center;
}

.box1low a:before {
  font-size: 14px;
  content: "詳細を見る";
  color: #fff;
}

.box1low a:hover:before {
  color: #eb613b;
}

.home .hentry ul, #syousai_box ul, .grid-content, cookie_fudou_single ul {
  margin: 0 auto;
}

.widget.widget_fudo_toukou_top {
  margin: 0 auto;
  width: 98%;
  float: left;
}

.archive-fudo .top_title {
  font-size: 18px;
  padding: 20px 20px 10px 20px;
  display: block;
}

span.kakakuzei {
  font-size: 14px !important;
}

.site-main > article {
  margin-bottom: 3.5em;
  position: relative;
}

.post-thumbnail {
  display: block;
  margin: 0;
}

.post-thumbnail img {
  display: block;
  opacity: 0.9;
  height: 600px;
  object-fit: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  font-family: 'object-fit: cover;';
}

.no-sidebar .post-thumbnail img {
  margin: 0 auto;
}

a.post-thumbnail:hover, a.post-thumbnail:focus {
  opacity: 0.85;
}

.entry-summary > :last-child, body:not(.search-results) .entry-summary > :last-child, .page-content > :last-child, .comment-content > :last-child, .textwidget > :last-child {
  margin-bottom: 0;
}

.more-link {
  white-space: nowrap;
}

.custom-logo {
  max-width: 200px;
}

.image-navigation {
  margin-bottom: 3.230769231em;
}

.post-navigation .post-title {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.25;
}

.post-navigation {
  margin-bottom: 7.0em;
}

.site-main > article {
  margin-bottom: 7.0em;
}

.page-cont header.entry-header h1.entry-title, h1.page-title {
  margin: 40px auto 20px auto;
  text-align: center;
  padding-bottom: 10px;
  width: 100%;
}

.format-aside .entry-title, .format-image .entry-title, .format-video .entry-title, .format-quote .entry-title, .format-gallery .entry-title, .format-status .entry-title, .format-link .entry-title, .format-audio .entry-title, .format-chat .entry-title {
  font-size: 23px;
  font-size: 1.4375em;
  line-height: 1.304347826;
  margin-bottom: 1.826086957em;
}

.single-fudo .post-thumbnail {
  margin-bottom: 2.625em;
  float: left;
}

.entry-content h1, .entry-summary h1, .comment-content h1 {
  font-size: 33px;
  font-size: 2.0625rem;
  line-height: 1.2727272727;
  margin-top: 1.696969697em;
  margin-bottom: 0.8484848485em;
}

.site-content {
  padding: 0;
  margin: 0 auto;
  position: relative;
  width: 100%;
  word-wrap: break-word;
}

.site-inner {
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.home .hentry {
  margin: 0 auto;
  padding: 50px 0 70px 0;
  width: 1200px;
  float: left;
}

.archive-fudo .content-area {
  width: 100%;
  margin: 0 auto;
}

.archive header.entry-header {
  margin-bottom: 20px;
  margin-top: 20px;
}

.entry-header, .post-thumbnail, .entry-content, .entry-summary, .image-navigation, .post-navigation, .page-header, .page-content {
  margin-right: 0;
  margin-left: 0;
}

header.entry-header span.bu-cat {
  margin: 0 auto;
  top: 5px;
  position: relative;
  margin-right: 50px;
  height: 40px;
  display: block;
  float: left;
  width: auto;
  padding: 9px 2% 0 2%;
  font-size: 16px;
  text-align: center;
}

.single-post header.entry-header span {
  padding: 0;
  margin: 0 auto;
  top: 30%;
  left: 0;
  position: absolute;
  width: 100%;
  text-align: center;
  font-weight: 700;
}

.single-fudo .content-area {
  width: 100%;
}

.single-post header.entry-header {
  text-align: center;
  position: relative;
  margin-bottom: 0;
  height: auto;
  min-height: 200px;
}

.page .post-thumbnail {
  margin-bottom: 1.75em;
}

/** top page css */
#ponopono, #top-map, #top-eye {
  float: left;
  width: 100%;
}

.cont-map {
  float: left;
  width: 100%;
  max-height: 500px;
}

.map-left {
  width: 40%;
  float: left;
}

.map-right {
  width: 60%;
  float: left;
}

.map-right iframe {
  height: 500px;
  margin: 0;
}

#pono-in, #tekuteku, .home .content-area {
  width: 100%;
  margin: 0 auto;
}

.cont-in {
  margin: 0 16.7%;
  padding: 50px 0 70px 0;
  height: auto;
  width: 66.667%;
  float: left;
}

.widget p.eye-bun {
  text-align: center;
  margin: 0 0 50px 0;
  line-height: 2;
}

.eye-box {
  width: 47%;
  margin: 0 1.5%;
  float: left;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
}

.eye-mark {
  padding: 5px 2%;
  margin: 0 3%;
  font-size: 14px;
}

.eye-bottom {
  margin: 0 auto;
  padding: 20px 0;
}

/** top banner */
#top-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-weight: 500;
  float: left;
  line-height: 100px;
  font-size: 1.2rem;
}

.top-btn-title:after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  border-style: solid;
  border-width: 50px 30px;
  width: 0;
  height: 0;
  z-index: 1;
}

.top-btn-left, .top-btn-right {
  position: relative;
  width: 35%;
  float: left;
  line-height: 98px;
}

.top-btn-title {
  width: 30%;
  position: relative;
  float: left;
  font-weight: normal;
  letter-spacing: 14px;
  font-family: arial;
  z-index: 1;
}

.top-btn-left a, .top-btn-right a {
  float: left;
  width: 100%;
  transition: .5s ease-in-out;
  letter-spacing: 3px;
}

.top-btn-left a:before, .top-btn-right a:before {
  content: "\f501";
  font-size: 16px;
  right: 10px;
  top: 1px;
  position: relative;
  width: 35px;
  font-family: "Genericons";
}

/** contact */
.contact-form {
  width: 80%;
}

#toiawasesaki {
  width: 100%;
  display: -webkit-box;
  margin-bottom: 20px;
  float: left;
}

#contact_form {
  width: 100%;
  float: left;
}

.contact-box {
  width: 600px;
  margin: 0 auto 20px auto;
  text-align: center;
}

.contact-chui {
  padding: 0 2%;
  font-size: 12px;
  margin: 0 1%;
}

.contact-box-title {
  font-size: 2rem;
  text-align: center;
  margin-top: 40px;
  padding-bottom: 10px;
  font-weight: 500;
}

.contact-box-left {
  float: left;
  border-right: 1px solid #d1d1d1;
  padding-right: 7%;
}

.contact-box-tel {
  font-size: 2rem;
  text-align: center;
  margin-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
}

.contact-box-right {
  float: right;
  padding-left: 5%;
}

.contact-box-form {
  border: 0;
  margin-top: 10px;
  border-radius: 2px;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-weight: 700;
  letter-spacing: 0.046875em;
  line-height: 1;
  padding: 0.84375em 0.875em 0.78125em;
  text-transform: uppercase;
}

.contact-box-form a {
  padding: 10px 20px;
}

.contact-form2 {
  padding: 10%;
  width: 100%;
  margin: 0 auto;
}

#loginform input[type="submit"] {
  padding: 15px 5px;
  width: 100%;
  margin: 30px auto 5px auto;
  text-align: center;
  transition: .3s ease-in-out;
}

.kaiin_register {
  margin: 30px auto 5px auto;
  padding-bottom: 5px;
  width: 100%;
  text-align: center;
}

.kaiin_register a.thickbox {
  text-align: center;
  margin: 5px 1% 10px 1%;
  padding: 10px 11.3%;
}

.widget.widget_fudo_kaiin {
  margin-bottom: 0;
  padding-top: 0;
  line-height: 1;
  float: none;
}

.kaiin_login {
  line-height: 40px;
}

.kaiin_login p {
  margin-bottom: 0;
  padding: 40px 0;
  text-align: center;
  line-height: 40px;
}

.kaiin-headtxt {
  margin: 0 auto 20px auto;
  text-align: center;
}

kaiin-readtxt {
  padding: 1.5em 5% 0em 5%;
  line-height: 1.5;
  margin-bottom: 20px;
}

.login_success .logout_title a, .login_success .repass_title a {
  text-align: center;
  　margin: 5px 0 10px 0;
  padding: 10px 5%;
}

.post-password-form label {
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  letter-spacing: 0.076923077em;
  line-height: 1.6153846154;
  margin-bottom: 1.75em;
  text-transform: uppercase;
}

#user_login.input, #password.input {
  margin-bottom: 30px;
}

.widget .widget-title:empty {
  margin-bottom: 0;
}

/** Forms */
input {
  line-height: normal;
}

.submit {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

input[type="submit"] {
  width: 80%;
  margin: 0 auto;
}

.submit input[type="submit"]:hover {
  border: solid 2px #eb613b;
  background: #ffffff;
  color: #eb613b;
}

.post-password-form {
  margin-bottom: 1.75em;
}

.post-password-form input[type="password"] {
  margin-top: 0.4375em;
}

.post-password-form > :last-child {
  margin-bottom: 0;
}

.search-form {
  position: relative;
}

input[type="search"].search-field {
  border-radius: 2px 0 0 2px;
  width: -webkit-calc(100% - 35px);
  width: calc(100% - 35px);
}

.search-submit:before {
  content: "\f400";
  font-size: 24px;
  left: 2px;
  line-height: 35px;
  position: relative;
  width: 35px;
}

.search-submit {
  border-radius: 0 2px 2px 0;
  bottom: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
}

.form_jsearch h5 {
  font-size: 16px;
  padding-top: 10px;
}

.eki li, .shiku li, .hof li, .madori li, .setsubi li {
  float: left;
  margin-right: 3%;
}

.wp-block-button, .wp-block-button.alignleft, .wp-block-button.alignright {
  max-width: 960px;
  width: 100%;
  margin: 0 auto 1.75em auto;
  float: unset;
}

/** - Header */
.site-header {
  margin: 0 auto;
  position: relative;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09);
}

.site-header-main-in {
  padding: 0;
  margin: 0 auto;
  width: 1200px;
  position: relative;
}

.wp-custom-logo .site-title {
  margin-top: 0.5em;
}

.site-description {
  display: block;
}

.wp-custom-logo {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.site-header-out {
  margin: 0 auto;
  display: block;
  height: 120px;
}

@media (max-width: 910px) {
  .site-header-out {
    height: 70px;
  }
}

.site-header-main {
  margin: 0 auto;
  line-height: 70px;
  width: 100%;
  z-index: 10;
  position: fixed;
  transition: all .5s ease-out;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  height: 120px;
}

@media (max-width: 910px) {
  .site-header-main {
    height: 70px;
  }
}

.site-header-main-in .info {
  position: absolute;
  right: 15px;
  font-size: 11px;
  line-height: 1;
  text-align: right;
  top: 7px;
}

@media (max-width: 910px) {
  .site-header-main-in .info {
    display: none;
  }
}

.site-header-main-in .info span {
  font-size: 18px;
  border-radius: 4px;
  padding: 3px;
  margin-right: 5px;
}

.c-tel {
  display: table;
}

.c-tel:before {
  content: "TEL";
  font-size: 19px;
  font-weight: bold;
  color: #ffffff;
  background: #8fc31f;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
  display: table-cell;
  vertical-align: middle;
}

.c-tel__hours {
  color: #666;
  font-size: 14px;
  line-height: 2em;
}

.site-header-main-in .info strong {
  font-size: 34px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
}

.site-branding {
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  float: left;
}

.site-title {
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 10px 0 0 0;
}

.header-image span {
  position: absolute;
  left: 0;
  top: 30%;
  padding: 0;
  width: 100%;
  font-size: 56px;
  font-weight: 500;
}

.header-image span br {
  display: none;
}

.header-image span.slide-lead {
  top: 44%;
  width: 100%;
  font-size: 30px;
  line-height: 2;
  font-family: Meiryo;
}

.header-image {
  position: relative;
  padding: 0px;
  text-align: center;
  border-top: 0 none;
  border-bottom: 0 none;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.header-image img {
  width: 100%;
  height: auto;
  opacity: 0.9;
  height: 700px;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

img.box1image, img.box2image {
  object-fit: cover;
  font-family: 'object-fit: cover;';
  height: 180px;
  width: 100%;
}

.main_picsam {
  margin: 1em auto;
  max-width: 1200px;
  padding: 0;
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main_picsam__item {
  width: 49%;
}

.main_picsam img.box3image {
  font-family: 'object-fit: cover;';
  object-fit: cover;
  padding: 0;
  width: 100%;
}

.list_picsam img.box3image {
  font-family: 'object-fit: cover;';
  margin-right: 1%;
  height: 300px;
  object-fit: cover;
  transform: scale(1);
  transition: all 500ms ease-out;
  width: 99%;
}

/** widget */
.widget p, .widget address, .widget hr, .widget ul, .widget ol, .widget dl, .widget dd, .widget table {
  margin-bottom: 1.6153846154em;
}

.widget li > ul, .widget li > ol {
  margin-bottom: 0;
}

.widget blockquote {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3125;
  margin-bottom: 1.3125em;
  padding-left: 1.0625em;
}

.widget blockquote cite, .widget blockquote small {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
}

.widget th, .widget td {
  padding: 0.5384615385em;
}

.widget pre {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin-bottom: 1.6153846154em;
  padding: 0.5384615385em;
}

.widget fieldset {
  margin-bottom: 1.6153846154em;
  padding: 0.5384615385em;
}

.widget_text.widget.widget_custom_html {
  width: 100%;
}

.widget button, .widget input[type="button"], .widget input[type="reset"], .widget input[type="submit"] {
  line-height: 1;
  padding: 0.846153846em;
}

.widget h1 {
  font-size: 23px;
  font-size: 1.4375rem;
  line-height: 1.2173913043;
  margin-bottom: 0.9130434783em;
}

.widget h3 {
  font-size: 2rem;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: normal;
}

.widget.widget_fudo_top h3 {
  font-size: 2rem;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 20px;
  font-weight: 500;
}

.widget h4, .widget h5, .widget h6 {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin-bottom: 0.9130434783em;
}

.widget .alignleft {
  margin: 0.2307692308em 1.6153846154em 1.6153846154em 0;
}

.widget .alignright {
  margin: 0.2307692308em 0 1.6153846154em 1.6153846154em;
}

.widget .aligncenter {
  margin-bottom: 1.6153846154em;
}

.widget_calendar td, .widget_calendar th {
  line-height: 2.6923076923;
  padding: 0;
}

.widget_rss .rssSummary:last-child {
  margin-bottom: 1.615384615em;
}

.widget input[type="search"].search-field {
  width: -webkit-calc(100% - 35px);
  width: calc(100% - 35px);
  height: 45px;
}

.widget .search-submit:before {
  content: "\f400";
  font-size: 24px;
  left: 2px;
  line-height: 35px;
  position: relative;
  width: 35px;
}

.widget button.search-submit {
  padding: 0;
  width: 35px;
}

.sidebar .widget_fudou_jsearch_history li {
  margin-bottom: 10px;
  list-style: square;
  line-height: 1.2;
}

.content-bottom-widgets .widget-area .widget select {
  width: 100%;
  margin: 5px 0;
}

.content-bottom-widgets .widget-area .widget select#kalc, .content-bottom-widgets .widget-area .widget select#kalb, .content-bottom-widgets .widget-area .widget select#mel {
  width: 45%;
  margin: 5px 2% 5px 0;
  float: left;
}

.content-bottom-widgets .widget-area .widget select#kahc, .content-bottom-widgets .widget-area .widget select#meh, .content-bottom-widgets .widget-area .widget select#kahb {
  width: 45%;
  margin: 5px 0 5px 2%;
}

.content-bottom-widgets .widget-area #searchitem input[type="checkbox"], .content-bottom-widgets .widget-area #searchitem input[type="radio"] {
  margin-right: 0.2375em;
  margin-left: 0.4375em;
}

.content-bottom-widgets .widget-area #searchitem {
  width: 80%;
  margin: 0 auto;
}

.content-bottom-widgets .widget-area #searchitem input[type="submit"] {
  width: 100%;
  margin: 10px 0;
  text-align: center;
}

.content-bottom-widgets .widget-area label {
  padding: 0 2px 0 0;
}

.content-bottom-widgets .widget-area #searchitem span {
  display: block;
  margin-top: 10px;
}

.content-bottom-widgets .widget-area #searchitem br {
  display: none;
}

.content-bottom-widgets .widget-area:nth-child(1):nth-last-child(2), .content-bottom-widgets .widget-area:nth-child(2):nth-last-child(1) {
  float: left;
  margin-right: 7.1428571%;
  width: 30%;
}

.content-bottom-widgets .widget-area:nth-child(2):nth-last-child(1):last-of-type {
  margin-right: 0;
}

.content-bottom-widgets .widget-area {
  margin: 20px 1.5%;
  width: 30.3%;
  float: left;
  font-size: 0.8rem;
}

.content-bottom-widgets {
  padding: 0;
  margin: 0 auto;
  width: 1200px;
  position: relative;
}

.tagcloud a {
  margin: 0 0.2307692308em 0.5384615385em 0;
  padding: 0.5384615385em 0.4615384615em 0.4615384615em;
}

.textwidget h1 {
  margin-top: 1.8260869565em;
}

.textwidget h2 {
  margin-top: 2.2105263158em;
}

.textwidget h3 {
  margin-top: 2.625em;
}

.textwidget h4 {
  letter-spacing: 0.153846154em;
}

.textwidget h4, .textwidget h5, .textwidget h6 {
  margin-top: 3.2307692308em;
}

.widget-area > :last-child, .widget > :last-child {
  margin-bottom: 0;
}

.content-bottom-widgets .widget-area > :last-child, .widget > :last-child {
  width: 100%;
}

.widget select {
  width: 100%;
  margin: 5px 0 10px 0;
  padding: 5px;
}

.widget.widget_fudo_search .widget-title {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.widget.widget_fudo_search.widget input[type="search"].search-field {
  width: 83%;
  margin: 5px 0;
  padding: 0.625em 0.4375em;
}

.widget.widget_fudo_search input[type="submit"] {
  width: 17%;
  margin: 5px 0;
  padding: 4px 2%;
}

.content-bottom-widgets .sub-menu ul li {
  margin: 0;
}

.widget .widget-title {
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 0.046875em;
  line-height: 1.3125;
  text-transform: uppercase;
  line-height: 1;
  padding: 5px 0 5px 10px;
  font-weight: bold;
}

.widget.widget_fudou_single_history h3 img {
  margin: 0 auto;
  padding-bottom: 10px;
}

.login_success {
  font-size: 30px;
  margin: 0 auto;
  line-height: 1.5;
  text-align: center;
}

.login_success .logout_title, .login_success .repass_title {
  font-size: 1rem;
  margin: 0 auto;
  text-align: center;
}

.widget.widget_fudo_kaiin ul {
  margin: 0;
  padding: 0;
}

/** table 基本*/
table {
  border-collapse: collapse;
  margin: 0 0 1.75em;
  table-layout: fixed;
  width: 100%;
}

caption, th, td {
  text-align: left;
}

th, td {
  padding: 1em;
}

td.td1 span {
  padding: 1px 0.5em;
  line-height: 2;
  margin: 1% 0;
  display: inline-block;
  font-size: 14px;
}

.form_jsearch th {
  width: 25%;
}

/** comment */
.comment-author {
  margin-bottom: 0;
}

.comment-author .avatar {
  height: 42px;
  position: relative;
  top: 0.25em;
  width: 42px;
}

.comment-list .children > li {
  padding-left: 1.75em;
}

.comment-navigation {
  margin-right: 0;
  margin-left: 0;
}

.comments-title + .comment-navigation {
  border-bottom: 0;
  margin-bottom: 0;
}

.comment-list + .comment-respond, .comment-navigation + .comment-respond {
  padding-top: 1.75em;
}

.comments-title {
  margin-bottom: 1.217391304em;
}

.comment-list {
  list-style: none;
  margin: 0;
}

.comment-list .children {
  list-style: none;
  margin: 0;
}

.comment-list .children > li {
  padding-left: 0.875em;
}

.comment-author .avatar {
  float: left;
  height: 28px;
  margin-right: 0.875em;
  position: relative;
  width: 28px;
}

.bypostauthor > article .fn:after {
  content: "\f304";
  left: 3px;
  position: relative;
  top: 5px;
}

.comment-metadata .edit-link, .pingback .edit-link {
  display: inline-block;
}

.comment-metadata .edit-link:before, .pingback .edit-link:before {
  content: "\002f";
  display: inline-block;
  opacity: 0.7;
  padding: 0 0.538461538em;
}

.comment-content ul, .comment-content ol {
  margin: 0 0 1.5em 1.25em;
}

.comment-content li > ul, .comment-content li > ol {
  margin-bottom: 0;
}

.comment-navigation + .no-comments {
  border-top: 0;
  padding-top: 0;
}

.format-aside .entry-title, .format-image .entry-title, .format-video .entry-title, .format-quote .entry-title, .format-gallery .entry-title, .format-status .entry-title, .format-link .entry-title, .format-audio .entry-title, .format-chat .entry-title {
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1.473684211;
  margin-bottom: 1.473684211em;
}

.blog .format-status .entry-title, .archive .format-status .entry-title {
  display: none;
}

.form-allowed-tags code {
  font-family: Inconsolata, monospace;
}

.form-submit {
  margin: 0 auto;
  text-align: center;
}

.form-submit input[type="submit"] {
  padding: 20px 0;
}

.comment-reply-title small {
  font-size: 100%;
}

.comment-reply-title small a {
  border: 0;
  float: right;
  height: 32px;
  overflow: hidden;
  width: 26px;
}

.comment-reply-title small a:before {
  content: "\f405";
  font-size: 32px;
  position: relative;
  top: -5px;
}

/** title */
.home h2.section-title {
  font-size: 12px;
  margin-bottom: 0;
  text-align: center;
  padding-top: 30px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
}

.widget h3.section-title, h3.section-title {
  font-size: 2rem;
  text-align: center;
  padding-top: 20px;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
  font-weight: 400;
}

#list_other_table h3, .widget h3 {
  font-size: 30px;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 20px;
  font-weight: bold;
}

.widgetsubtitle {
  display: block;
  font-size: 15px;
  color: #7f7f7f;
  font-family: 'Yantramanav', sans-serif;
}

.single-post h1.entry-title {
  line-height: 1.25;
  margin: 0 5% 1em 5%;
  position: absolute;
  top: 43%;
  width: 90%;
  text-align: center;
}

.archive-fudo h1.entry-title {
  padding: 20px 20px 10px 20px;
  text-align: center;
  margin: 40px auto 20px auto;
}

.single-fudo h1.entry-title {
  padding: 20px 0 10px 0;
  text-align: left;
  margin: 40px auto 20px auto;
  width: 960px;
  position: relative;
}

h1.entry-title {
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 1px;
}

.entry-content h1, .entry-summary h1, .comment-content h1, .textwidget h1 {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.25;
  margin-top: 2em;
  margin-bottom: 1em;
}

.entry-summary h2, .comment-content h2, .textwidget h2 {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.25;
  margin-top: 2em;
  margin-bottom: 1em;
}

.entry-content h2 {
  position: relative;
  line-height: 1.6;
  font-size: 28px;
  font-size: 1.75rem;
  padding: 0.25em;
  top: 0;
  text-align: center;
  margin: 60px auto;
  width: 25%;
}

.entry-content h2:before, .entry-content h2:after {
  position: absolute;
  top: 0;
  content: '';
  width: 8px;
  height: 100%;
  display: inline-block;
}

.entry-content h3, .entry-content h4 {
  text-align: center;
}

.entry-content h3, .entry-summary h3, .comment-content h3, .textwidget h3 {
  font-size: 23px;
  font-size: 1.4375rem;
  line-height: 1.6;
  margin-top: 2.4347826087em;
  margin-bottom: 1.2173913043em;
}

.entry-content h4, .entry-summary h4, .comment-content h4, .textwidget h4 {
  letter-spacing: 0.140625em;
  text-transform: uppercase;
}

.entry-content h4 {
  font-size: 1.25em;
  line-height: 1.5;
  margin: 0 auto 1.75em auto;
}

.entry-content h1, .entry-content h3, .entry-summary h1, .entry-summary h2, .entry-summary h3, .comment-content h1, .comment-content h2, .comment-content h3, .textwidget h1, .textwidget h2, .textwidget h3 {
  font-weight: 900;
}

.entry-content h1:first-child, .entry-content h2:first-child, .entry-content h3:first-child, .entry-summary h1:first-child, .entry-summary h2:first-child, .entry-summary h3:first-child, .comment-content h1:first-child, .comment-content h2:first-child, .comment-content h3:first-child, .textwidget h1:first-child, .textwidget h2:first-child, .textwidget h3:first-child {
  margin-top: 0;
}

.post-navigation .post-title, .entry-title, .comments-title {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

/** mark */
.fudo_kaiin_type_logo {
  z-index: 1;
  top: 10px;
  right: 10px;
  position: absolute;
}

.single-fudo .fudo_kaiin_type_logo {
  position: initial;
}

.new_mark {
  margin: 0;
  position: absolute;
  display: block;
  z-index: 2;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica', 'sans-serif';
}

.home .new_mark, #syousai_box .new_mark, .grid-content.cookie_fudou_single .new_mark {
  height: 30px;
  width: 125px;
  padding: 8px 0 0 0;
  font-size: 14px;
  left: -40px;
  top: 8px;
  transform: rotate(-45deg);
}

header.entry-header .new_mark {
  height: 40px;
  width: 40px;
  padding: 12px 0 0 0;
  font-size: 12px;
  left: -15px;
  top: -10px;
  border-radius: 20px;
  transform: rotate(-15deg);
}

.list_picsam_img .new_mark {
  height: 35px;
  font-size: 12px;
  width: 125px;
  padding: 5px 0;
  font-size: 16px;
  left: -30px;
  top: 15px;
  transform: rotate(-45deg);
}

/** slides */
.swiper-container {
  width: 100%;
  line-height: 0;
}

.swiper-slide span {
  position: absolute;
  left: 5%;
  text-shadow: 0 0 10px #000, 0 0 15px #000;
  top: 40%;
  color: #fff;
  padding: 5px 0;
  width: 95%;
  font-size: 48px;
  font-weight: 500;
}

span.slide-lead {
  top: 45%;
  padding: 10px 0 5px 0;
  width: 90%;
  font-size: 24px;
  line-height: 2;
  font-family: Meiryo;
}

.swiper-button-next {
  background-image: url(../../images/next.svg);
  background-size: 100% 80%;
}

.swiper-button-prev {
  background-image: url(../../images/prev.svg);
  background-size: 100% 80%;
}

.swiper-button-next, .swiper-button-prev {
  top: 49% !important;
}

/*drawer_menu*/
.sidebar {
  margin-bottom: 5.25em;
  float: left;
  margin-left: 5%;
  padding: 75px 25% 50px 3%;
  width: 94%;
  z-index: 10000;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.sidebar .grid-content.cookie_fudou_single li {
  width: 100%;
}

.sidebar #setsubi_cb, .sidebar #memseki, .sidebar #chikunen, .sidebar #hofun, .sidebar #kakaku_c, .sidebar #kakaku_b, .sidebar #roseneki, .sidebar #chiiki, .sidebar #madori_cb {
  margin: 15px 0 0 0;
}

.sidebar label {
  margin: 0 20px 0 1px;
  font-size: 12px;
}

.sidebar select#mel, .sidebar select#meh, .sidebar select#kalc, .sidebar select#kahc, .sidebar select#kalb, .sidebar select#kahb {
  width: 46.8%;
}

.sidebar .widget h2.widget-title {
  margin: 0;
  padding: 20px 0;
}

.kogma_drawer_menu * {
  -webkit-appearance: normal;
  -moz-appearance: normal;
  appearance: normal;
}

.kogma_drawer_menu ul li ul {
  margin: 0;
  padding: 5px 0 5px 10px;
}

.sidebar .widget {
  width: 100%;
}

.kogma_drawer_menu input[type="submit"] {
  width: 100%;
  margin: 20px auto;
}

.kogma_drawer_menu a {
  color: inherit;
  text-decoration: none;
}

.kogma_drawer_menu a:visited {
  color: inherit;
}

.kogma_drawer_menu .kogma_drawer_bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background-color: rgba(51, 51, 51, 0.5);
  display: none;
  top: 0;
  left: 0;
}

.kogma_drawer_menu .kogma_drawer_button.active .kogma_drawer_bar {
  width: 49px;
}

.kogma_drawer_menu .kogma_drawer_button.active .kogma_drawer_menu_text {
  display: none;
}

.kogma_drawer_menu .kogma_drawer_button.active .kogma_drawer_close {
  display: block;
}

.kogma_drawer_menu .kogma_drawer_text {
  text-align: center;
  font-size: 12px;
}

.kogma_drawer_menu .kogma_drawer_close {
  letter-spacing: 0.08em;
  display: none;
}

.kogma_drawer_menu .kogma_drawer_menu_text {
  display: block;
}

.kogma_drawer_menu .kogma_drawer_nav_wrapper {
  width: 450px;
  height: 100%;
  transition: all 0.2s;
  transform: translate(450px);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
}

.kogma_drawer_menu .kogma_drawer_nav_wrapper.open {
  transform: translate(0);
}

.kogma_drawer_menu.left .kogma_drawer_nav_wrapper {
  transform: translate(-450px);
  right: auto;
  left: 0;
}

.kogma_drawer_menu.left .kogma_drawer_nav_wrapper.open {
  transform: translate(0);
}

.kogma_drawer_menu .kogma_drawer_nav {
  padding: 112px 24px;
}

.kogma_drawer_menu .kogma_drawer_nav li {
  font-size: 16px;
  margin-bottom: 15px;
}

.kogma_drawer_menu .kogma_drawer_button {
  width: auto;
  height: 75px;
  padding: 0 1%;
  border-radius: 10px 0px 0px 10px;
  display: block;
  border: none;
  letter-spacing: 0.1em;
  cursor: pointer;
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 10000;
  text-align: center;
  outline: none;
  transition: .3s ease-in-out;
}

.kogma_drawer_menu .kogma_drawer_button:before {
  content: "\f400";
  font-size: 20px;
  left: 2px;
  line-height: 24px;
  position: relative;
  width: 35px;
  font-family: "Genericons";
}

.kogma_drawer_menu_text kogma_drawer_text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

/**  Accessibility */
.says, .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.site .skip-link:focus {
  clip: auto;
  height: auto;
  left: 6px;
  top: 7px;
  width: auto;
  z-index: 100000;
}

/** Alignments */
.alignleft {
  float: left;
  margin: 0.375em 1.75em 1.75em 0;
}

.wp-block-image .alignleft {
  margin-left: 420px !important;
}

.wp-block-image .alignright {
  margin-right: 420px !important;
}

.alignright {
  float: right;
  margin: 0.375em 0 1.75em 1.75em;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

blockquote.alignleft {
  margin: 0.3157894737em 1.4736842105em 1.473684211em 0;
}

blockquote.alignright {
  margin: 0.3157894737em 0 1.473684211em 1.4736842105em;
}

blockquote.aligncenter {
  margin-bottom: 1.473684211em;
}

/** Clearings */
.clear:before, .clear:after, .entry-content:before, .entry-content:after, .entry-summary:before, .entry-summary:after, .comment-content:before, .comment-content:after, .site-content:before, .site-content:after, .site-main > article:before, .site-main > article:after, .primary-menu:before, .primary-menu:after, .textwidget:before, .textwidget:after, .content-bottom-widgets:before, .content-bottom-widgets:after {
  content: "";
  display: table;
}

.clear:after, .entry-content:after, .entry-summary:after, .comment-content:after, .site-content:after, .site-main > article:after, .primary-menu:after, .social-links-menu:after, .textwidget:after, .content-bottom-widgets:after {
  clear: both;
}

/** footer */
.wg-footer {
  padding: 50px 0;
}

.wg-footer .widget li ul {
  margin-top: 19px;
}

.wg-footer .widget li {
  margin-bottom: 19px;
}

.wg-footer #toukou_top li {
  width: 100%;
}

.wg-footer .widget .widget-title {
  font-size: 20px;
  letter-spacing: 0.046875em;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  border-left: 0;
  padding: 5px 0;
  font-weight: normal;
}

.site-footer {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.site-info {
  margin: 2% auto 0 auto;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.site-footer {
  padding: 0 7.6923% 1.75em;
}

.site-footer .site-title {
  font-family: inherit;
  font-size: inherit;
}

.site-footer .site-title:after {
  content: "\002f";
  display: inline-block;
  font-family: Montserrat, sans-serif;
  opacity: 0.7;
  padding: 0 0.307692308em 0 0.538461538em;
}

.site-footer .main-navigation {
  margin-bottom: 1.75em;
}

.site-footer .social-navigation {
  margin: 1.75em 0;
  width: 100%;
}

.site-footer .menu-navis-container {
  position: relative;
  overflow: hidden;
}

.site-footer .social-navigation ul {
  position: relative;
  left: 50%;
  float: left;
}

.site-footer .social-navigation ul li {
  position: relative;
  left: -50%;
  float: left;
}

/** Media */
.site .avatar {
  border-radius: 50%;
}

.author-info {
  border-color: inherit;
  border-style: solid;
  border-width: 1px 0 1px 0;
  clear: both;
  padding-top: 1.75em;
  padding-bottom: 1.75em;
}

.author-info {
  border-bottom-width: 0;
  padding-bottom: 0;
  margin: 0 23.3335% 1.75em 23.3335%;
}

.author-avatar .avatar {
  float: left;
  height: 75px;
  margin: 0 1.2em 1.2em 0;
  width: 75px;
}

.author-description > :last-child {
  margin-bottom: 0;
}

.entry-content .author-title {
  clear: none;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.75;
  margin: 0;
}

.entry-content .wp-smiley, .entry-summary .wp-smiley, .comment-content .wp-smiley, .textwidget .wp-smiley {
  border: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.entry-content a img, .entry-summary a img, .comment-content a img, .textwidget a img {
  display: block;
}

embed, iframe, object, video {
  margin-bottom: 1.75em;
  width: 100%;
  vertical-align: middle;
}

.wp-block-pullquote {
  padding: 2em 0 0 0 !important;
}

p > embed, p > iframe, p > object, p > video {
  margin-bottom: 0;
}

.entry-content .wp-audio-shortcode a, .entry-content .wp-playlist a {
  box-shadow: none;
}

.wp-audio-shortcode, .wp-video, .wp-playlist.wp-audio-playlist {
  margin-top: 0;
  margin-bottom: 1.75em;
}

.wp-playlist.wp-audio-playlist {
  padding-bottom: 0;
}

.wp-playlist .wp-playlist-tracks {
  margin-top: 0;
}

.wp-playlist-item .wp-playlist-caption {
  border-bottom: 0;
  padding: 0.7142857143em 0;
}

.wp-playlist-item .wp-playlist-item-length {
  top: 0.7142857143em;
}

/** Captions */
.wp-caption {
  margin-bottom: 1.75em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0;
}

/** 12.2 - Galleries */
.gallery {
  margin: 0 -1.1666667% 1.75em;
}

.gallery-item {
  display: inline-block;
  max-width: 33.33%;
  padding: 0 1.1400652% 2.2801304%;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-1 .gallery-item {
  max-width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.gallery-caption {
  display: block;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: italic;
  line-height: 1.6153846154;
  padding-top: 0.5384615385em;
}

.gallery-columns-6 .gallery-caption, .gallery-columns-7 .gallery-caption, .gallery-columns-8 .gallery-caption, .gallery-columns-9 .gallery-caption {
  display: none;
}

/**  Menus */
.site-header-menu {
  display: none;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  margin: 0 !important;
}

.site-header-menu.toggled-on, .no-js .site-header-menu {
  display: block;
}

.dropdown-toggle {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #1a1a1a;
  content: "";
  height: 48px;
  padding: 0;
  position: absolute;
  right: 0;
  text-transform: none;
  top: 0;
  width: 48px;
}

.site-header-menu.toggled-on .main-navigation {
  margin-bottom: 1.75em;
  width: 100%;
  background: #fff;
}

.main-navigation ul {
  list-style: none;
  margin: 0;
}

.main-navigation .primary-menu {
  border-bottom: 1px solid #d1d1d1;
}

.main-navigation .current-menu-item > a, .main-navigation .current-menu-ancestor > a {
  color: #eb613b;
}

.main-navigation ul ul {
  display: none;
  margin-left: 0.875em;
}

.no-js .main-navigation ul ul {
  display: block;
}

.main-navigation ul .toggled-on {
  display: block;
}

.main-navigation .menu-item-has-children > a {
  margin-right: 56px;
}

.dropdown-toggle:focus {
  outline: thin dotted;
  outline-offset: -1px;
}

.dropdown-toggle:focus:after {
  border-color: transparent;
}

.dropdown-toggle.toggled-on:after {
  content: "\f432";
}

.main-navigation a {
  display: block;
  font-size: 14PX;
  line-height: 1.3125;
  outline-offset: -1px;
  padding: 0.84375em 0;
}

.site-header-menu.toggled-on .main-navigation li a {
  line-height: 1.3125;
  padding: 0.84375em 0;
  font-size: 16PX;
}

.site-header .main-navigation + .social-navigation {
  float: right;
  margin: 10px 0;
}

.menu-item-has-children a:after, .social-navigation a:before, .dropdown-toggle:after, .bypostauthor > article .fn:after, .comment-reply-title small a:before, .pagination .prev:before, .pagination .next:before, .pagination .nav-links:before, .pagination .nav-links:after, .search-submit:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "Genericons";
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  vertical-align: top;
}

.social-navigation a {
  border: 1px solid #d1d1d1;
  border-radius: 50%;
  color: #1a1a1a;
  display: block;
  height: 35px;
  position: relative;
  width: 35px;
}

.social-navigation ul {
  list-style: none;
  margin: 0 0 -0.4375em;
}

.social-navigation li {
  float: left;
  margin: 0 0.4375em 0.4375em 0;
}

.social-navigation a:before {
  content: "\f415";
  height: 33px;
  line-height: 33px;
  text-align: center;
  width: 33px;
}

.social-navigation a[href*="dropbox.com"]:before {
  content: "\f225";
}

.social-navigation a[href*="facebook.com"]:before {
  content: "\f203";
}

.social-navigation a[href*="plus.google.com"]:before {
  content: "\f206";
}

.social-navigation a[href*="instagram.com"]:before {
  content: "\f215";
}

.social-navigation a[href*="pinterest.jp"]:before {
  content: "\f210";
}

.social-navigation a[href*="skype.com"]:before {
  content: "\f220";
}

.social-navigation a[href*="twitter.com"]:before {
  content: "\f202";
}

.social-navigation a[href*="vimeo.com"]:before {
  content: "\f212";
}

.social-navigation a[href*="wordpress.com"]:before, .social-navigation a[href*="wordpress.org"]:before {
  content: "\f205";
}

.social-navigation a[href*="youtube.com"]:before {
  content: "\f213";
}

.social-navigation a[href^="mailto:"]:before {
  content: "\f410";
}

.social-navigation a[href$="/feed/"]:before {
  content: "\f413";
}

.image-navigation .nav-previous:not(:empty), .image-navigation .nav-next:not(:empty), .comment-navigation .nav-previous:not(:empty), .comment-navigation .nav-next:not(:empty) {
  display: inline-block;
}

.image-navigation .nav-previous:not(:empty) + .nav-next:not(:empty):before, .comment-navigation .nav-previous:not(:empty) + .nav-next:not(:empty):before {
  content: "\002f";
  display: inline-block;
  opacity: 0.7;
  padding: 0 0.538461538em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

select {
  text-transform: none;
}

button {
  overflow: visible;
}

button, input, select, textarea {
  max-width: 100%;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
  opacity: .5;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.wp-block-button .wp-block-button__link {
  border-radius: 4px !important;
}

.wp-block-button.is-style-squared .wp-block-button__link {
  border-radius: 0 !important;
}

input[type="checkbox"], input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  vertical-align: middle;
  margin-right: 2px;
}

input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-outer-spin-button, input[type="time"]::-webkit-inner-spin-button, input[type="time"]::-webkit-outer-spin-button, input[type="datetime-local"]::-webkit-inner-spin-button, input[type="datetime-local"]::-webkit-outer-spin-button, input[type="week"]::-webkit-inner-spin-button, input[type="week"]::-webkit-outer-spin-button, input[type="month"]::-webkit-inner-spin-button, input[type="month"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/** パンくず */
#bread-out {
  margin: 0 auto 0 auto;
  width: 100%;
}

#bread ul {
  list-style-type: none;
  overflow: hidden;
  margin: 0 auto;
}

#bread ul li {
  height: 40px;
  line-height: 40px;
  float: left;
  margin-right: 3px;
  font-size: 0.85rem;
}

#bread ul li:before {
  content: ">";
  margin: 0 5px;
}

#bread ul li:first-child:before {
  content: none;
}

span.crumb-no-link, #nendebcopy a {
  pointer-events: none;
}

span.crumb-no-link a {
  color: #686868;
}

/*page-top*/
#page-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 0;
  z-index: 10000;
}

#page-top a {
  border: 0;
  display: block;
  transition: .4s;
}

#page-top a:hover {
  border: 0;
  opacity: 0.7;
  box-shadow: none !important;
}

@media screen and (max-width: 910px) {
  #page-top a {
    width: 50px;
    padding: 10px 0;
    line-height: 1.4;
  }
}

/** map */
#list_simplepage2 #map_canvas {
  margin-bottom: 3.5em;
  width: 100% !important;
}

#list_simplepage2 #map_canvas .gm-style-iw {
  width: auto !important;
  height: auto !important;
}

#list_simplepage2 #map_canvas table {
  overflow: hidden;
  width: auto !important;
  margin: 8px;
  padding: 0;
  border-style: none;
  border-spacing: 2px;
  border-collapse: separate;
}

#list_simplepage2 #map_canvas td {
  font-size: 10px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  color: #3f3f3f;
  background-color: #fff;
  border-width: 0 !important;
  border-top: medium none !important;
}

#list_simplepage2 #map_canvas td br {
  line-height: 1.2 !important;
}

#list_simplepage2 .gmapballoon img {
  width: 90px;
  height: auto;
  padding: 2px;
  border: 0;
}

#list_simplepage2 .gmaptitle {
  font-size: 12px;
  font-weight: 700;
}

#list_simplepage2 .gmapkakaku {
  font-family: Arial,Helvetica,sans-serif;
  font-size: 20px !important;
  font-weight: 700;
  color: #f30 !important;
}

#list_simplepage2 .gmapmadori {
  font-family: Arial,Helvetica,sans-serif;
  font-size: 20px !important;
  font-weight: 700;
  color: #333 !important;
}

/** 14.3 - >= 910px */
@media screen and (min-width: 56.875em) {
  .site-header-menu {
    display: block;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .main-navigation {
    margin: 30px 0 0 0.8em;
    float: right;
  }
  .main-navigation nav {
    width: 100%;
    color: #FFF;
    background: #333;
    height: 50px;
    position: fixed;
    top: 80px;
  }
  .main-navigation nav {
    width: 100%;
    color: #FFF;
    background: #333;
    height: 50px;
    position: fixed;
    top: 80px;
  }
  .main-navigation .primary-menu, .main-navigation .primary-menu > li {
    border: 0;
  }
  .main-navigation .primary-menu > li {
    float: left;
  }
  .main-navigation a {
    outline-offset: -8px;
    padding: 0 0.5em;
    white-space: nowrap;
    line-height: 40px;
    transition: .3s ease-in-out;
    font-size: 16px;
    font-weight: bold;
  }
  .main-navigation li:last-child a {
    padding: 0 0 0 0.5em;
  }
  .main-navigation ul ul {
    border-bottom: 1px solid #d1d1d1;
    display: block;
    left: -999em;
    margin: 0;
    position: absolute;
    z-index: 99999;
  }
  .main-navigation ul ul ul {
    top: -1px;
  }
  .main-navigation ul ul ul:before, .main-navigation ul ul ul:after {
    border: 0;
  }
  .main-navigation ul ul li {
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-bottom-width: 0;
  }
  .main-navigation ul ul a {
    white-space: normal;
    width: 12.6875em;
  }
  .main-navigation ul ul:before, .main-navigation ul ul:after {
    border-style: solid;
    content: "";
    position: absolute;
  }
  .main-navigation ul ul:before {
    border-color: #d1d1d1 transparent;
    border-width: 0 10px 10px;
    right: 9px;
    top: -9px;
  }
  .main-navigation ul ul:after {
    border-color: #fff transparent;
    border-width: 0 8px 8px;
    right: 11px;
    top: -7px;
  }
  .main-navigation li:hover > ul, .main-navigation li.focus > ul {
    left: auto;
    right: 0;
  }
  .main-navigation ul ul li:hover > ul, .main-navigation ul ul li.focus > ul {
    left: auto;
    right: 100%;
  }
  .main-navigation .menu-item-has-children > a {
    margin: 0;
    padding-right: 1.7em;
  }
  .main-navigation .menu-item-has-children > a:after {
    content: "\f431";
    position: absolute;
    right: 0.625em;
    top: 0.7em;
  }
  .main-navigation ul ul .menu-item-has-children > a {
    padding-right: 2.0625em;
  }
  .main-navigation ul ul .menu-item-has-children > a:after {
    right: 0.5625em;
    top: 0.875em;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .dropdown-toggle, .main-navigation ul .dropdown-toggle.toggled-on, .menu-toggle, .site-header .social-navigation, .site-footer .main-navigation {
    display: none;
  }
  .content-bottom-widgets .widget-area section {
    margin: 0 0 1.75em 0;
  }
}

@media screen and (max-width: 1800px) {
  #page {
    width: 100%;
  }
  .wp-block-table {
    width: 56.25%;
  }
  .wp-block-image .alignleft {
    margin-left: 21.875% !important;
  }
  .wp-block-image .alignright {
    margin-right: 21.875% !important;
  }
}

@media screen and (max-width: 1600px) {
  .wp-block-table {
    width: 60%;
  }
  .wp-block-image .alignleft {
    margin-left: 20% !important;
  }
  .wp-block-image .alignright {
    margin-right: 20% !important;
  }
  .single-fudo h1.entry-title {
    width: 80%;
    margin: 40px 10% 20px 10%;
  }
  .post-thumbnail img {
    height: 500px;
  }
  .swiper-slide span {
    font-size: 43px;
  }
  span.slide-lead {
    font-size: 24px;
    top: 46%;
  }
}

@media screen and (max-width: 1366px) {
  .wp-block-table {
    width: 70.276%;
  }
  .swiper-slide span {
    font-size: 40px;
    margin-left: 3%;
  }
  span.slide-lead {
    font-size: 24px;
  }
  .wp-block-image .alignleft {
    margin-left: 14.862% !important;
  }
  .wp-block-image .alignright {
    margin-right: 14.862% !important;
  }
  .cont-map {
    float: left;
    width: 100%;
    max-height: 400px;
  }
  .map-left .wp-block-cover-image.alignfull {
    min-height: 400px;
  }
  .map-right iframe {
    height: 400px;
    margin: 0;
  }
}

@media screen and (max-width: 1300px) {
  .main-navigation {
    float: right;
  }
  .wp-block-table {
    width: 75%;
  }
  .page-cont header.entry-header h1.entry-title {
    margin: 30px auto 20px auto;
  }
  .wp-block-image .alignleft {
    margin-left: 12.5% !important;
  }
  .wp-block-image .alignright {
    margin-right: 12.5% !important;
  }
  .swiper-slide span {
    font-size: 38px;
  }
  span.slide-lead {
    font-size: 22px;
    top: 45%;
  }
  .header-image img {
    height: 600px;
  }
  #pono-in, #tekuteku, .home .content-area {
    width: 100%;
    margin: 0 auto;
  }
  .home .hentry {
    width: 90%;
    margin: 0 5%;
  }
  #top-btn {
    font-size: 1rem;
    line-height: 80px;
  }
  .top-btn-title {
    width: 18%;
    letter-spacing: 8px;
    text-align: right;
  }
  .top-btn-title:after {
    border-width: 40px 30px;
  }
  .top-btn-left, .top-btn-right {
    width: 41%;
    line-height: 78px;
  }
  .top-btn-left a, .top-btn-right a {
    float: left;
    width: 100%;
    transition: .5s ease-in-out;
    letter-spacing: 3px;
  }
  .top-btn-left a, .top-btn-right a {
    letter-spacing: 1px;
  }
  .top-btn-left a:before, .top-btn-right a:before {
    right: 2px;
  }
  .cont-in {
    margin: 0 10%;
    width: 80%;
  }
}

/** モバイル用のCSS */
@media screen and (max-width: 910px) {
  .wp-block-cover-image.alignfull {
    min-height: 300px;
  }
  .header-image img {
    height: 400px;
  }
  .main-navigation {
    padding: 0 0.5em;
    float: left;
  }
  .entry-content h2 {
    font-size: 1.5rem;
    margin: 40px auto;
    width: 50%;
    padding: 0.3em;
  }
  .list_picsam img.box3image {
    height: 370px;
    margin: 0 auto;
    width: 75%;
  }
  h2.section-title {
    padding-top: 40px;
  }
  .widget h3.section-title {
    font-size: 1.5rem;
    padding-top: 20px;
    margin-top: 0;
  }
  .list_picsam .swiper-slide {
    text-align: center;
  }
  .wg-footer .widget .widget-title {
    font-size: 20px;
  }
  .wg-footer .widget li ul li {
    margin-bottom: 0;
  }
  #toukou_top ul.toukou_top_post_excerpt {
    width: 100%;
    margin: 0 auto 0 auto;
  }
  .main-navigation .primary-menu {
    padding-top: 5px;
    padding-left: 2%;
  }
  .site-branding {
    margin: 0 0 0 2%;
    width: 40%;
  }
  .site-title {
    font-size: 1.4375rem;
    font-weight: 700;
    line-height: 1;
    margin: 5px 0 0 0;
  }
  .custom-logo {
    max-width: 100%;
    padding: 0.5em;
  }
  .kogma_drawer_menu .kogma_drawer_button {
    height: 50px;
  }
  button.menu-toggle.toggled-on:focus:before {
    content: "\f405";
    font-size: 24px;
    line-height: 30px;
    position: relative;
    width: 100%;
    display: block;
    font-family: "Genericons";
  }
  .menu-toggle {
    font-size: 12px;
    padding: 14px 0;
    float: right;
    width: 70px;
    text-align: center;
    border-radius: 0;
    margin: 0;
    border: 0;
  }
  .menu-toggle:before {
    content: "\f419";
    font-size: 24px;
    line-height: 30px;
    position: relative;
    width: 100%;
    display: block;
    font-family: "Genericons";
  }
  .menu-toggle.toggled-on:before {
    content: "\f405";
    font-size: 24px;
    line-height: 30px;
    position: relative;
    width: 100%;
    display: block;
    font-family: "Genericons";
  }
  .top_price {
    font-size: 140%;
  }
  .archive-fudo .top_title {
    padding: 15px 2% 8px 2%;
    font-size: 0.875rem;
  }
  .list_simple_boxtitle .top_title {
    font-weight: normal;
    display: block;
    padding: 10px 2%;
    font-size: 1.2rem;
  }
  .list_simple_boxtitle .top_title_shubetu {
    font-size: 1rem;
    width: auto;
    padding: 2px 0;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 0;
  }
  #list_add_table dt {
    width: 20%;
    margin-right: 2%;
    font-size: 12px;
  }
  #list_add_table dd {
    width: 100%;
    margin: 10px 1% 20px 0;
    font-size: 0.85rem;
  }
  #toukou_top li {
    width: 97%;
    float: left;
    font-size: 0.875rem;
    margin: 10px 1% 10px 2%;
  }
  #list_other_table h3 {
    font-size: 1.5rem;
    padding-top: 20px;
  }
  .widget h3 {
    font-size: 1.5rem;
    padding-top: 20px;
    padding-bottom: 15px;
    font-weight: 500;
  }
  span.toukou_top_post_title {
    list-style: none;
    font-size: 1rem;
    padding-left: 2%;
  }
  .entry-title {
    margin: 0 2%;
    padding-top: 5px;
    font-size: 1.2rem;
    text-align: center;
  }
  .single-fudo h1.entry-title {
    margin: 20px 0 10px 0;
  }
  h1.entry-title, h1.page-title {
    font-size: 1.5rem;
  }
  h2.entry-title {
    font-size: 1.3rem;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }
  .archive h2.entry-title {
    margin: 5px 0 0 0;
  }
  .nav-previous {
    margin-top: 10px;
  }
  #nav-above1 .nav-next {
    margin: 10px auto;
    padding: 1%;
  }
  #nav-above1 .nav-next a, #nav-above1 .nav-next b {
    padding: 0.2em 0.6em;
  }
  .list_simple_boxtitle .new_mark {
    margin-top: 19px;
    margin-left: -30px;
    font-size: 0.875rem;
    padding: 2px 0 3px 0;
    width: 80px;
  }
  .list_simple_boxtitle .new_mark:before {
    top: 1.55em;
  }
  #bread ul li {
    height: 18px;
    line-height: 20px;
    float: left;
    font-size: 0.7em;
  }
  #list_simplepage .hentry .list_picsam .list_price li {
    margin-bottom: 7px;
    border-left: none;
    font-size: 0.8rem;
  }
  .list_price span {
    margin: 0 15px;
    font-size: 1.1rem;
  }
  .list_detail_bottom_info {
    font-size: 0.6rem;
  }
  .widget_fudo_syousai, .widget.widget_fudou_single_history {
    margin-top: 15px;
    margin-bottom: 0;
    width: 100%;
  }
  .widget_fudo_syousai h3, .widget.widget_fudou_single_history h3 {
    margin-bottom: 0px;
    padding-top: 20px;
  }
  .list_details_button {
    color: #fff;
    font-size: 0.8rem;
    width: 40%;
    padding: 2% 5%;
    margin: 10% 23.5% 10% 22%;
  }
  #list_simplepage .hentry {
    width: 46%;
    margin: 15px 2%;
    font-size: 0.775rem;
  }
  .swiper-button-next {
    right: 0 !important;
    background-color: rgba(0, 0, 0, 0.53);
    width: 6%;
    height: 50px;
  }
  .swiper-button-prev {
    left: 0 !important;
    background-color: rgba(0, 0, 0, 0.53);
    width: 6%;
    height: 50px;
  }
  .swiper-slide span {
    font-size: 2.2rem;
  }
  span.slide-lead {
    font-size: 1.5rem;
  }
  .pagination .nav-links {
    padding-right: 2%;
    width: 100%;
  }
  #bread ul {
    width: 100%;
    padding: 10px 0;
    font-size: 14px;
  }
  .site-footer {
    float: left;
  }
  .pageback {
    margin: 0 2%;
    text-align: center;
  }
  .form_jsearch th {
    width: 32%;
    padding: 0.5em 0 0.5em 0.5em;
    font-size: 0.85rem;
  }
  .form_jsearch td {
    padding: 0.5em;
    font-size: 16px;
    line-height: 2em;
  }
  .form_jsearch table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 1.75em;
    table-layout: fixed;
    width: 100%;
  }
  .form_jsearch h5 {
    font-size: 1em;
    padding-top: 0;
  }
  #content #container {
    float: left;
    margin-right: 0;
    width: 100%;
    margin-left: 0;
  }
  #sub {
    float: left;
    margin-left: 10%;
    padding: 0;
    margin-right: 10%;
    width: 80%;
  }
  input[type="submit"] {
    width: 100%;
    margin: 0 auto;
  }
  #list_add_table dl {
    line-height: 1;
    margin: 1.75em auto 0 auto;
    float: left;
    width: 100%;
    padding-bottom: 0px;
    text-align: center;
  }
  table#list_other {
    font-size: 0.85rem;
  }
  table#list_other th, table#list_other td {
    padding: 0.5em;
  }
  .swiper-pagination.swiper-pagination-fraction {
    width: 25% !important;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    left: 37%;
    background: rgba(0, 0, 0, 0.41);
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
  }
  .swiper-pagination-total:after {
    content: "枚";
  }
  .comments-area {
    padding: 2% 3%;
    width: 90.607%;
    float: left;
    margin: 0 5%;
  }
  img.box1image, img.box2image, img.box4image {
    height: 200px;
  }
  .post-thumbnail img {
    height: 300px;
  }
  .main_picsam {
    margin: 3px auto;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 869px) {
  #list_simplepage .hentry .list_price {
    margin: 0 0 10px 0;
    padding: 0;
  }
  .main-navigation a {
    padding: 0 0.855em 0 0.855em;
  }
  .header-image span {
    top: 26%;
    font-size: 42px;
  }
  .header-image span.slide-lead {
    top: 40%;
    font-size: 24px;
  }
  .list_address_koutu {
    margin: 0px auto;
  }
  #top-btn {
    line-height: 18px;
    font-size: 0.85rem;
  }
  .top-btn-title {
    letter-spacing: 5px;
    float: unset;
    line-height: 30px;
    width: 100%;
    text-align: center;
  }
  .top-btn-title:after {
    border-style: none;
  }
  .top-btn-left a, .top-btn-right a {
    float: left;
    width: 100%;
    transition: .5s ease-in-out;
    letter-spacing: 3px;
    padding: 15px 0;
  }
  .top-btn-left a:before, .top-btn-right a:before {
    top: 3px;
  }
  .top-btn-left, .top-btn-right {
    width: 50%;
    line-height: 20px;
  }
  .cont-map {
    max-height: 300px;
  }
  .map-left .wp-block-cover-image.alignfull {
    min-height: 300px;
  }
  .map-right iframe {
    height: 300px;
  }
  .cont-in {
    margin: 0 5%;
    width: 90%;
  }
  .eye-p {
    margin: 20px 5% 0 5%;
    width: 90%;
    display: block;
  }
}

@media screen and (max-width: 818px) {
  .info-bottom {
    position: fixed;
    top: 0;
    height: 70px;
    line-height: 70px;
    width: auto;
    right: 70px;
    z-index: 1000;
    margin: 0;
    padding: 0 3%;
    text-align: center;
  }
  .info-bottom .genericon {
    font-size: 1.3rem;
    float: left;
    margin-top: 25px;
    margin-right: 5px;
  }
  .admin-bar .info-bottom {
    top: 32px;
  }
  .site-header-main-in .info strong {
    font-size: 24px;
  }
  .main-navigation {
    float: right;
  }
  .site-header-main-in .info span {
    margin-left: 5px;
  }
  .site-header-main-in .info br {
    display: unset;
  }
  .single-fudo h1.entry-title {
    width: 90%;
    margin: 30px 1% 20px 5%;
  }
  header.entry-header span.bu-cat {
    margin-right: 20px;
    height: 30px;
    width: 20%;
    padding: 5px 0 0 0;
    font-size: 12px;
    text-align: center;
  }
  #list_add_table dl {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 736px) {
  .admin-bar .info-bottom {
    top: 46px;
  }
  .site-footer .main-navigation {
    display: unset;
    width: 100%;
    margin: 1.75em 0 0 0;
  }
  .wg-footer {
    margin: 0;
    padding: 0;
  }
  .content-bottom-widgets .widget-area {
    width: 94%;
    margin: 10px 3% 20px 3%;
  }
}

@media screen and (max-width: 709px) {
  .swiper-button-next {
    right: 0 !important;
    background-color: rgba(0, 0, 0, 0.53);
    width: 6%;
    height: 50px;
  }
  .swiper-button-prev {
    left: 0 !important;
    background-color: rgba(0, 0, 0, 0.53);
    width: 6%;
    height: 50px;
  }
}

@media screen and (max-width: 600px) {
  #user_login.input, #password.input {
    margin-bottom: 10px;
  }
  #loginform input[type="submit"] {
    margin: 15px auto 5px auto;
  }
  .cont-in {
    padding: 20px 0;
    margin: 0 2%;
    width: 96%;
  }
  .eye-bun {
    margin: 0 10% 30px 10%;
  }
  .eye-box {
    width: 47%;
    margin: 0 1.5% 10px 1.5%;
  }
  .eye-mark {
    padding: 2px 2%;
    margin: 0;
    width: 100%;
    display: block;
    text-align: center;
  }
  .eye-p {
    padding: 0;
    margin: 15px 5%;
  }
  .eye-bottom {
    padding: 0;
    font-size: 14px;
  }
  .map-left {
    width: 100%;
    float: left;
  }
  .map-right, .map-right iframe {
    width: 100%;
    float: left;
    height: 200px;
  }
  .contact-box {
    width: 95%;
  }
  .contact-box-left {
    width: 48%;
    font-size: 0.75rem;
  }
  .contact-box-tel {
    font-size: 1rem;
    font-weight: 700;
  }
  .contact-box-right {
    width: 48%;
    font-size: 0.75rem;
    padding-left: 0;
  }
  .contact-box-form {
    margin: 15px 0;
    padding: 0;
    width: 100%;
  }
  .contact-box-form a {
    padding: 10px 5%;
    font-size: 0.7rem;
  }
  　.wp-block-table {
    width: 80%;
  }
  .wp-block-image .alignleft {
    margin-left: 10% !important;
  }
  .wp-block-image .alignright {
    margin-right: 10% !important;
  }
  .wp-block-cover-image {
    min-height: 200px !important;
  }
  h1.entry-title, h1.page-title {
    font-size: 1.3rem;
  }
  .post-thumbnail img {
    height: 200px;
  }
  .list_detail .list_price li {
    font-size: 0.85rem;
    padding-left: 1%;
    margin-bottom: 10px;
  }
  .list_simple_box .list_price span {
    font-size: 1rem;
  }
  html #wpadminbar {
    position: fixed;
  }
  .single-fudo h1.entry-title {
    width: 94%;
    margin: 50px 1% 0 5%;
    text-align: left;
  }
  header.entry-header span.bu-cat {
    margin-right: 10px;
    height: 25px;
    padding: 6px 2.5% 0 2.5%;
    font-size: 10px;
    width: auto;
    top: -20px;
    text-align: center;
    display: block;
    position: absolute;
  }
  header.entry-header .new_mark {
    margin: 0;
    z-index: 2;
    height: 30px;
    position: absolute;
    display: block;
    width: 30px;
    padding: 9px 0 0 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.36);
    font-size: 10px;
    text-align: center;
    left: -15px;
    top: -42px;
    border-radius: 20px;
    font-family: 'Helvetica', 'sans-serif';
    transform: rotate(-15deg);
  }
  .swiper-slide span {
    font-size: 1.9rem;
    top: 40%;
  }
  .site-title {
    font-size: 1.2rem;
    margin: 8px 0 0 0;
  }
  .site-description {
    font-size: 0.66rem;
  }
  span.slide-lead {
    font-size: 1.2rem;
    top: 45%;
  }
  .swiper-button-next {
    width: 8%;
    height: 30px;
  }
  .swiper-button-prev {
    width: 8%;
    height: 30px;
  }
  .list_picsam img.box3image {
    height: 250px;
  }
  .entry-content h2 {
    width: 60%;
    font-size: 1rem;
    margin: 30px auto;
  }
  .post-thumbnail {
    margin-bottom: 1.625em;
  }
  .header-image span.slide-lead {
    width: 70%;
    margin: 0 15%;
    line-height: 1.4;
    font-size: 18px;
    top: 35%;
  }
  .top-btn-left, .top-btn-right {
    width: 100%;
  }
  .top-btn-right a, .top-btn-left a {
    border-left: 0;
    padding-left: 5%;
    text-align: left;
  }
  .header-image span {
    font-size: 30px;
  }
  .cont-map {
    max-height: 400px;
  }
  .archive-fudo h1.entry-title {
    padding: 5px;
    margin: 10px auto;
  }
  #list_simplepage .hentry {
    width: 98%;
    margin: 15px 1%;
    padding: 0 0 10px 0;
  }
  img.box1image, img.box2image {
    height: 140px;
  }
  img.box4image {
    height: 220px;
  }
}

@media screen and (max-width: 420px) {
  .widget p.eye-bun {
    text-align: left;
    margin: 0 10% 50px 10%;
    line-height: 2;
  }
  .widget p.eye-bun br {
    display: none;
  }
  .admin-bar .info-bottom, .info-bottom {
    top: unset;
  }
  .wp-block-image .alignleft img, .wp-block-image .alignright img {
    width: 95%;
  }
  .header-image span {
    width: 70%;
    font-weight: 700;
    margin: 0 15%;
    line-height: 1.4;
    font-size: 28px;
    top: 15%;
  }
  .header-image span br {
    display: unset;
  }
  .home .grid-content li .top_title, .top_shozaichi, .home .grid-content li span.top_kotsu, .grid-content.cookie_fudou_single li .top_title {
    padding: 5px 2% 2px 2%;
  }
  .home .hentry {
    margin: 0 1%;
    width: 98%;
  }
  .home .new_mark, #syousai_box .new_mark, .grid-content.cookie_fudou_single .new_mark {
    height: 25px;
    padding: 5px 0 0 0;
    width: 85px;
    left: -25px;
    top: 6px;
  }
  table#list_other th, table#list_other td {
    padding: 0.6em 0.1em 0.6em 0.4em;
    font-size: 12px;
  }
  html #wpadminbar {
    position: fixed;
  }
  .list_picsam .list_address, .list_picsam .list_address_koutu {
    margin: 0 2% 6px 5%;
    width: 93%;
    text-align: left;
    font-size: 12px;
  }
  .list_address_koutu br {
    padding-bottom: 10px;
  }
  .kogma_drawer_menu .kogma_drawer_text {
    text-align: left;
    padding: 3px 10px 0 10px;
    font-size: 12px;
    font-weight: bold;
  }
  .sidebar select {
    font-size: 85%;
  }
  .sidebar select#mel, .sidebar select#meh, .sidebar select#kalc, .sidebar select#kahc, .sidebar select#kalb, .sidebar select#kahb {
    width: 45.8%;
  }
  .sidebar {
    padding: 50px 8% 80px 8%;
    margin: 0;
  }
  .admin-bar .kogma_drawer_menu .kogma_drawer_button, .kogma_drawer_menu .kogma_drawer_button {
    bottom: 0;
    left: 0;
    top: unset;
    width: 50%;
    margin: 0;
    height: 55px;
    border-radius: 0;
  }
  .kogma_drawer_menu .kogma_drawer_button:before {
    float: left;
    margin-left: 18%;
    line-height: 19px;
  }
  .info-bottom {
    bottom: 0;
    height: 55px;
    line-height: 20px;
    width: 50%;
    z-index: 10000;
    text-align: left;
    right: 0;
    margin: 0;
    padding: 8px 10px 0 1%;
    font-size: 12px;
    font-weight: bold;
  }
  .info-bottom .genericon {
    font-size: 1.3rem;
    float: left;
    margin-top: 0;
    margin-right: 5%;
    margin-left: 12%;
  }
  #page-top {
    bottom: 65px;
    height: 35px;
    width: 35px;
    right: 5%;
    font-size: 80%;
    z-index: 10000;
  }
  .kogma_drawer_menu .kogma_drawer_nav_wrapper {
    width: 280px;
    height: 100%;
    transition: all 0.2s;
    transform: translate(280px);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
  }
  .contact-form {
    width: 95%;
  }
  .home h2.section-title {
    padding-top: 10px;
  }
  #charge {
    padding: 2%;
    width: 95%;
  }
  img.kannri_tanto_pic {
    width: 20%;
  }
  .widget h3.section-title {
    font-size: 1.5rem;
    padding-top: 15px;
    margin-top: 0;
    padding-bottom: 10px;
  }
  .list_simple_boxtitle .new_mark {
    margin-left: -25px;
    z-index: 100;
  }
  .swiper-slide span {
    font-size: 1.3rem;
    margin-left: 6%;
    top: 38%;
    font-weight: bold;
    font-family: Meiryo;
    letter-spacing: 1px;
  }
  span.slide-lead {
    font-size: 0.9rem;
    top: 42%;
  }
  .site-branding {
    width: 70%;
  }
  .entry-content h2 {
    width: 70%;
  }
  .form_jsearch td {
    padding: 0.3em 0.1em 0.3em 0.5em;
  }
  .list_simple_box .list_detail {
    margin: 0 1%;
    width: 98%;
  }
  .wp-block-embed__wrapper iframe {
    width: 100% !important;
    margin: 0 0 1.75em 0;
    height: auto;
  }
}

@media screen and (max-width: 385px) {
  .info-bottom .genericon {
    font-size: 1.3rem;
    float: left;
    margin-top: 0;
    margin-right: 5%;
    margin-left: 10%;
  }
  .kogma_drawer_menu .kogma_drawer_button:before {
    float: left;
    margin-left: 15%;
    line-height: 19px;
  }
  .list_simple_boxtitle .new_mark:before {
    top: 1.50em;
  }
  .swiper-slide span {
    font-size: 1.2rem;
  }
  span.slide-lead {
    font-size: 0.7rem;
  }
  .form_jsearch th {
    width: 28%;
    padding: 0.5em 0 0.5em 0.3em;
  }
  .list_details_button {
    font-size: 0.7rem;
    width: 40%;
    padding: 1% 5%;
    margin: 12% 24.5% 10% 23%;
  }
  #page_jsearch_page select#mel, #page_jsearch_page select#meh, #page_jsearch_page select#kalc, #page_jsearch_page select#kahc, #page_jsearch_page select#kalb, #page_jsearch_page select#kahb {
    width: 40%;
  }
  .form_jsearch th, .form_jsearch td {
    font-size: 16px;
    line-height: 2em;
  }
  .header-image span.slide-lead {
    width: 90%;
    margin: 0 5%;
    top: 38%;
    font-size: 12px;
  }
  .header-image img {
    height: 350px;
  }
  #page_jsearch_page {
    margin: 0 3% 1.75em 3%;
    width: 94%;
  }
  img.box1image, img.box2image {
    height: 120px;
  }
}

@media screen and (max-width: 320px) {
  #list_add_table, #list_other_table, .list_price ul {
    margin: 0 2%;
    width: 96%;
  }
  .info-bottom .genericon {
    margin-right: 3%;
    margin-left: 3%;
  }
  .kogma_drawer_menu .kogma_drawer_button:before {
    margin-left: 10%;
  }
  .site-header-main {
    line-height: 50px;
  }
  .menu-toggle {
    padding: 4px 0;
    height: 50px;
  }
  .swiper-slide span {
    font-size: 1.6rem;
    top: 25%;
    width: 70%;
    margin: 0 13% 0 12%;
    line-height: 1.2;
  }
  span.slide-lead {
    display: none;
  }
  .header-image span {
    top: 14%;
  }
  .header-image img {
    height: 300px;
  }
  img.box1image, img.box2image, img.box4image {
    height: 180px;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .wp-block-cover .wp-block-cover-text {
    line-height: 100px;
    margin: 10%;
    text-align: center;
  }
  /* IE11 */
}

/*// -----------------------------------------------------------------
// red-style.css
// -----------------------------------------------------------------*/
/*
red-style.css
をベースにカスタマイズ
*/
body, button, input, textarea {
  background-color: #e6e6e6;
}

.widget select, .widget textarea {
  background-color: #f7f7f7;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  padding: 0.4375em;
  width: 100%;
}

button:focus .kogma_drawer_menu .kogma_drawer_button .kogma_drawer_bar, .widget button, #list_add_table dt, .site-header-main-in .info span, .single-post header.entry-header {
  background-color: #eb613b;
}

header.entry-header span.bu-cat {
  background-color: #aeaeae;
}

.header-image {
  background-color: #000000;
}

.site-header-main-in .info {
  color: #7A8687;
}

.contact-box-form, .site-header-main-in .info span, .kogma_drawer_menu .kogma_drawer_button, .pagination a, .info-bottom a, #list_add_table dt, #syousai_box .new_mark:after, .grid-content.cookie_fudou_single .new_mark:after {
  color: #fff;
}

.single-post header.entry-header {
  color: #fff;
  text-shadow: 0 0 10px #000, 0 0 15px #000;
}

.site-header-main-in .info strong, .top-btn-left a:before, .top-btn-right a:before {
  color: #000000;
}

.site-header-main {
  background-color: rgba(255, 255, 255, 0.93);
}

.kogma_drawer_menu .kogma_drawer_nav_wrapper, .site, .site-inner, .site-footer, td.td1 span {
  background-color: #fff;
}

.kogma_drawer_menu .kogma_drawer_button.active {
  background-color: #aeaeae;
}

#ponopono, #top-map, #top-eye {
  background-color: #f2f2f2;
}

/*drawer_menu end*/
table {
  border: 1px solid #eb613b;
}

th, td {
  border: 1px solid #eb613b;
}

th {
  background: #f7c0b1;
}

td.td1 span {
  border: 1px solid #dad4d4;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

/* Block: Quote -------------------------------------------- */
blockquote {
  margin: 0 auto;
}

.wp-block-quote {
  background: #f6f6f6;
  clear: both;
  color: #666;
  display: block;
  font-size: 1em;
  max-width: 960px;
  width: 100%;
  margin: 0 auto 1.75em auto;
  position: relative;
  padding: 30px 30px 10px 90px;
}

.wp-block-quote p {
  padding: 0 0 10px 0px;
  margin: 0;
  width: 100%;
}

.wp-block-quote:before {
  color: #aaa;
  content: '”';
  display: block;
  font-family: 'Georgia', 'Times New Roman', serif;
  font-size: 72px;
  font-weight: 700;
  line-height: 72px;
  position: absolute;
  left: 0px;
  top: 32px;
  text-align: center;
  width: 90px;
  -webkit-font-smoothing: antialiased;
}

cite {
  font-weight: 700;
}

cite::before, .wp-block-quote footer::before, .wp-block-quote__citation::before, .wp-block-pullquote__citation::before {
  content: "— ";
}

.new_mark {
  background-color: #eb613b;
  color: #fff;
  text-transform: uppercase;
}

.eye-mark, .login_success .logout_title a, .login_success .repass_title a {
  color: #fff;
  background-color: #eb613b;
}

.login_success .logout_title a:hover, .login_success .repass_title a:hover {
  color: #fff;
  background-color: #aeaeae;
}

button, button[disabled]:hover, button[disabled]:focus, input[type="button"], input[type="button"][disabled]:hover, input[type="button"][disabled]:focus, input[type="reset"], input[type="reset"][disabled]:hover, input[type="reset"][disabled]:focus, input[type="submit"], input[type="submit"][disabled]:hover, input[type="submit"][disabled]:focus {
  background-color: #eb613b;
  border-radius: 2px;
  color: #fff;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-weight: normal;
  letter-spacing: 0.046875em;
  line-height: 1;
  padding: 0.84375em 0.875em 0.78125em;
  text-transform: uppercase;
  border: solid 2px #eb613b;
}

.widget.widget_fudo_search {
  width: 100%;
  margin: 10px auto 0 auto;
}

button:focus, input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus {
  outline: thin dotted;
  outline-offset: -4px;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
  background-color: #f7f7f7;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  color: #686868;
  padding: 0.625em 0.4375em;
  margin-top: 10px;
  width: 100%;
}

input[type="date"]:focus, input[type="time"]:focus, input[type="datetime-local"]:focus, input[type="week"]:focus, input[type="month"]:focus, input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="number"]:focus, textarea:focus {
  background-color: #fff;
  border-color: #eb613b;
  color: #000000;
  outline: 0;
}

.post-password-form label {
  color: #686868;
}

a:hover, a:focus, a:active, .sidebar .widget_fudou_jsearch_history li a:hover {
  color: #aaaaaa;
}

.main-navigation li {
  border-top: 1px solid #d1d1d1;
  position: relative;
}

.main-navigation a:hover, .main-navigation a:focus {
  color: #eb613b;
}

.dropdown-toggle:after {
  border: 0 solid #d1d1d1;
  border-left-width: 1px;
  content: "\f431";
  font-size: 24px;
  left: 1px;
  position: relative;
  width: 48px;
}

.dropdown-toggle:hover, .dropdown-toggle:focus {
  background-color: transparent;
  color: #eb613b;
}

.social-navigation a:hover:before, .social-navigation a:focus:before {
  color: #eb613b;
}

/** nav　pagenation */
#nav-above1 .nav-next {
  width: 100%;
  margin: 20px auto 10px auto;
  padding: 10px;
  text-align: right;
}

#nav-above1 .nav-next a {
  letter-spacing: 0.013157895em;
  line-height: 1;
  margin: 0;
  padding: 0.3947368421em 0.7em;
  text-transform: uppercase;
  transition: .3s ease-in-out;
}

#nav-above1 .nav-next b {
  font-weight: 700;
  padding: 0.34em 0.7586em;
}

.nav-previous {
  text-align: left;
  margin-top: 30px;
}

.pagination {
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 19px;
  font-size: 1.1875rem;
  min-height: 56px;
  position: relative;
}

.pagination .current .screen-reader-text {
  position: absolute !important;
}

.pagination:before {
  right: 0;
}

.pagination:after {
  right: 54px;
}

.pagination .nav-links {
  padding-right: 106px;
  position: relative;
}

.pagination .nav-links:before {
  content: "\f429";
  right: -1px;
}

.pagination .nav-links:after {
  content: "\f430";
  right: 55px;
}

.pagination .prev:focus, .pagination .next:focus {
  outline: 0;
}

.pagination .prev {
  right: 54px;
}

.pagination .prev:before {
  content: "\f430";
  left: -1px;
  top: -1px;
}

.pagination .next {
  right: 0;
}

.pagination .next:before {
  content: "\f429";
  right: -1px;
  top: -1px;
}

.post-navigation {
  border-top: 4px solid #000000;
  border-bottom: 4px solid #000000;
  clear: both;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  margin: 0 7.6923% 3.5em;
}

.post-navigation a {
  color: #000000;
  display: block;
  padding: 1.75em 0;
}

.post-navigation span {
  display: block;
}

.post-navigation .meta-nav {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  letter-spacing: 0.076923077em;
  line-height: 1.6153846154;
  margin-bottom: 0.5384615385em;
  text-transform: uppercase;
}

.post-navigation .post-title {
  display: inline;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 700;
  line-height: 1.2173913043;
  text-rendering: optimizeLegibility;
}

.post-navigation a:hover .post-title, .post-navigation a:focus .post-title {
  color: #eb613b;
}

.post-navigation div + div {
  border-top: 4px solid #000000;
}

.pagination:before, .pagination:after {
  background-color: #e3e3e3;
  content: "";
  height: 52px;
  position: absolute;
  top: 0;
  width: 52px;
  z-index: 0;
}

.pagination a:hover, .pagination a:focus {
  color: #000000;
}

.pagination .nav-links:before, .pagination .nav-links:after {
  color: #eb613b;
  font-size: 32px;
  line-height: 51px;
  opacity: 0.3;
  position: absolute;
  width: 52px;
  z-index: 1;
}

.pagination .page-numbers {
  display: none;
  background-color: #eb613b;
  letter-spacing: 0.013157895em;
  line-height: 1;
  margin: 0;
  padding: 0.3947368421em 0.7em;
  text-transform: uppercase;
  border: 1px solid #eb613b;
}

.pagination a:hover {
  background-color: #eb613b;
  border: 1px solid #eb613b;
  color: #fff;
}

.pagination .current {
  display: inline-block;
  font-weight: 700;
  background-color: #e3e3e3;
  border: 1px solid #dad4d4;
  color: #eb613b;
}

.pagination .prev, .pagination .next {
  background-color: #eb613b;
  color: #fff;
  display: inline-block;
  height: 52px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 52px;
  z-index: 2;
}

.pagination .prev:before, .pagination .next:before {
  font-size: 32px;
  height: 53px;
  line-height: 52px;
  position: relative;
  width: 53px;
}

.pagination .prev:hover, .pagination .prev:focus, .pagination .next:hover, .pagination .next:focus {
  background-color: #eb613b;
  border: 1px solid #eb613b;
  color: #fff;
}

.image-navigation, .comment-navigation {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  color: #686868;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin: 0 7.6923% 2.1538461538em;
  padding: 1.0769230769em 0;
}

.site .skip-link {
  background-color: #f1f1f1;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  color: #eb613b;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 700;
  left: -9999em;
  outline: none;
  padding: 15px 23px 14px;
  text-decoration: none;
  text-transform: none;
  top: -9999em;
}

.logged-in .site .skip-link {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  font-family: "Open Sans", sans-serif;
}

.wg-footer .widget .widget-title {
  color: #ffffff;
}

.widget-title a {
  color: #000000;
}

.wg-footer .widget .widget-title a {
  color: #fff;
}

/* Calendar widget */
.widget.widget_calendar table {
  margin: 0;
  border: 1px solid #dad4d4;
}

.widget_calendar td, .widget_calendar th {
  line-height: 2.5625;
  padding: 0;
  text-align: center;
}

.widget_calendar caption {
  font-weight: 900;
  margin-bottom: 0.75em;
  color: #eb613b;
}

.widget_calendar a {
  display: block;
  font-weight: 700;
  background-color: #ffffff;
}

.widget_calendar th {
  background-color: #aaa;
}

.widget_calendar td {
  color: #000000;
  background-color: #f5f5f5;
  border-bottom: 1px solid #dad4d4;
}

.widget_calendar td#today {
  background-color: #eb613b;
  color: #fff;
}

.widget_calendar tbody a:hover, .widget_calendar tbody a:focus {
  background-color: #686868;
  color: #fff;
}

/* Recent Posts widget */
.widget_recent_entries .post-date {
  color: #686868;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.615384615;
  margin-bottom: 0.538461538em;
}

.widget_recent_entries li:last-child .post-date {
  margin-bottom: 0;
}

/* RSS widget */
.widget_rss .rsswidget img {
  margin-top: -0.375em;
}

.widget_rss .rss-date, .widget_rss cite {
  color: #686868;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: normal;
  line-height: 1.615384615;
  margin-bottom: 0.538461538em;
}

.widget_rss .rssSummary:last-child {
  margin-bottom: 2.1538461538em;
}

.widget_rss li:last-child :last-child {
  margin-bottom: 0;
}

/* Tag Cloud widget */
.tagcloud a {
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  display: inline-block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  line-height: 1;
  margin: 0 0.1875em 0.4375em 0;
  padding: 0.5625em 0.4375em 0.5em;
}

.tagcloud ul {
  list-style-type: none;
  margin-left: 0;
}

.tagcloud ul li {
  display: inline-block;
}

.tagcloud a:hover, .tagcloud a:focus {
  border-color: #916b54;
  color: #916b54;
  outline: 0;
}

.site-branding .site-title a {
  color: #000000;
}

.site-branding .site-title a:hover, .site-branding .site-title a:focus {
  color: #6f6f6f;
}

.wp-custom-logo .site-title {
  margin-top: 0.608695652em;
}

.site-description {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.0769230769;
  margin: 0.3em 0 0;
}

.menu-toggle:focus {
  outline: 0;
}

.menu-toggle.toggled-on:focus {
  outline: thin dotted;
}

button:focus, input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus {
  outline: thin dotted;
  outline-offset: -4px;
}

.no-js .menu-toggle {
  display: none;
}

.entry-content, .entry-summary {
  border-color: #d1d1d1;
  line-height: 30px;
}

body:not(.search-results) .entry-summary {
  color: #686868;
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1.4736842105;
  margin-bottom: 1.4736842105em;
}

.author-bio {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin-bottom: 1.6153846154em;
  overflow: hidden;
}

.author-link {
  white-space: nowrap;
}

.entry-footer {
  color: #686868;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin-top: 2.1538461538em;
}

.entry-footer:empty {
  margin: 0;
}

.entry-footer a {
  color: #686868;
}

.entry-footer a:hover, .entry-footer a:focus {
  color: #ff8c00;
}

.entry-footer > span:not(:last-child):after {
  content: "\002f";
  display: inline-block;
  opacity: 0.7;
  padding: 0 0.538461538em;
}

.entry-footer .avatar {
  height: 21px;
  margin: -0.1538461538em 0.5384615385em 0 0;
  width: 21px;
}

.sticky-post {
  color: #686868;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  letter-spacing: 0.076923077em;
  line-height: 1.6153846154;
  margin-bottom: 0.5384615385em;
  text-transform: uppercase;
}

.taxonomy-description {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
}

.page-links {
  clear: both;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  margin: 0 0 1.75em;
}

.page-links a, .page-links > span {
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  display: inline-block;
  font-size: 13px;
  font-size: 0.8125rem;
  height: 1.8461538462em;
  line-height: 1.6923076923em;
  margin-right: 0.3076923077em;
  text-align: center;
  width: 1.8461538462em;
}

.page-links a {
  background-color: #000000;
  border-color: #000000;
  color: #fff;
}

.page-links a:hover, .page-links a:focus {
  background-color: #eb613b;
  border-color: transparent;
  color: #fff;
}

.page-links > .page-links-title {
  border: 0;
  color: #000000;
  height: auto;
  margin: 0;
  padding-right: 0.6153846154em;
  width: auto;
}

.entry-attachment {
  margin-bottom: 1.75em;
}

.entry-caption {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: italic;
  line-height: 1.6153846154;
  padding-top: 1.0769230769em;
}

.comments-title, .comment-reply-title {
  border-top: 1px solid #d1d1d1;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 700;
  line-height: 1.3125;
  padding-top: 1.217391304em;
}

.comment-list article, .comment-list .pingback, .comment-list .trackback {
  border-top: 1px solid #d1d1d1;
  padding: 1.75em 0;
}

.comment-author {
  color: #000000;
  margin-bottom: 0.4375em;
}

.comment-metadata, .pingback .edit-link {
  color: #686868;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
}

.comment-metadata {
  margin-bottom: 2.1538461538em;
}

.comment-metadata a, .pingback .comment-edit-link {
  color: #686868;
}

.comment-metadata a:hover, .comment-metadata a:focus, .pingback .comment-edit-link:hover, .pingback .comment-edit-link:focus {
  color: #eb613b;
}

.comment-reply-link {
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  color: #eb613b;
  display: inline-block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1;
  margin-top: 2.1538461538em;
  padding: 0.5384615385em 0.5384615385em 0.4615384615em;
}

.comment-reply-link:hover, .comment-reply-link:focus {
  border-color: currentColor;
  color: #eb613b;
  outline: 0;
}

.comment-form {
  padding-top: 1.75em;
}

.comment-form label {
  color: #686868;
  display: block;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  letter-spacing: 0.076923077em;
  line-height: 1.6153846154;
  margin-bottom: 0.5384615385em;
  text-transform: uppercase;
}

.comment-list .comment-form {
  padding-bottom: 1.75em;
}

.comment-notes, .comment-awaiting-moderation, .logged-in-as, .form-allowed-tags {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin-bottom: 2.1538461538em;
}

.no-comments {
  border-top: 1px solid #d1d1d1;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-weight: 700;
  margin: 0;
  padding-top: 1.75em;
}

.required {
  color: #eb613b;
  font-family: Merriweather, Georgia, serif;
}

.comment-reply-title small a:hover, .comment-reply-title small a:focus {
  color: #000000;
}

.site-info {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  float: left;
}

.top-btn {
  margin: 0 auto;
  padding: 0;
  max-width: 840px;
  width: 90%;
  text-align: center;
}

.top-btn a {
  border-right: none;
  font-size: 1.2rem;
  margin: 40px 0 20px 0;
  padding: 5px 0;
  line-height: 3;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  display: block;
  transition: .3s ease-in-out;
}

.top-btn a:before {
  content: "\f429";
  font-size: 30px;
  line-height: 30px;
  position: relative;
  width: 35px;
  font-family: "Genericons";
  left: 3px;
  top: 7px;
}

.info-bottom, .kogma_drawer_menu .kogma_drawer_button, .top-btn a {
  background-color: #eb613b;
  color: #fff;
}

.wp-caption .wp-caption-text {
  color: #686868;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: italic;
  line-height: 1.6153846154;
  padding-top: 0.5384615385em;
}

.entry-content h2:before {
  border-left: solid 1px #000000;
  border-top: solid 1px #000000;
  border-bottom: solid 1px #000000;
  left: 0;
}

.entry-content h2:after {
  content: '';
  border-top: solid 1px #000000;
  border-right: solid 1px #000000;
  border-bottom: solid 1px #000000;
  right: 0;
}

header.entry-header span.bu-cat {
  color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.17);
}

.wg-footer {
  background-color: #000000;
  color: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.wg-footer li a {
  color: #ccc;
}

.wg-footer li a:hover {
  color: #eb613b;
}

.content-bottom-widgets .widget-area #searchitem {
  color: #eb613b;
}

.top_price {
  font-weight: 600;
  color: #ee686d;
  font-size: 130%;
  font-family: arial;
  padding-left: 10px;
}

.top_madori {
  font-weight: 700;
  color: #eb613b;
  font-size: 130%;
  font-family: arial;
  padding-right: 10px;
}

.box1low a {
  color: #fff;
  background-color: #eb613b;
  border: solid 2px #eb613b;
}

.box1low a:hover {
  color: #eb613b;
  background-color: #fff;
  border: solid 2px #eb613b;
}

.top_title {
  color: #000;
}

.top_shozaichi {
  display: block;
  padding-left: 10px;
  padding-right: 10px;
}

.top_kotsu {
  padding-left: 10px;
  padding-right: 10px;
}

#nendebcopy {
  background-color: rgba(255, 255, 255, 0);
  margin: 0 auto;
  text-align: center;
}

#nendebcopy a {
  color: #a0a5aa;
  font-size: 80%;
}

/** top banner */
.top-btn-title {
  color: #fff;
  background-color: #eb613b;
}

.top-btn-title:after {
  border-color: transparent;
  border-left-color: #eb613b;
}

.top-btn-right a, .top-btn-left a {
  color: #000000;
  background-color: rgba(255, 255, 255, 0.89);
}

.top-btn-left a {
  border-right: 1px solid #aaa;
}

.top-btn-right a {
  border-left: 2px solid #fff;
}

.top-btn-right a:hover, .top-btn-left a:hover {
  background-color: #fff;
  text-shadow: 0 0 10px #d1d1d1, 0 0 15px #d1d1d1;
}

/** slider */
.mb60 {
  margin-bottom: 60px;
}

.header-image span {
  text-shadow: 0 0 10px #000, 0 0 15px #000;
  color: #fff;
}

.prettyprint {
  border: none;
  background-color: #fafafa;
  color: #697d86;
}

.swiper-slide-next, .swiper-slide-prev {
  display: inline-block;
}

.swiper-slide-prev img, .swiper-slide-next img {
  opacity: 0.6;
}

.kaiin_register a {
  color: #fff;
  padding: 10px 5%;
  background-color: #000000;
}

.kaiin_register a:hover.thickbox {
  color: #fff;
  background-color: #aeaeae;
}

kaiin-readtxt {
  border: solid 1px #e3e3e3;
}

.list_picsam {
  margin-bottom: 30px;
  padding: 10px 0;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.67);
  border-radius: 50%;
}

.swiper-pagination-bullet-active {
  background-color: rgba(255, 255, 255, 0.93);
  transform: scale(1.4);
}

.list_price dt.leftline {
  border-left: 1px solid rgba(65, 65, 65, 0.25);
}

#list_add_table dl {
  border-bottom: 1px solid #eb613b;
}

.dpoint4 {
  font-weight: 700;
  color: #eb613b;
}

h2.section-title {
  color: #eb613b;
}

.widget h3.section-title {
  color: #000000;
}

#list_other_table h3, .widget h3 {
  color: #000000;
}

.contact-chui {
  background-color: #ee686d;
  color: #fff;
  border-top: 2px solid #ee686d;
}

.contact-box-form a {
  background-color: #eb613b;
  color: #fff;
}

.contact-box-form a:hover {
  background-color: #ee686d;
  color: #fff;
}

.contact-form2 {
  border: 1px solid #d1d1d1;
}

#nav-above1 .nav-next a {
  background-color: #e6e6e6;
  color: #eb613b;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}

#nav-above1 .nav-next a:hover {
  background-color: #aeaeae;
  border: 1px solid #aeaeae;
  color: #fff;
}

#nav-above1 .nav-next b {
  background-color: #eb613b;
  border: 1px solid #eb613b;
  color: #ffffff;
}

#map_search h3 {
  background-color: #e3e3e3 !important;
}

#map_menu {
  border: none !important;
  background-color: none !important;
}

#bread-out {
  background-color: #f2f2f2;
}

@media screen and (min-width: 56.875em) {
  .kogma_drawer_menu .kogma_drawer_button:hover, .top-btn a:hover {
    background-color: #333333;
  }
}

@media screen and (max-width: 910px) {
  .menu-toggle.toggled-on {
    background-color: #aeaeae;
    color: #fff;
  }
}

@media screen and (max-width: 600px) {
  .top-btn-left a {
    border-right: 0;
    border-bottom: 1px solid #ababab;
  }
  .top-btn-right a {
    border-left: 0;
    border-top: 1px solid #ffffff;
  }
  .top-btn-right a, .top-btn-left a {
    color: #00222f;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.73);
    border-top: 1px solid #ffffff;
  }
  .top-btn-title {
    border-top: 1px solid #eb613b;
    background-color: rgba(191, 0, 0, 0.68);
  }
  .top-btn a {
    margin: 20px 0;
    padding: 0;
    line-height: 2.5;
  }
}

@media screen and (max-width: 420px) {
  .kogma_drawer_menu .kogma_drawer_button {
    border-bottom: 8px solid #fff;
    border-left: 8px solid #fff;
    border-top: 8px solid #fff;
    border-right: 4px solid #fff;
  }
  .info-bottom {
    border-bottom: 8px solid #fff;
    border-left: 4px solid #fff;
    border-top: 8px solid #fff;
    border-right: 8px solid #fff;
  }
}

a {
  color: #000000;
  text-decoration: none;
}

a:hover {
  color: #eb613b;
}

/*// -----------------------------------------------------------------
// 後で削除
// -----------------------------------------------------------------*/
.site-footer .site-title::after {
  display: none;
}

/* 物件一覧 */
.archive-fudo .top_title {
  text-align: left;
}

.list_picsam .list_address, .list_picsam .list_address_koutu {
  text-align: left;
  padding: 0 20px 0 30px;
}

/* お知らせ */
.blog .page-cont {
  margin: 0 auto 1.75em auto;
  width: 960px;
}

.blog .page-cont p {
  margin: 0.75em auto 1.75em auto;
}

@media screen and (max-width: 1800px) {
  .blog .page-cont {
    margin: 0 21.875% 1.75em 21.875%;
    width: auto;
  }
}

@media screen and (max-width: 1600px) {
  .blog .page-cont {
    margin: 0 20% 1.75em 20%;
    width: auto;
  }
}

@media screen and (max-width: 1366px) {
  .blog .page-cont {
    margin: 0 14.862% 1.75em 14.862%;
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  .blog .page-cont {
    margin: 0 10% 1.75em 10%;
    width: auto;
  }
}

/*// -----------------------------------------------------------------
// packages
// -----------------------------------------------------------------*/
/*// ==========================================================================
// Layout
// ==========================================================================*/
/*// -----------------------------------------------------------------
// Page head
// -----------------------------------------------------------------*/
.l-page-head {
  width: 100%;
}

.l-page-head__img {
  width: 100%;
}

.l-page-head__inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.l-page-head__title {
  line-height: 1.5;
  position: absolute;
  top: 43%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.l-page-head__title.__small {
  top: 30%;
}

@media (max-width: 767px) {
  .l-page-head__title.__small {
    top: 40%;
  }
}

.l-page-head__title.__about {
  top: 40%;
}

@media (max-width: 767px) {
  .l-page-head__title.__about {
    top: 40%;
  }
}

.l-page-head__title--ja {
  font-size: 36px;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3px;
}

@media (max-width: 767px) {
  .l-page-head__title--ja {
    font-size: 20px;
  }
}

.l-page-head__title--en {
  font-family: 'Yantramanav', sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  color: #7f7f7f;
  letter-spacing: 3px;
}

@media (max-width: 767px) {
  .l-page-head__title--en {
    font-size: 12px;
  }
}

.l-contents {
  padding: 70px 0 70px;
}

@media (max-width: 767px) {
  .l-contents {
    padding: 35px 0 35px;
  }
}

.l-contents2 {
  padding: 70px 0 0;
}

@media (max-width: 767px) {
  .l-contents2 {
    padding: 35px 0 0;
  }
}

.l-footer {
  padding: 30px 0;
}

/*// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------*/
.c-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.c-container__inner {
  width: 98%;
  /* IE8以下とAndroid4.3以下用フォールバック */
  width: -webkit-calc(100% - 30px);
  width: calc(100% - 30px);
  margin: 0 auto;
}

.c-container__header--inner {
  width: 98%;
  /* IE8以下とAndroid4.3以下用フォールバック */
  width: -webkit-calc(100% - 30px);
  width: calc(100% - 30px);
  margin: 0 auto;
}

@media (max-width: 910px) {
  .c-container__header--inner {
    width: 100%;
  }
}

.c-container__box {
  max-width: 820px;
  width: 100%;
  margin: 0 auto;
}

.c-container__box--white {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 0;
  background: #fff;
}

@media (max-width: 767px) {
  .c-container__box--white {
    padding: 35px 15px;
  }
}

.c-header__copy {
  font-size: 14px;
  color: #666666;
  line-height: 1.7;
  margin: 15px 0 0 0;
  font-weight: normal;
  display: block;
  width: 100%;
}

@media (max-width: 910px) {
  .c-header__copy {
    margin: 5px 0 0 0;
    font-size: 12px;
    display: none;
  }
}

.c-header__logo img {
  max-width: 260px;
  width: 100%;
}

@media (max-width: 910px) {
  .c-header__logo img {
    max-width: 180px;
  }
}

.c-tel__sp {
  display: none;
  position: absolute;
  top: 0;
  right: 80px;
}

@media (max-width: 910px) {
  .c-tel__sp {
    display: block;
  }
}

.c-tel__sp--link {
  font-size: 55px;
  color: #8fc31f;
}

.c-mainimg img {
  width: 100%;
}

.c-header-sp__meta {
  display: none;
}

@media (max-width: 910px) {
  .c-header-sp__meta {
    display: block;
  }
}

.c-header-sp__meta--tel {
  padding: 20px 0 0;
  text-align: center;
}

.c-header-sp__meta--hours {
  text-align: center;
  padding: 10px 0 20px;
  margin: 0;
  line-height: 2;
}

.c-header-sp__sns--list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 40px;
}

.c-header-sp__sns--item {
  margin: 0 auto;
  border: 0;
  width: 30%;
}

.c-header-sp__sns--item a {
  display: block;
  padding: 0 !important;
  text-align: center;
  margin: 0;
}

.c-header-sp__sns--item a.c-sns__linebtn {
  font-size: 55px;
  line-height: 1;
}

.c-header-sp__sns--item a.c-sns__linebtn:hover {
  transition: .4s;
  color: #00b900;
  opacity: 0.7;
}

.c-header-sp__sns--item a.c-sns__instabtn {
  margin-top: 6px;
}

.c-header-sp__sns--item a.c-sns__instabtn:hover {
  transition: .4s;
  opacity: 0.7;
}

.c-header-sp__sns--item a.c-sns__fbbtn {
  font-size: 55px;
  line-height: 1;
}

.c-header-sp__sns--item a.c-sns__fbbtn:hover {
  transition: .4s;
  color: #1877f2;
  opacity: 0.7;
}

.c-footer__logo {
  max-width: 260px;
  width: 100%;
}

.c-footer__company {
  margin-left: 5em;
  line-height: 1.8em;
}

@media (max-width: 960px) {
  .c-footer__company {
    margin-left: 2em;
  }
}

.c-footer__company--address,
.c-footer__company--tel,
.c-footer__company--fax {
  padding: 0;
  margin: 0;
}

.c-footer__company--tel a {
  pointer-events: none;
}

@media (max-width: 767px) {
  .c-footer__company--tel a {
    pointer-events: auto;
  }
}

.c-footer__company--address {
  margin: 20px 0 0;
}

.c-footer__inner {
  position: relative;
}

.c-footer__nav {
  position: absolute;
  top: 30px;
  right: 0;
}

@media (max-width: 960px) {
  .c-footer__nav {
    display: none;
  }
}

.c-footer__nav--list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.c-footer__nav--item {
  font-size: 16px;
  font-weight: bold;
  margin: 0 15px;
}

.c-footer__nav--item:last-child {
  margin: 0 0 0 15px;
}

.c-footer__nav--item a {
  color: #000000;
}

.c-footer__nav--item a:hover {
  color: #eb613b;
}

.c-footer__sns--nav {
  position: absolute;
  top: 100px;
  right: 0;
}

@media (max-width: 960px) {
  .c-footer__sns--nav {
    display: none;
  }
}

.c-footer__sns--nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.c-footer__sns--nav__item {
  margin: 0 5px;
}

.c-footer__sns--nav__item a {
  display: block;
}

.c-footer__sns--nav__item a.c-sns__linebtn {
  font-size: 55px;
  line-height: 1;
}

.c-footer__sns--nav__item a.c-sns__linebtn:hover {
  transition: .4s;
  color: #00b900;
  opacity: 0.7;
}

.c-footer__sns--nav__item a.c-sns__instabtn {
  margin-top: 6px;
}

.c-footer__sns--nav__item a.c-sns__instabtn:hover {
  transition: .4s;
  opacity: 0.7;
}

.c-footer__sns--nav__item a.c-sns__fbbtn {
  font-size: 55px;
  line-height: 1;
}

.c-footer__sns--nav__item a.c-sns__fbbtn:hover {
  transition: .4s;
  color: #1877f2;
  opacity: 0.7;
}

.c-footer__copyright {
  text-align: center;
  margin-top: 30px;
}

@media (max-width: 960px) {
  .c-footer__copyright {
    font-size: 12px;
  }
}

/**, *:before, *:after {*/
/*-webkit-box-sizing:border-box;*/
/*-moz-box-sizing:border-box;*/
/*-o-box-sizing:border-box;*/
/*-ms-box-sizing:border-box;*/
/*box-sizing:border-box;*/
/*}*/
/* Markup */
.c-editor {
  font-size: 16px;
  line-height: 1.7em;
  font-family: YakuHanJP, "Noto Sans JP", sans-serif;
}

.c-editor h2 {
  display: block;
  width: 100%;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5em;
  margin: 20px 0;
  padding: 5px 0.5em;
  color: #000000;
  border-top: solid 1px #eb613b;
  border-bottom: solid 1px #eb613b;
}

.c-editor h3 {
  display: block;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  padding-left: 0.5em;
  color: #000000;
  border-left: solid 4px #eb613b;
  margin: 20px 0;
}

.c-editor h4 {
  display: block;
  width: 100%;
  padding: 5px 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  padding-left: 0.5em;
  margin: 20px 0;
  color: #000000;
  border-bottom: solid 1px #eb613b;
}

.c-editor h5 {
  display: block;
  width: 100%;
  padding: 5px 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  padding-left: 0.5em;
  margin: 10px 0;
  border-bottom: solid 1px #cccccc;
}

.c-editor h6 {
  display: block;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  padding-left: 0.5em;
  color: #000000;
  border-left: solid 4px #cccccc;
  margin: 20px 0;
}

.c-editor p {
  line-height: 2;
  display: block;
  margin: 1.5em 0;
}

.c-editor strong {
  font-weight: bold;
  color: #eb613b;
}

.c-editor em {
  font-weight: bold;
  font-style: normal;
  color: #eb613b;
}

.c-editor hr {
  border-top: 1px solid #eb613b;
}

.c-editor ul {
  margin: 30px 0;
  margin: 1em 0;
  padding-left: 40px;
  list-style-type: disc;
  list-style: disc;
}

.c-editor ul li {
  float: none !important;
  list-style-type: disc;
  list-style: disc;
  line-height: 2;
}

.c-editor ul.oblong {
  margin: 30px 0;
  margin: 1em 0;
  padding-left: 0;
  list-style-type: none;
}

.c-editor ul.oblong li {
  float: none !important;
  background-size: auto 50%;
  padding-left: 40px;
}

.c-editor ol {
  margin: 30px 0;
  padding-left: 40px;
  list-style-type: decimal;
  list-style: decimal;
}

.c-editor ol li {
  float: none !important;
  list-style-type: decimal;
  list-style: decimal;
  line-height: 2;
}

.c-editor table {
  border-collapse: collapse;
  line-height: 2;
}

.c-editor table tr th {
  border: solid 1px #eb613b;
  background: #f7c0b1;
  padding: 14px;
}

.c-editor table tr td {
  border: solid 1px #eb613b;
  padding: 14px;
}

.c-editor table tr:nth-child(2n) {
  background: none;
}

@media screen and (max-width: 1024px) {
  .c-editor table {
    width: 100% !important;
  }
}

.c-editor blockquote {
  background-color: #f4f9e8;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 1em;
  position: relative;
  z-index: 1;
}

.c-editor blockquote:before {
  font-size: 3em;
  line-height: 1em;
  color: #fff;
  position: absolute;
  z-index: -1;
  content: "\f10d";
  font-family: FontAwesome;
  left: 0;
  top: 0;
  padding: 10px;
}

.c-editor blockquote:after {
  font-size: 3em;
  line-height: 1em;
  color: #fff;
  position: absolute;
  z-index: -1;
  content: "\f10e";
  font-family: FontAwesome;
  right: 0;
  bottom: -0.2em;
  padding: 10px;
}

.c-editor blockquote p {
  display: inline;
}

.c-editor img {
  max-width: 100%;
  height: auto;
  padding: 10px 0;
}

.c-editor img.aligncenter {
  display: block;
  margin: 0 auto;
}

.c-editor img.alignright {
  display: inline;
  float: right;
}

.c-editor img.alignleft {
  display: inline;
  float: left;
}

.c-editor a {
  color: #eb613b;
  text-decoration: underline;
  word-break: break-all;
}

.c-editor a:hover {
  background: #eb613b;
  color: #fff;
  text-decoration: none;
}

.c-editor a:hover img {
  display: block;
}

.c-editor a[target="_blank"]:after {
  content: "\f14c";
  font-family: FontAwesome;
  padding: 0 5px;
}

.c-editor pre {
  white-space: pre-wrap;
  width: 100%;
  background: #fff;
  padding: 20px;
}

/* コンポーネント */
.wpac-alert {
  display: block;
  background-color: #f7c0b1;
  border: 1px solid #eb613b;
  border-radius: 3px;
  color: #eb613b;
  padding: .9rem;
  font-weight: bold;
}

.wpac-columns {
  margin: 10px 0;
}

.wpac-columns__row {
  display: flex;
  flex-wrap: wrap;
}

.wpac-columns__row {
  display: flex;
  flex-wrap: wrap;
}

.wpac-columns__col {
  padding-right: .9rem;
  padding-left: .9rem;
  margin-bottom: 1.8rem;
}

.wpac-columns__col--1-1 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 50%;
}

@media (max-width: 767px) {
  .wpac-columns__col--1-1 {
    width: 100%;
  }
}

.wpac-columns__col--1-2 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.wpac-columns__col--lg-1-3 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 33.3333%;
}

@media (min-width: 768px) and (max-width: 991px) {
  .wpac-columns__col--1-1.wpac-columns__col--lg-1-3 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .wpac-columns__col--lg-1-3 {
    width: 100%;
  }
}

.wpac-columns__col--1-3 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 33.3333%;
}

.wpac-columns__col--lg-1-4 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 25%;
}

@media (min-width: 768px) and (max-width: 991px) {
  .wpac-columns__col--1-1.wpac-columns__col--lg-1-4 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .wpac-columns__col--1-1.wpac-columns__col--lg-1-4 {
    width: 100%;
  }
}

.wpac-columns__col--1-4 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 25%;
}

@media (max-width: 767px) {
  .wpac-columns__col--1-2.wpac-columns__col--lg-1-4 {
    width: 50%;
  }
}

.c-btn {
  min-width: 290px;
  display: inline-block;
  font-size: 25px;
  padding: 0.5em 2.5em;
  text-decoration: none;
  color: #ffffff;
  border: solid 2px #eb613b;
  background: #eb613b;
  border-radius: 5px;
  transition: .4s;
}

@media (max-width: 767px) {
  .c-btn {
    font-size: 20px;
  }
}

.c-btn:hover {
  border: solid 2px #eb613b;
  background: #ffffff;
  color: #eb613b;
}

.c-btn__form {
  min-width: 290px;
  display: inline-block;
  font-size: 25px;
  padding: 0.5em 2.5em;
  text-decoration: none;
  color: #ffffff;
  border: solid 2px #eb613b;
  background: #eb613b;
  border-radius: 5px;
  transition: .4s;
}

@media (max-width: 767px) {
  .c-btn__form {
    font-size: 20px;
  }
}

.c-btn__form:hover {
  border: solid 2px #eb613b;
  background: #ffffff;
  color: #eb613b;
}

.c-btn__small {
  min-width: 110px;
  display: inline-block;
  font-size: 14px;
  padding: 0.5em 2.5em;
  text-decoration: none;
  color: #ffffff;
  border: solid 2px #eb613b;
  background: #eb613b;
  border-radius: 5px;
  transition: .4s;
}

.c-btn__small:hover {
  border: solid 2px #eb613b;
  background: #ffffff;
  color: #eb613b;
}

.c-sns__btn--insta {
  min-width: 290px;
  display: inline-block;
  font-size: 20px;
  padding: 0.5em 2.5em;
  text-decoration: none;
  color: #9711cc;
  border: solid 2px #9711cc;
  background: #fff;
  border-radius: 5px;
  transition: .4s;
}

.c-sns__btn--insta:hover {
  border: solid 2px #9711cc;
  background: #9711cc;
  color: #fff;
}

.c-sns__btn--fb {
  min-width: 290px;
  display: inline-block;
  font-size: 20px;
  padding: 0.5em 2em;
  text-decoration: none;
  color: #0d47a0;
  border: solid 2px #0d47a0;
  background: #fff;
  border-radius: 5px;
  transition: .4s;
}

.c-sns__btn--fb:hover {
  border: solid 2px #0d47a0;
  background: #0d47a0;
  color: #fff;
}

.c-sns__linebtn {
  color: #00b900;
}

.c-sns__fbbtn {
  color: #1877f2;
}

.c-sns__instabtn {
  display: inline-block;
  text-align: center;
  /*中央揃え*/
  color: #2e6ca5;
  /*文字色*/
  font-size: 20px;
  /*文字サイズ*/
  text-decoration: none;
  /*下線消す*/
}

.c-sns__instabtn:hover {
  /*ホバー時*/
  color: #668ad8;
  /*文字色*/
  transition: .5s;
  /*ゆっくり変化*/
}

.c-sns__instabtn .__insta {
  /*アイコンの背景*/
  position: relative;
  /*相対配置*/
  display: inline-block;
  width: 50px;
  /*幅*/
  height: 50px;
  /*高さ*/
  background: -webkit-linear-gradient(135deg, #427eff 0%, #f13f79 70%) no-repeat;
  background: linear-gradient(135deg, #427eff 0%, #f13f79 70%) no-repeat;
  /*グラデーション①*/
  overflow: hidden;
  /*はみ出た部分を隠す*/
  border-radius: 7px;
  /*角丸に*/
}

.c-sns__instabtn .__insta:before {
  /*グラデーションを重ねるため*/
  content: '';
  position: absolute;
  /*絶対配置*/
  top: 23px;
  /*ずらす*/
  left: -18px;
  /*ずらす*/
  width: 60px;
  /*グラデーションカバーの幅*/
  height: 60px;
  /*グラデーションカバーの高さ*/
  background: -webkit-radial-gradient(#ffdb2c 10%, rgba(255, 105, 34, 0.65) 55%, rgba(255, 88, 96, 0) 70%);
  background: radial-gradient(#ffdb2c 10%, rgba(255, 105, 34, 0.65) 55%, rgba(255, 88, 96, 0) 70%);
  /*グラデーション②*/
}

.c-sns__instabtn .fa-instagram {
  /*アイコン*/
  color: #FFF;
  /*白に*/
  position: relative;
  /*z-indexを使うため*/
  z-index: 2;
  /*グラデーションより前に*/
  font-size: 35px;
  /*アイコンサイズ*/
  line-height: 50px;
  /*高さと合わせる*/
}

.c-pagenavi .wp-pagenavi {
  margin: 40px 0;
  padding: 0 0 0 0;
  border: 0;
  text-align: center;
  font-size: 16px;
}

.c-pagenavi .wp-pagenavi .pages {
  display: none;
}

.c-pagenavi .wp-pagenavi a:not(.first):not(.last), .c-pagenavi .wp-pagenavi span {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 4px;
  padding: 7px 5px;
  border: none;
  /* border: solid 1px #000000; */
  color: #eb613b;
}

.c-pagenavi .previouspostslink,
.c-pagenavi .nextpostslink {
  border: solid 1px #eb613b;
  background: #eb613b;
  color: #fff !important;
}

.c-pagenavi .wp-pagenavi .current {
  border: solid 1px #eb613b;
  color: #eb613b;
}

/* .c-pagenavi__prev {
  display: inline-block;
  margin: 0 50px;
}

.c-pagenavi__next {
  display: inline-block;
  margin: 0 50px;
} */
/*// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------*/
/*// -----------------------------------------------------------------
// トップ　共通
// -----------------------------------------------------------------*/
.p-top__ttl {
  padding-top: 0;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 767px) {
  .p-top__ttl {
    font-weight: 500;
    font-size: 24px;
  }
}

.p-top__subttl {
  display: block;
  color: #7f7f7f;
  font-size: 15px;
  font-family: 'Yantramanav', sans-serif;
  font-weight: normal;
}

@media (max-width: 767px) {
  .p-top__subttl {
    font-size: 14px;
  }
}

/*// -----------------------------------------------------------------
// TOP 物件一覧
// -----------------------------------------------------------------*/
.p-top__fudou {
  padding: 70px 0 70px;
}

@media (max-width: 767px) {
  .p-top__fudou {
    padding: 35px 0 35px;
  }
}

.p-top__fudou__item {
  overflow: hidden;
  display: block;
  float: left;
  text-align: left;
  width: 23%;
  height: auto;
  margin: 0 1% 2% 1%;
  padding: 0;
  font-size: 14px;
  list-style-type: none;
  position: relative;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
}

@media (max-width: 767px) {
  .p-top__fudou__item {
    width: 48%;
    margin: 0 1% 2% 1%;
    font-size: 0.85rem;
  }
}

.p-top__new_mark {
  margin: 0;
  position: absolute;
  display: table;
  z-index: 2;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: url(../../assets/img/fudo/status_icon.png) no-repeat top center;
  background-size: cover;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
}

.p-top__new_mark span {
  display: table-cell;
  vertical-align: middle;
}

.p-top__fudou__item--btn {
  margin-top: 10px;
}

.p-top__fudou--btn {
  text-align: center;
}

/*// -----------------------------------------------------------------
// TOP 華はうすについて
// -----------------------------------------------------------------*/
.p-top__about {
  background: #F6F1DA;
  padding: 70px 0;
}

@media (max-width: 767px) {
  .p-top__about {
    padding: 35px 0;
  }
}

.p-top__about--img {
  text-align: center;
}

.p-top__about--copy {
  font-size: 16px;
  text-align: left;
  line-height: 2;
  margin: 0;
}

.p-top__about--btn {
  text-align: center;
  margin-top: 40px;
}

/*// -----------------------------------------------------------------
// SNSでも知寄町のくらしの情報発信中！
// -----------------------------------------------------------------*/
.p-top__sns {
  background: url(../../assets/img/common/bg.jpg) repeat top center;
  border-bottom: solid 4px #eb613b;
}

.p-top__sns--bg {
  background: url(../../assets/img/common/footer_bg01.png) no-repeat bottom center;
  padding: 50px 0;
  min-height: 200px;
}

@media (max-width: 767px) {
  .p-top__sns--bg {
    background: url(../../assets/img/common/footer_bg01_sp.png) no-repeat bottom center;
    min-height: 500px;
    background-size: contain;
  }
}

.p-top__sns--list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto;
}

.p-top__sns--item {
  width: 50%;
  margin: 20px 0 0;
  text-align: center;
}

@media (max-width: 767px) {
  .p-top__sns--item {
    width: 100%;
  }
  .p-top__sns--item:first-child {
    margin: 0;
  }
}

/*// -----------------------------------------------------------------
// 不動産コラム
// -----------------------------------------------------------------*/
.p-top__column {
  background: #F6F1DA;
  padding: 0 0 70px;
}

@media (max-width: 767px) {
  .p-top__column {
    padding: 0 0 35px;
  }
}

.p-top__column--list {
  padding: 0;
  margin: 0;
}

.p-top__common--item__link {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 30px 0;
  border-bottom: dotted 2px #eb613b;
}

@media (max-width: 767px) {
  .p-top__common--item__link {
    padding: 15px 0;
  }
}

.p-top__column--item__img {
  width: 30%;
}

@media (max-width: 767px) {
  .p-top__column--item__img {
    width: 100%;
  }
}

.p-top__column--item__img img {
  width: 100%;
}

.p-top__column--item__data {
  width: 65%;
  margin-left: 5%;
}

@media (max-width: 767px) {
  .p-top__column--item__data {
    width: 100%;
    margin-left: 0;
  }
}

.p-top__column--item__ttl {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.p-top__column--item__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 15px;
}

.p-top__column--item__meta--date {
  color: #7f7f7f;
}

.p-top__column--item__meta--cate {
  background: #ddedbb;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 14px;
}

a:hover .p-top__column--item__meta--cate {
  color: #000000;
}

.p-top__column--btn {
  text-align: right;
  margin-top: 30px;
}

/*// -----------------------------------------------------------------
// お知らせ
// -----------------------------------------------------------------*/
.p-top__information {
  padding: 70px 0 70px;
}

@media (max-width: 767px) {
  .p-top__information {
    padding: 35px 0 35px;
  }
}

.p-top__information--list {
  padding: 0;
  margin: 0;
}

.p-top__information--item {
  padding: 30px 0;
  border-bottom: dotted 2px #eb613b;
}

@media (max-width: 767px) {
  .p-top__information--item {
    padding: 15px 0;
  }
}

.p-top__information--item__ttl {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.p-top__information--item__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 15px;
}

.p-top__information--item__meta--date {
  color: #7f7f7f;
}

.p-top__information--item__meta--cate {
  background: #f9cfc4;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 14px;
}

a:hover .p-top__information--item__meta--cate {
  color: #000000;
}

.p-top__information--btn {
  text-align: right;
  margin-top: 30px;
}

.p-top__sns--ttl br {
  display: none;
}

@media (max-width: 767px) {
  .p-top__sns--ttl br {
    display: block;
  }
}

/*// -----------------------------------------------------------------
// お知らせ一覧
// -----------------------------------------------------------------*/
.p-information {
  margin-top: 70px;
}

@media (max-width: 767px) {
  .p-information {
    margin-top: 35px;
  }
}

.p-information__main {
  width: 64%;
  float: left;
}

@media (max-width: 767px) {
  .p-information__main {
    width: 100%;
    float: none;
  }
}

.p-information--list {
  padding: 0;
  margin: 0;
}

.p-information--item__link {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 0 40px;
  margin: 0 0 40px;
  border-bottom: dotted 2px #eb613b;
}

.p-information--item__img {
  width: 30%;
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .p-information--item__img {
    width: 100%;
  }
}

.p-information--item__img img {
  width: 100%;
}

.p-information--item__data {
  width: 65%;
  margin-left: 5%;
}

@media (max-width: 767px) {
  .p-information--item__data {
    width: 100%;
    margin: 15px 0 0 0;
  }
}

.p-information--item__ttl {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.p-information--item__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 15px;
}

.p-information--item__meta--date {
  color: #7f7f7f;
}

.p-information--item__meta--cate {
  background: #cccccc;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 14px;
  min-width: 130px;
  text-align: center;
}

a:hover .p-information--item__meta--cate {
  color: #000000;
}

.p-information--item__meta--cate.__column {
  background: #ddedbb;
}

.p-information--item__meta--cate.__news {
  background: #f9cfc4;
}

/*// -----------------------------------------------------------------
// お知らせ詳細
// -----------------------------------------------------------------*/
.p-information__ttl {
  font-size: 36px;
  font-weight: bold;
  display: block;
  border-bottom: solid 4px #eb613b;
  padding-bottom: 10px;
}

.p-information__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 25px 0;
}

.p-information__meta--date {
  color: #7f7f7f;
}

.p-information__meta--cate {
  background: #cccccc;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 14px;
  min-width: 130px;
  text-align: center;
}

.p-information__meta--cate.__column {
  background: #ddedbb;
}

.p-information__meta--cate.__news {
  background: #f9cfc4;
}

/*// -----------------------------------------------------------------
// お知らせサイドコンテンツ
// -----------------------------------------------------------------*/
.p-information__side {
  width: 28%;
  float: right;
  line-height: 1.8em;
}

@media (max-width: 767px) {
  .p-information__side {
    width: 100%;
    float: none;
  }
}

@media (max-width: 767px) {
  .p-information__side {
    width: 100%;
    float: left;
  }
}

.p-information__side--widget {
  margin-bottom: 60px;
}

.p-information__side--widget-title {
  font-size: 16px;
  font-weight: bold;
  color: #231815;
  border-top: solid 1px #eb613b;
  border-bottom: solid 1px #eb613b;
  line-height: 2em;
}

.p-information__side--widget-list {
  padding: 0;
}

.p-information__side--widget-list li {
  margin: 1em 0;
  font-size: 16px;
}

.p-information__side--widget-list a {
  color: #000000;
  text-decoration: none;
}

.p-information__side--widget-list a:hover:not(.c-side-cate) {
  color: #eb613b;
}

.p-information__side--widget-list .cat-item a::before {
  font-family: FontAwesome;
  content: '\f105';
  margin-right: 2px;
  color: #ccc;
}

.p-information__side--widget-list .c-side-ttl {
  display: block;
  margin-bottom: 10px;
}

.p-information__side--widget-list .c-side-date {
  font-size: 12px;
  display: block;
  color: #7f7f7f;
}

.c-side-mata {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 15px;
}

.c-side-cate {
  background: #cccccc;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 14px;
  min-width: 130px;
  text-align: center;
}

a:hover .c-side-cate {
  color: #000000;
}

.c-side-cate.__column {
  background: #ddedbb;
}

.c-side-cate.__news {
  background: #f9cfc4;
}

/*// -----------------------------------------------------------------
// 詳細ページページング
// -----------------------------------------------------------------*/
.p-information-nav {
  padding: 40px 0 40px;
  text-align: center;
}

.p-information-nav a.page {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding: 0.5em 2.5em;
  border: solid 2px #eb613b;
  border-radius: 5px;
  background: #eb613b;
  color: #ffffff;
  transition: .4s;
}

.p-information-nav a.page:hover {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  color: #eb613b;
}

.p-information-nav .current {
  display: inline-block;
  margin: 0 5px;
  padding: 5px 15px;
  border: solid 1px #eb613b;
  color: #eb613b;
}

.p-information-nav .previouspostslink {
  width: 40px;
  height: 40px;
  float: left;
  border: none;
  font-size: 20px;
  line-height: 36px;
  border: solid 2px #eb613b;
  border-radius: 5px;
  background: #eb613b;
  color: #ffffff;
  transition: .4s;
}

.p-information-nav .previouspostslink i {
  margin-left: -4px;
}

.p-information-nav .previouspostslink:hover {
  width: 40px;
  height: 40px;
  border: none;
  border: solid 2px #eb613b;
  background: #ffffff;
  color: #eb613b;
}

.p-information-nav .nextpostslink {
  width: 40px;
  height: 40px;
  float: right;
  border: none;
  font-size: 20px;
  line-height: 36px;
  border: solid 2px #eb613b;
  border-radius: 5px;
  background: #eb613b;
  color: #ffffff;
  transition: .4s;
}

.p-information-nav .nextpostslink i {
  margin-right: -4px;
}

.p-information-nav .nextpostslink:hover {
  width: 40px;
  height: 40px;
  border: none;
  border: solid 2px #eb613b;
  background: #ffffff;
  color: #eb613b;
}

.p-information-nav .post-nav .list_top {
  display: inline-block;
  font-size: 14px;
  text-align: center;
  padding: 0.5em 2.5em;
  border: solid 2px #eb613b;
  border-radius: 5px;
  background: #eb613b;
  color: #ffffff;
  transition: .4s;
}

.p-information-nav .post-nav .list_top:hover {
  border: solid 2px #eb613b;
  background: #ffffff;
  color: #eb613b;
  text-decoration: none;
}

/*// -----------------------------------------------------------------
// 物件一覧
// -----------------------------------------------------------------*/
.p-fudou__searchresults {
  margin: 70px 0 40px;
}

@media (max-width: 767px) {
  .p-fudou__searchresults {
    margin: 0 0 40px;
  }
}

.p-fudou__searchresults th {
  width: 100px;
}

@media (max-width: 767px) {
  .p-fudou__searchresults th {
    padding: 0.5em;
  }
}

@media (max-width: 767px) {
  .p-fudou__searchresults td {
    padding: 0.5em;
  }
}

.p-fudou__searchbtn {
  border-top: solid 1px #eb613b;
  border-bottom: solid 1px #eb613b;
  background: #eb613b;
  color: #ffffff;
  padding: 10px 0;
  cursor: pointer;
}

.p-fudou__searchbtn small {
  color: #ffffff;
  font-size: 20px;
  display: none;
}

.p-fudou__searchbtn_txt {
  position: relative;
  padding: 0 1em;
  font-size: 20px;
  font-weight: bold;
}

.p-fudou__searchbtn_txt:before {
  font-family: FontAwesome;
  content: "\f107";
  padding-right: 10px;
}

.p-fudou__searchbtn_txt.__changed:before {
  font-family: FontAwesome;
  content: "\f106";
  padding-right: 10px;
}

.p-fudou__search {
  margin: 40px 0;
  display: none;
}

.p-fudou__bukkenno {
  text-align: left;
  padding: 10px 0;
}

.p-fudou__bukkenno em {
  font-size: 110%;
  font-weight: bold;
  color: #eb613b;
  font-style: normal;
}

.p-fudou__new_mark {
  margin: 0;
  position: absolute;
  display: table;
  z-index: 2;
  color: #fff;
  font-size: 14px;
  text-align: center;
  background: url(../../assets/img/fudo/status_icon.png) no-repeat top center;
  width: 80px;
  height: 80px;
  top: 0;
  left: 0;
}

.p-fudou__new_mark span {
  display: table-cell;
  vertical-align: middle;
}

/*// -----------------------------------------------------------------
// 物件詳細
// -----------------------------------------------------------------*/
.p-fudou__main {
  padding: 0 0 70px;
}

.p-fudou-detail__new_mark {
  margin: 0;
  display: table;
  color: #fff;
  font-size: 14px;
  text-align: center;
  background: url(../../assets/img/fudo/status_icon.png) repeat top center;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .p-fudou-detail__new_mark {
    background-size: cover;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    font-size: 12px;
  }
}

.p-fudou-detail__new_mark span {
  display: table-cell;
  vertical-align: middle;
}

.p-fudou-detail__ttl {
  font-size: 36px;
  font-weight: bold;
  width: -webkit-calc(100% - 100px);
  width: calc(100% - 100px);
}

@media (max-width: 767px) {
  .p-fudou-detail__ttl {
    width: -webkit-calc(100% - 70px);
    width: calc(100% - 70px);
    margin: 15px 0 10px;
    font-size: 24px;
  }
}

.p-fudou-detail__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 70px 0 10px;
  align-items: center;
}

@media (max-width: 767px) {
  .p-fudou-detail__meta {
    margin: 35px 0 10px;
  }
}

.p-fudou-detail__cate {
  min-width: 130px;
  padding: 5px 20px;
  border-radius: 5px;
  background: #cccccc;
  font-size: 14px;
  text-align: center;
  display: inline-block;
}

.p-fudou-detail__area {
  margin: 40px 0 0;
}

.p-fudou-detail__area--copy {
  width: 100%;
  margin: 0 auto 40px;
  line-height: 2;
}

@media (max-width: 767px) {
  .p-fudou-detail__area--copy {
    margin: 0 auto 20px;
  }
}

.p-fudou-detail__point {
  margin: 40px 0 0;
  padding: 20px;
  width: 100%;
  min-height: 250px;
  background: #f3ecd4 url(../../assets/img/fudo/point.png) no-repeat bottom right 20px;
}

@media (max-width: 767px) {
  .p-fudou-detail__point {
    padding: 20px 20px 170px;
    background: #f3ecd4 url(../../assets/img/fudo/point.png) no-repeat bottom center;
  }
}

.p-fudou-detail__point--ttl {
  padding: 5px 20px;
  background: #eb613b;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
}

.p-fudou-detail__point--txt {
  margin: 20px 0 0;
  width: 75%;
  line-height: 2;
}

@media (max-width: 767px) {
  .p-fudou-detail__point--txt {
    width: 100%;
  }
}

.p-fudou-detail__subttl {
  padding-top: 70px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2em;
}

@media (max-width: 767px) {
  .p-fudou-detail__subttl {
    padding-top: 35px;
    font-size: 24px;
    font-weight: 500;
  }
}

.p-fudou-detail__table {
  margin: 0;
}

/*// -----------------------------------------------------------------
// 物件詳細 お問い合わせ
// -----------------------------------------------------------------*/
.p-fudou-detail__form {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 60px 60px;
  border: 1px solid #d1d1d1;
  line-height: 20px;
}

@media (max-width: 767px) {
  .p-fudou-detail__form {
    padding: 0 15px 20px;
  }
}

.p-fudou-detail__form--head {
  width: 100%;
  min-height: 250px;
  background: url(../../assets/img/fudo/point.png) no-repeat bottom right 20px;
}

@media (max-width: 767px) {
  .p-fudou-detail__form--head {
    min-height: 350px;
  }
}

.p-fudou-detail__form--copy {
  max-width: 540px;
  width: 100%;
  margin: 0 auto;
}

.p-fudou-detail__form--txt {
  line-height: 2;
}

@media (max-width: 767px) {
  .p-fudou-detail__form--txt br {
    display: none;
  }
}

.p-fudou-detail__form--line__dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 540px;
  width: 100%;
  margin: 40px auto 0;
}

@media (max-width: 767px) {
  .p-fudou-detail__form--line__dl {
    margin: 20px auto 0;
  }
}

.p-fudou-detail__form--line__dt {
  width: 70%;
  font-weight: normal;
}

@media (max-width: 767px) {
  .p-fudou-detail__form--line__dt {
    width: 100%;
    text-align: left;
    margin-top: 10px;
  }
}

.p-fudou-detail__form--line__dd {
  width: 30%;
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .p-fudou-detail__form--line__dd {
    width: 100%;
    text-align: left;
    margin-top: 10px;
  }
}

.p-fudou-detail__form--policy a {
  text-decoration: underline;
}

.p-fudou-detail__form--policy a:hover {
  text-decoration: none;
}

/*// -----------------------------------------------------------------
// 物件詳細関連物件表示
// -----------------------------------------------------------------*/
.c-fudo__item {
  overflow: hidden;
  display: block;
  float: left;
  text-align: left;
  width: 23%;
  height: auto;
  margin: 0 1% 2% 1%;
  padding: 0;
  font-size: 14px;
  list-style-type: none;
  position: relative;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
}

@media (max-width: 767px) {
  .c-fudo__item {
    width: 48%;
    margin: 0 1% 2% 1%;
    font-size: 0.85rem;
  }
}

.c-fudo__new_mark {
  margin: 0;
  position: absolute;
  display: table;
  z-index: 2;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: url(../../assets/img/fudo/status_icon.png) no-repeat top center;
  background-size: cover;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
}

.c-fudo__new_mark span {
  display: table-cell;
  vertical-align: middle;
}

/*// -----------------------------------------------------------------
// 華はうすについて
// -----------------------------------------------------------------*/
.p-page__ttl {
  padding-top: 0;
  padding-bottom: 40px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 767px) {
  .p-page__ttl {
    font-weight: 500;
    font-size: 24px;
  }
}

.p-page__subttl {
  display: block;
  color: #7f7f7f;
  font-size: 15px;
  font-family: 'Yantramanav', sans-serif;
  font-weight: normal;
}

@media (max-width: 767px) {
  .p-page__subttl {
    font-size: 14px;
  }
}

.p-about__message--copy {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  display: block;
}

@media (max-width: 767px) {
  .p-about__message--copy {
    text-align: left;
  }
}

.p-about__message--txt {
  margin: 20px 0 40px;
  line-height: 2;
}

.p-about__message--president {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  display: block;
}

.p-about__message--bg {
  background: url(../../assets/img/about/president.png) no-repeat bottom right;
  background-size: 200px;
  padding-bottom: 200px;
}

@media (max-width: 767px) {
  .p-about__message--bg {
    background: url(../../assets/img/about/president.png) no-repeat bottom center;
    background-size: 200px;
    padding-bottom: 280px;
  }
}

.p-about__what {
  background: #fbdfd8;
  padding: 70px 0;
}

@media (max-width: 767px) {
  .p-about__what {
    padding: 35px 0;
  }
}

.p-about__what--list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.p-about__what--item {
  width: 48%;
  background: #fff;
  padding: 30px 20px;
  margin-bottom: 4%;
}

@media (max-width: 767px) {
  .p-about__what--item {
    width: 100%;
  }
}

.p-about__what--item__ttl {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  padding-left: 55px;
  min-height: 85px;
}

.p-about__what--item__ttl:before {
  content: '';
  background: url(../../assets/img/about/icon1.png) no-repeat top left;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.p-about__what--item__txt {
  min-height: 180px;
  padding: 0;
  margin: 0;
  line-height: 2;
}

.p-about__what--item__btn {
  text-align: center;
  margin-top: 20px;
}

.p-about__our {
  background: url(../../assets/img/about/bg.jpg) no-repeat top center;
  background-size: cover;
}

.p-about__our--bg {
  background: rgba(255, 255, 255, 0.7);
  padding: 70px 0;
  text-align: center;
  position: relative;
}

@media (max-width: 767px) {
  .p-about__our--bg {
    padding: 35px 0 0;
  }
}

.p-about__our--img {
  display: block;
  padding-bottom: 20px;
  margin: 0 auto;
}

.p-about__our--meta {
  margin: 20px 0;
}

@media (max-width: 767px) {
  .p-about__our--meta {
    padding: 0 15px;
  }
}

.p-about__our--meta hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background-color: #d1d1d1;
  border: 0;
  height: 2px;
  margin: 0 0 1.75em;
  margin-right: 20%;
  margin-left: 20%;
}

.p-about__our--ttl2 {
  font-size: 27px;
  font-weight: bold;
}

.p-about__our--txt {
  font-size: 18px;
  line-height: 1.8em;
}

@media (max-width: 767px) {
  .p-about__our--txt {
    text-align: left;
  }
  .p-about__our--txt br {
    display: none;
  }
}

.p-about__our--img2 {
  position: absolute;
  right: -100px;
  bottom: 0;
}

@media (max-width: 767px) {
  .p-about__our--img2 {
    position: static;
    right: auto;
    bottom: auto;
  }
}

.p-about__spots {
  padding: 70px 0;
}

@media (max-width: 767px) {
  .p-about__spots {
    padding: 35px 0 0;
  }
}

.p-about__spots--ttl br {
  display: none;
}

@media (max-width: 767px) {
  .p-about__spots--ttl br {
    display: block;
  }
}

.p-about__spots--copy {
  display: block;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 767px) {
  .p-about__spots--copy {
    text-align: left;
  }
}

.p-about__spots--list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 40px 0;
}

.p-about__spots--item {
  display: block;
  position: relative;
  width: 23%;
  height: auto;
  margin: 0 1% 2% 1%;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
  font-size: 14px;
  list-style-type: none;
  text-align: left;
}

@media (max-width: 767px) {
  .p-about__spots--item {
    width: 48%;
  }
}

.p-about__spots--item__img {
  margin: 0;
}

.p-about__spots--item__img img {
  width: 100%;
}

.p-about__spots--item__ttl {
  padding: 10px 10px 0;
  font-size: 16px;
  font-weight: bold;
}

.p-about__spots--item__txt {
  padding: 10px;
  margin: 0;
  line-height: 1.8;
}

.p-about__spots--item__btn {
  text-align: center;
  padding: 10px 0 20px;
}

.p-about__business {
  padding: 70px 0 0;
  background: #f3ecd4;
}

@media (max-width: 767px) {
  .p-about__business {
    padding: 35px 0 0;
  }
}

.p-about__business--table {
  background: #fff;
}

.p-about__business--table th {
  width: 120px;
}

.p-about__business-mapimg {
  margin: 70px 0 0;
}

@media (max-width: 767px) {
  .p-about__business-mapimg {
    margin: 35px 0 0;
  }
}

.p-about__business-mapimg img {
  width: 100%;
}

.p-about__business--map {
  padding: 70px 0 0;
}

@media (max-width: 767px) {
  .p-about__business--map {
    padding: 35px 0 0;
  }
}

#map_canvas {
  width: 100%;
  height: 550px;
}

@media (max-width: 767px) {
  #map_canvas {
    padding: 35px 0 0;
  }
}

/*// -----------------------------------------------------------------
// お問い合わせ
// -----------------------------------------------------------------*/
.p-contact {
  margin-bottom: 70px;
}

@media (max-width: 767px) {
  .p-contact {
    margin-bottom: 35px;
  }
}

.p-contact__head {
  width: 100%;
  min-height: 300px;
  background: url(../../assets/img/contact/contact_bg.png) no-repeat bottom right 20px;
  border-bottom: 1px solid #d1d1d1;
  background-size: 155px;
}

@media (max-width: 767px) {
  .p-contact__head {
    min-height: 500px;
    background: url(../../assets/img/contact/contact_bg.png) no-repeat bottom center;
    background-size: 155px;
  }
}

.p-contact__tel {
  padding-bottom: 70px;
  border-bottom: 1px solid #d1d1d1;
}

@media (max-width: 767px) {
  .p-contact__tel {
    padding-bottom: 35px;
  }
}

.p-contact__line {
  padding-bottom: 70px;
  border-bottom: 1px solid #d1d1d1;
}

@media (max-width: 767px) {
  .p-contact__line {
    padding-bottom: 35px;
  }
}

.p-fudou-detail__form--tel__meta {
  margin: 40px 0 0 30%;
}

@media (max-width: 767px) {
  .p-fudou-detail__form--tel__meta {
    margin: 20px 0 0 0;
  }
}

.p-fudou-detail__form--tel__tel {
  display: block;
  padding-left: 10px;
  font-size: 34px;
  font-style: normal;
  position: relative;
  padding-left: 50px;
}

@media (max-width: 767px) {
  .p-fudou-detail__form--tel__tel {
    font-size: 20px;
  }
}

.p-fudou-detail__form--tel__tel:before {
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
  background: #8fc31f;
  color: #ffffff;
  font-size: 19px;
  font-weight: bold;
  content: "TEL";
  position: absolute;
  margin-top: -5px;
  left: 0;
}

.p-fudou-detail__form--tel__tel a {
  pointer-events: none;
}

@media (max-width: 767px) {
  .p-fudou-detail__form--tel__tel a {
    pointer-events: auto;
  }
}

/*// -----------------------------------------------------------------
// お問い合わせ送信完了
// -----------------------------------------------------------------*/
.p-contact-complete {
  margin-bottom: 70px;
}

@media (max-width: 767px) {
  .p-contact-complete {
    margin-bottom: 35px;
  }
}

.p-contact-complete__box {
  padding: 60px 60px 60px;
  border: 1px solid #d1d1d1;
}

@media (max-width: 767px) {
  .p-contact-complete__box {
    padding: 30px 20px 30px;
  }
}

.p-contact-complete__txt {
  text-align: left;
  line-height: 2em;
  margin: 0;
}

.p-contact-complete__btn {
  text-align: center;
  margin: 0;
  padding-top: 40px;
}

/*// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------*/
/**
* clearfix
*/
.u-cf:before,
.u-cf:after {
  display: table;
  content: " ";
}

.u-cf:after {
  clear: both;
}

.u-cf {
  *zoom: 1;
}
