/*// -----------------------------------------------------------------
// Page head
// -----------------------------------------------------------------*/
.l-page-head {
  // display: table;
  width: 100%;
  // height: 300px;
  // height: 440px;
}
.l-page-head__img{
  width: 100%;
}
// @media ( max-width: $screen-xs-max ){
//   .l-page-head {
//     height: 235px;
//     height: 300px;
//   }
// }

.l-page-head__inner {
  position: relative;
  width: 100%;
  height: 100%;
  // display: table-cell;
  // text-align: center;
  // vertical-align: middle;
  // background: url(#{$imagepath}/img/information/mainimg.png) no-repeat top center;
  // background-position: 50% 50%;
  // background-size: cover;
  // @media ( max-width: $screen-xs-max ){
  //   background: url(#{$imagepath}/img/information/mainimg_sp.png) no-repeat top center;
  //   background-size: cover;
  // }
}

.l-page-head__title {
  line-height: 1.5;
  position: absolute;
  top: 43%;
  width: 100%;
  margin:0 auto;
  text-align: center;
  &.__small {
    top:30%;
    @media ( max-width: $screen-xs-max ){
      top: 40%;
    }
  }
  &.__about {
    top:40%;
    @media ( max-width: $screen-xs-max ){
      top: 40%;
    }
  }
}

.l-page-head__title--ja {
  font-size:36px;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3px;
}
@media ( max-width: $screen-xs-max ){
  .l-page-head__title--ja {
    font-size:20px;
  }
}

.l-page-head__title--en {
  font-family: 'Yantramanav', sans-serif;
  font-size:15px;
  font-weight: bold;
  font-style: normal;
  color: #7f7f7f;
  letter-spacing: 3px;
}
@media ( max-width: $screen-xs-max ){
  .l-page-head__title--en {
    font-size:12px;
  }
}


.l-contents{
  padding:70px 0 70px;
  @media ( max-width: $screen-xs-max ){
    padding: 35px 0 35px;
  }
}

.l-contents2{
  padding:70px 0 0;
  @media ( max-width: $screen-xs-max ){
    padding: 35px 0 0;
  }
}