
.c-container {
  max-width: 1200px;
  width:100%;
  margin:0 auto;
}

.c-container__inner {
  width : 98% ; /* IE8以下とAndroid4.3以下用フォールバック */
  width : -webkit-calc(100% - 30px);
  width : calc(100% - 30px);
  margin:0 auto;
}

.c-container__header--inner {
  width : 98% ; /* IE8以下とAndroid4.3以下用フォールバック */
  width : -webkit-calc(100% - 30px);
  width : calc(100% - 30px);
  margin:0 auto;
  @media ( max-width: 910px ){
    width:100%;
  }
}

.c-container__box{
  max-width: 820px;
  width:100%;
  margin:0 auto;
}

.c-container__box--white {
  max-width: 1200px;
  width:100%;
  margin:0 auto;
  padding: 70px 0;
  background: #fff;
  @media ( max-width: $screen-xs-max ){
    padding: 35px 15px;
  }
}