
$screen-sm-max: 991px !default;
$screen-sm-min: 768px !default;
$screen-xs-max: 767px !default;

$imagepath:"../../assets/";



a {
  color: #000000;
  text-decoration: none;
  &:hover {
    color: #eb613b;
  }
}


/*// -----------------------------------------------------------------
// 後で削除
// -----------------------------------------------------------------*/

.site-footer .site-title::after {
  display: none;
}

/* 物件一覧 */
.archive-fudo .top_title {
  text-align: left;
}

.list_picsam .list_address, .list_picsam .list_address_koutu {
  text-align: left;
  padding: 0 20px 0 30px;
}


/* お知らせ */
.blog .page-cont { margin: 0 auto 1.75em auto; width: 960px; }
.blog .page-cont p { margin: 0.75em auto 1.75em auto; }

@media screen and (max-width: 1800px) {
  .blog .page-cont {
    margin: 0 21.875% 1.75em 21.875%;
    width: auto;
  }
}

@media screen and (max-width: 1600px) {
  .blog .page-cont {
    margin: 0 20% 1.75em 20%;
    width: auto;
  }
}

@media screen and (max-width: 1366px) {
  .blog .page-cont {
    margin: 0 14.862% 1.75em 14.862%;
    width: auto;
  }
}


@media screen and (max-width: 600px) {
  .blog .page-cont {
    margin: 0 10% 1.75em 10%;
    width: auto;
  }
}